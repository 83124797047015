/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData } from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthDataFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthDataFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthDataToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData';

/**
 * @type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken = FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData;

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData(json)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthDataToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData);
    }

    return {};
}

