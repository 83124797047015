/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig } from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig';
import type { FxIdDomainSettingsCompanyBrandingConfigFirebaseOptions } from './FxIdDomainSettingsCompanyBrandingConfigFirebaseOptions';
import {
    FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsFromJSON,
    FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsFromJSONTyped,
    FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsToJSON,
    FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsToJSONTyped,
} from './FxIdDomainSettingsCompanyBrandingConfigFirebaseOptions';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig';
import type { FxIdDomainSettingsCompanyBrandingConfigLogos } from './FxIdDomainSettingsCompanyBrandingConfigLogos';
import {
    FxIdDomainSettingsCompanyBrandingConfigLogosFromJSON,
    FxIdDomainSettingsCompanyBrandingConfigLogosFromJSONTyped,
    FxIdDomainSettingsCompanyBrandingConfigLogosToJSON,
    FxIdDomainSettingsCompanyBrandingConfigLogosToJSONTyped,
} from './FxIdDomainSettingsCompanyBrandingConfigLogos';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsCompanyBrandingConfig
 */
export interface FxIdDomainSettingsCompanyBrandingConfig {
    /**
     * Название компании
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    CompanyName: string;
    /**
     * Название бренда сайта (передается в строки локализации)
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    BrandName: string;
    /**
     * 
     * @type {FxIdDomainSettingsCompanyBrandingConfigLogos}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    Logos: FxIdDomainSettingsCompanyBrandingConfigLogos;
    /**
     * Картинка для хедера (FX_HEADER итд)
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    HeaderElementType: string;
    /**
     * 
     * @type {FxIdDomainSettingsCompanyBrandingConfigFirebaseOptions}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    FirebaseOptions?: FxIdDomainSettingsCompanyBrandingConfigFirebaseOptions | null;
    /**
     * Настройки темы оформления
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    ThemeType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    ExternalTosUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    ExternalPpUrl?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    Auth: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig;
    /**
     * 
     * @type {Array<FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig>}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     * @deprecated
     */
    Adapters?: Array<FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig> | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsCompanyBrandingConfig interface.
 */
export function instanceOfFxIdDomainSettingsCompanyBrandingConfig(value: object): value is FxIdDomainSettingsCompanyBrandingConfig {
    if (!('CompanyName' in value) || value['CompanyName'] === undefined) return false;
    if (!('BrandName' in value) || value['BrandName'] === undefined) return false;
    if (!('Logos' in value) || value['Logos'] === undefined) return false;
    if (!('HeaderElementType' in value) || value['HeaderElementType'] === undefined) return false;
    if (!('Auth' in value) || value['Auth'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsCompanyBrandingConfigFromJSON(json: any): FxIdDomainSettingsCompanyBrandingConfig {
    return FxIdDomainSettingsCompanyBrandingConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsCompanyBrandingConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsCompanyBrandingConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'CompanyName': json['CompanyName'],
        'BrandName': json['BrandName'],
        'Logos': FxIdDomainSettingsCompanyBrandingConfigLogosFromJSON(json['Logos']),
        'HeaderElementType': json['HeaderElementType'],
        'FirebaseOptions': json['FirebaseOptions'] == null ? undefined : FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsFromJSON(json['FirebaseOptions']),
        'ThemeType': json['ThemeType'] == null ? undefined : json['ThemeType'],
        'ExternalTosUrl': json['ExternalTosUrl'] == null ? undefined : json['ExternalTosUrl'],
        'ExternalPpUrl': json['ExternalPpUrl'] == null ? undefined : json['ExternalPpUrl'],
        'Auth': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSON(json['Auth']),
        'Adapters': json['Adapters'] == null ? undefined : ((json['Adapters'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSON)),
    };
}

export function FxIdDomainSettingsCompanyBrandingConfigToJSON(json: any): FxIdDomainSettingsCompanyBrandingConfig {
    return FxIdDomainSettingsCompanyBrandingConfigToJSONTyped(json, false);
}

export function FxIdDomainSettingsCompanyBrandingConfigToJSONTyped(value?: FxIdDomainSettingsCompanyBrandingConfig | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'CompanyName': value['CompanyName'],
        'BrandName': value['BrandName'],
        'Logos': FxIdDomainSettingsCompanyBrandingConfigLogosToJSON(value['Logos']),
        'HeaderElementType': value['HeaderElementType'],
        'FirebaseOptions': FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsToJSON(value['FirebaseOptions']),
        'ThemeType': value['ThemeType'],
        'ExternalTosUrl': value['ExternalTosUrl'],
        'ExternalPpUrl': value['ExternalPpUrl'],
        'Auth': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigToJSON(value['Auth']),
        'Adapters': value['Adapters'] == null ? undefined : ((value['Adapters'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSON)),
    };
}

