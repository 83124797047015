/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig';
import {
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig(json)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig);
    }

    return {};
}

