/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult } from './FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult';
import {
    FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultFromJSON,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultFromJSONTyped,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultToJSON,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultToJSONTyped,
} from './FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse
 */
export interface FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse
     */
    Success: boolean;
    /**
     * 
     * @type {FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult}
     * @memberof FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse
     */
    Result?: FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse interface.
 */
export function instanceOfFxIdWebFeaturesProfileFetchFxIdGameConnectionResponse(value: object): value is FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse {
    if (!('Success' in value) || value['Success'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseFromJSON(json: any): FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse {
    return FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Success': json['Success'],
        'Result': json['Result'] == null ? undefined : FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultFromJSON(json['Result']),
    };
}

export function FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseToJSON(json: any): FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse {
    return FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseToJSONTyped(value?: FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Success': value['Success'],
        'Result': FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultToJSON(value['Result']),
    };
}

