import {
	EFxIdSdkAdapterInfoSocialCapability,
	FxIdSdkAdapterSocialSettingDefault,
	FxIdSdkAdapterStickyBannerShowErrorReason,
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdAddToFavoritesRequest,
	IFxIdAddToFavoritesResult,
	IFxIdJoinCommunityRequest,
	IFxIdJoinCommunityResult,
	IFxIdSdkAdapterAuthenticateUserRequest,
	IFxIdSdkAdapterAuthenticateUserResponse,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterGetFriendsRequest,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterInviteFriendsRequest,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest,
	IFxIdSdkAdapterStickyBannerShowResultDto,
	IFxIdSdkGetFriendsResult,
	IFxIdSdkGetFriendsResultFriend,
	IFxIdSdkInviteFriendsResult,
	IFxIdSdkRateApplicationRequest,
	IFxIdSdkRateApplicationResult,
	IFxIdSdkWordsFilterRequest,
	IFxIdSdkWordsFilterResult
} from "@app/SDK/FxIdSdkBaseAdapter";
import {
	FxIdApplicationStoreCreatePaymentHandlerEmbeddingType,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesPlayPublicDataBase,
	type FxIdWebFeaturesStoreCreatePaymentRequest
} from "@app/Api/gen";
import SharePayload = FBInstant.SharePayload;
import OpenApiClient from "@app/Api/OpenApiClient";
import Purchase = FBInstant.Purchase;
import InvitePayload = FBInstant.InvitePayload;

export interface FacebookUserNode {
	id: string;
	first_name?: string;
	last_name?: string;
	middle_name: string;
	name: string;
	picture?: {
		data: {
			height: number;
			is_silhouette: boolean;
			url: string;
			width: number;
		};
	};
}

export interface FacebookFriendsResult {
	data: FacebookUserNode[];
	paging: unknown;
	summary: {
		total_count: number;
	};
}

export class FxIdSdkAdapterForFacebookInstantGames extends FxIdSdkBaseAdapter {
	private _fbInstantPaymentsReady: boolean = false;
	private _preloadedVideoAd?: FBInstant.AdInstance;
	private _preloadedInterstitialAd?: FBInstant.AdInstance;

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxIdWebFeaturesPlayPublicDataBase
	) {
		super(exportedSdk);
	}

	async Initialize(): Promise<void> {
		log.info("Loading facebook script");
		await new Promise<void>((resolve) => {
			const s = document.createElement("script");
			s.type = "text/javascript";
			s.src = `https://www.facebook.com/assets.php/en_US/fbinstant.latest.js`;
			s.async = false;
			s.defer = false;
			s.crossOrigin = "anonymous";
			s.addEventListener(
				"load",
				(e) => {
					log.info("Facebook instant sdk loaded");
					resolve();
				},
				false
			);
			const head = document.getElementsByTagName("head")[0];
			head.appendChild(s);

			log.info("Facebook script loaded");
		});

		log.info("Initializing facebook instant sdk");
		await FBInstant.initializeAsync();

		log.info("Tell fb instant that game started");
		await FBInstant.startGameAsync();

		if (this.FBInstantPaymentsApisAvailable()) {
			log.info("Payments apis are available. Subscribing to on ready callback");
			FBInstant.payments.onReady(this.OnFBInstantPaymentsReady.bind(this));
		} else {
			log.info("Payments apis are not available");
		}

		void this.InitializeAds();
	}

	private FBInstantPaymentsApisAvailable() {
		const supportedApis = FBInstant.getSupportedAPIs();
		log.info("List of supported apis: %o", supportedApis);
		// https://developers.facebook.com/docs/games/monetize/in-app-purchases#detecting-whether-payments-is-available
		const paymentsAvailable = supportedApis.includes("payments.purchaseAsync");
		return paymentsAvailable;
	}

	private FBInstantRewardedVideoApiAvailable() {
		const supportedApis = FBInstant.getSupportedAPIs();
		const available = supportedApis.includes("getRewardedVideoAsync");
		return available;
	}

	private FBInstantInterstitialApiAvailable() {
		const supportedApis = FBInstant.getSupportedAPIs();
		const available = supportedApis.includes("getInterstitialAdAsync");
		return available;
	}

	OnFBInstantPaymentsReady() {
		log.info("FB instant payments ready");
		this._fbInstantPaymentsReady = true;
		void this.CheckNotConsumedPurchases();
	}

	private async CheckNotConsumedPurchases() {
		log.info("Checking purchases");
		const unconsumedPurchases = await FBInstant.payments.getPurchasesAsync();

		log.info("Found unconsumed purchases %o", unconsumedPurchases);

		for (const purchase of unconsumedPurchases) {
			try {
				await this.VerifyAndConsumePurchase(purchase);
			} catch (e) {
				log.error("Failed to verify and consume purchase: %o", purchase);
				log.error(e);
			}
		}
	}

	async GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		const userId = await FBInstant.player.getASIDAsync();

		if (userId == null) {
			throw new Error("Failed to retrieve getASIDAsync");
		}

		const paymentsAvailable = this.FBInstantPaymentsApisAvailable();

		const result: IFxIdSdkAdapterInfoSocial = {
			social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FacebookInstantGames,
			paymentsAvailable: paymentsAvailable,
			userId: userId,
			capabilities: [
				EFxIdSdkAdapterInfoSocialCapability.FriendsList,
				EFxIdSdkAdapterInfoSocialCapability.FriendsInvite,
				EFxIdSdkAdapterInfoSocialCapability.AdsAvailable
			],
			displayName: FBInstant.player.getName() ?? undefined,
			socialLocale:
				FBInstant.getLocale() == null || FBInstant.getLocale() == ""
					? undefined
					: (FBInstant.getLocale() ?? undefined)
		};

		const photoUrl = FBInstant.player.getPhoto();
		if (photoUrl != null) {
			result.photo = { url: photoUrl };
		}

		return result;
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		if (!this._fbInstantPaymentsReady) {
			log.warn("Payments api still not ready");
			throw new Error("Payments api still not ready");
		}

		const paymentRequest: FxIdWebFeaturesStoreCreatePaymentRequest = {
			Game: this.game,
			Sku: request.sku,
			ProductNameHint: request.productNameHint,
			ProductDescriptionHint: request.productDescriptionHint,
			WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FacebookInstantGames,
			EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType.Embed
		};

		if (request.developerPayload != null) {
			paymentRequest.DeveloperPayload = {
				MerchantDeveloperPayload: request.developerPayload
			};
		}

		const createPaymentResult = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint(paymentRequest);

		log.info("Received result from server: %o", createPaymentResult);

		const fbInstantPurchase = await FBInstant.payments.purchaseAsync({
			productID: createPaymentResult.FacebookInstantGames!.StoreSpecificOrderProductSku,
			developerPayload: JSON.stringify({ public_order_id: createPaymentResult.TransactionId })
		});

		log.info("Received purchase from fb instant: %o", fbInstantPurchase);

		await this.VerifyAndConsumePurchase(fbInstantPurchase);

		return {
			transactionId: createPaymentResult.TransactionId,
			stats: {
				currency: createPaymentResult.OrderProduct.Currency ?? "",
				currencyAmount: createPaymentResult.OrderProduct.Price ?? 0,
				usdAmount: createPaymentResult.OrderProduct.UsdPrice ?? 0
			}
		};
	}

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve({
			ui: {
				disabled: true
			}
		});
	}

	RegisterShareHandlers(): Promise<void> {
		return Promise.resolve();
	}

	StoreCurrency(): Promise<string | undefined> {
		return Promise.resolve(undefined);
	}

	StatInitialize(request: IFxIdSdkAdapterStatInitializeRequest): Promise<void> {
		return Promise.resolve();
	}

	StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		return Promise.resolve();
	}

	async StoreAdditionalData(): Promise<unknown | undefined> {
		if (!this.FBInstantPaymentsApisAvailable()) {
			return undefined;
		}

		const catalog = await FBInstant.payments.getCatalogAsync();
		return { catalog };
	}

	async AdsIsVideoReady(): Promise<boolean> {
		if (!this.FBInstantRewardedVideoApiAvailable()) {
			return false;
		}

		return this._preloadedVideoAd != null;
	}

	async AdsShowVideo(): Promise<void> {
		if (!this.FBInstantRewardedVideoApiAvailable()) {
			return;
		}

		if (this._preloadedVideoAd == null) {
			return;
		}
		try {
			await this._preloadedVideoAd.showAsync();
			this._preloadedVideoAd = undefined;
			window.FxIdSdk!.DispatchAdsFinished();
		} catch (e) {
			log.error("Failed to show video ad");
			log.error(e);
			window.FxIdSdk!.DispatchAdsFailed();
		}
	}

	async AdsIsInterstitialReady(): Promise<boolean> {
		if (!this.FBInstantRewardedVideoApiAvailable()) {
			return false;
		}

		return this._preloadedInterstitialAd != null;
	}

	async AdsShowInterstitial(): Promise<void> {
		if (!this.FBInstantRewardedVideoApiAvailable()) {
			return;
		}

		if (this._preloadedInterstitialAd == null) {
			return;
		}
		try {
			await this._preloadedInterstitialAd.showAsync();
			this._preloadedInterstitialAd = undefined;
			window.FxIdSdk!.DispatchAdsFinished();
		} catch (e) {
			log.error("Failed to show video ad");
			log.error(e);
			window.FxIdSdk!.DispatchAdsFailed();
		}
	}

	AdsStickyBannerShow(): Promise<IFxIdSdkAdapterStickyBannerShowResultDto> {
		return Promise.resolve({
			success: false,
			errorReason: FxIdSdkAdapterStickyBannerShowErrorReason.NotImplemented
		});
	}

	AdsStickyBannerHide(): Promise<IFxIdSdkAdapterStickyBannerShowResultDto> {
		return Promise.resolve({
			success: false,
			errorReason: FxIdSdkAdapterStickyBannerShowErrorReason.NotImplemented
		});
	}

	async GetFriends(_request: IFxIdSdkAdapterGetFriendsRequest): Promise<IFxIdSdkGetFriendsResult> {
		const friends: IFxIdSdkGetFriendsResultFriend[] = [];
		try {
			const fbFriends = await FBInstant.player.getConnectedPlayersAsync();
			log.info("Fetched %s friends", fbFriends.length);

			for (const fbFriend of fbFriends) {
				const friend: IFxIdSdkGetFriendsResultFriend = {
					displayName: fbFriend.getName() ?? undefined,
					uid: fbFriend.getID()
				};

				const photo = fbFriend.getPhoto();
				if (photo != null) {
					friend.photo = {
						url: photo
					};
				}

				friends.push(friend);
			}
		} catch (e) {
			log.warn(e);
		}

		return Promise.resolve({ friends });
	}

	async InviteFriends(_request: IFxIdSdkAdapterInviteFriendsRequest): Promise<IFxIdSdkInviteFriendsResult> {
		if (_request.base64image == null) {
			log.warn("Will not share - base64image must be set for fb instant games sharing");
			return { friends: [] };
		}

		const payload: InvitePayload = {
			image: _request.base64image,
			text: _request.inviteText,
			data: _request.trackString
		};

		await FBInstant.inviteAsync(payload);

		return { friends: [] };
	}

	AuthenticateUser(
		_request: IFxIdSdkAdapterAuthenticateUserRequest
	): Promise<IFxIdSdkAdapterAuthenticateUserResponse> {
		return Promise.resolve({});
	}

	JoinCommunity(_request: IFxIdJoinCommunityRequest): Promise<IFxIdJoinCommunityResult> {
		return Promise.resolve({ success: false });
	}

	async AddToFavorites(_request: IFxIdAddToFavoritesRequest): Promise<IFxIdAddToFavoritesResult> {
		const canCreateShortcut = await FBInstant.canCreateShortcutAsync();
		if (canCreateShortcut) {
			// Тут try/catch нормальная семантика для этого вызова
			try {
				await FBInstant.createShortcutAsync();
				return { success: true };
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
			} catch (_e) {
				return { success: false };
			}
		} else {
			return { success: false };
		}
	}

	WordsFilter(request: IFxIdSdkWordsFilterRequest): Promise<IFxIdSdkWordsFilterResult> {
		return Promise.resolve({ success: true });
	}

	RateApplication(request: IFxIdSdkRateApplicationRequest): Promise<IFxIdSdkRateApplicationResult> {
		return Promise.resolve({ success: true });
	}

	async LoadingReady(): Promise<void> {
		return Promise.resolve();
	}

	async GameStarted(): Promise<void> {
		return Promise.resolve();
	}

	async GameStopped(): Promise<void> {
		return Promise.resolve();
	}

	private async VerifyAndConsumePurchase(purchase: Purchase) {
		log.info("Verifying payment on server %o", purchase);
		try {
			const response =
				await OpenApiClient.FacebookInstantGames.fxIdWebFeaturesStoreFacebookInstantGamesPurchaseEndpoint({
					Game: this.game,
					FbInstantPurchaseSignedData: purchase.signedRequest
				});

			log.info("Received response from server: %o", response);

			if (!response.Success) {
				log.error(`Purchase not validated ${JSON.stringify(purchase)}`);
			}
		} catch (e) {
			log.error(e);
		}

		// В ФБ нельзя НЕ закосьюмить товар
		await FBInstant.payments.consumePurchaseAsync(purchase.purchaseToken);
	}

	private InitializeAds() {
		const rewardedConfig = this.config.PublicWebClientConfig.Advertisement?.FacebookInstantGames?.Rewarded;
		const interstitialConfig = this.config.PublicWebClientConfig.Advertisement?.FacebookInstantGames?.Interstitial;
		if (rewardedConfig != null) {
			void this.PreloadRewardedVideo(rewardedConfig.DefaultPlacementId);
		}

		if (interstitialConfig != null) {
			void this.PreloadInterstitial(interstitialConfig.DefaultPlacementId);
		}
	}

	private async PreloadRewardedVideo(placementId: string): Promise<void> {
		if (!this.FBInstantRewardedVideoApiAvailable()) {
			log.warn("Rewarded video api not available");
		}

		try {
			const preloadedAd = await FBInstant.getRewardedVideoAsync(placementId);
			await preloadedAd.loadAsync();
			this._preloadedVideoAd = preloadedAd;
		} catch (e) {
			log.error("Failed to preload video ad for placement id: %s", placementId);
			log.error(e);
		}
	}

	private async PreloadInterstitial(placementId: string): Promise<void> {
		if (!this.FBInstantInterstitialApiAvailable()) {
			log.warn("Interstitial api not available");
		}

		try {
			const preloadedAd = await FBInstant.getInterstitialAdAsync(placementId);
			await preloadedAd.loadAsync();
			this._preloadedInterstitialAd = preloadedAd;
		} catch (e) {
			log.error("Failed to preload interstitial ad for placement id: %s", placementId);
			log.error(e);
		}
	}
}
