import React, { ReactNode, Suspense, useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { IconContext } from "react-icons";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import i18n from "./i18n";
import Layout from "./Screens/Layout";
import WebPlayRouter from "./WebPlayRouter";
import Error from "./Components/Error";
import { AuthRoute } from "./Utils/AuthRoute";
import { Center, Global, Loader, MantineProvider, MantineThemeOverride } from "@mantine/core";
import { LoginWithTempToken } from "./Screens/LoginWithTempToken";
import { IconProvider } from "./Icons";
import { LoginFromEmail, LoginFromFxEmail } from "./Screens/LoginFromEmail";
import { useOs } from "@mantine/hooks";
import { GlobalMessagesProvider } from "./Components/GlobalMessages";
import { InstallGame, NoEmail, ValidateEmail } from "./Components/GlobalMessages/Messages";
import { ModalsProvider } from "@mantine/modals";
import {
	IframeModal,
	LinkShareModal,
	LoginWithFirebaseModal,
	ReportIssueModal,
	SocialModal
} from "./Components/Modals";
import { DiscordQr } from "./Screens/DiscordQr";
import { LoadingIndicator } from "./Components/LoadingIndicator";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useQuery } from "./Utils/Hooks/useQuery";
import { HelmetWithChildren } from "./Components/HelmetWithChildren";
import { StripeCheckoutForm } from "./Components/Modals/StripeCheckoutModal";
import { useFirebaseInit } from "./Stores";
import { InfoModal } from "./Components/Modals/InfoModal";
import OpenApiClient from "./Api/OpenApiClient";
import useAsyncEffect from "use-async-effect";
import { FxGamesTheme } from "./Config";
import { IframeType, IframeTypeToSupportedPlatformMap } from "./Screens/WebPlayer/Hooks";
import {
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesGamesChooseGamePortalConfig,
	FxIdWebFeaturesGamesChooseGameResponse,
	FxIdWebFeaturesGamesChooseGameResponsePublicGameData
} from "./Api/gen";
import { webPlayerStore } from "./Screens/WebPlayer/store/webPlayerStore";
import { TextCopyModal } from "@app/Components/Modals/TextCopyModal";
// const DebugScreen = React.lazy(() => import("./Screens/DebugScreen"));
const WebPlayer = React.lazy(() => import("./Screens/WebPlayer"));
const IframeScreen = React.lazy(() => import("./Screens/Iframe"));
const NewLayout = React.lazy(() => import("./Screens/NewLayout"));
const Landing = React.lazy(() => import("./Components/Landing"));
const NewStoreScreen = React.lazy(() => import("./Screens/Store/new"));
const DocsScreen = React.lazy(() => import("./Screens/DocsScreen"));
const LoginFromGame = React.lazy(() => import("./Screens/LoginFromGame"));
const StoreReturnScreen = React.lazy(() => import("./Screens/Store/Components/StoreReturnScreen"));
const StoreContinueWithPI = React.lazy(() => import("./Screens/Store/Components/StoreContinueWithPI"));
const MerchantPaymentCompleted = React.lazy(() => import("./Screens/Store/Components/MerchantPaymentCompleted"));
const LauncherLanding = React.lazy(() => import("./Screens/Launcher/Landing"));
const Profile = React.lazy(() => import("./Screens/Profile"));
const PaymentFormPageLazy = React.lazy(() => import("./Screens/PaymentFormPage/PaymentFormPage"));

export const queryClient = new QueryClient();

const FullscreenFallback = () => (
	<Center h="100vh">
		<Loader size="xl" />
	</Center>
);

const SmallFallback = () => (
	<Center>
		<LoadingIndicator />
	</Center>
);

const SingleGameSiteRoutes = (props: {
	gameConfig: FxIdWebFeaturesGamesChooseGameResponsePublicGameData;
	portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig;
}) => {
	const { gameConfig, portalConfig } = props;
	const adapter = webPlayerStore((state) => state.adapter);

	log.info("Using single-game site routes for game %o", gameConfig.GameSystemName);

	//const defaultAdapter: IframeType = (gameConfig.PublicWebClientConfig.ForcedAdapter ?? "fxid") as IframeType;
	//const defaultPlatform = IframeTypeToSupportedPlatformMap[defaultAdapter];
	//const contextValue = { gameConfig, portalConfig, adapter: defaultAdapter, platform: defaultPlatform };

	const firebaseInit = useFirebaseInit();
	const firebaseConfig =
		gameConfig.PublicWebClientConfig.Firebase ??
		props.portalConfig.PortalPublicWebConfig.CompanyBranding.FirebaseOptions;

	if (firebaseConfig != null) {
		log.info("Initializing firebase with %o", firebaseConfig);
		firebaseInit({
			apiKey: firebaseConfig.ApiKey ?? undefined,
			authDomain: firebaseConfig?.AuthDomain ?? undefined,
			databaseURL: firebaseConfig.DatabaseUrl ?? undefined,
			projectId: firebaseConfig.ProjectId ?? undefined,
			storageBucket: firebaseConfig.StorageBucket ?? undefined,
			messagingSenderId: firebaseConfig.MessagingSenderId ?? undefined,
			appId: firebaseConfig?.AppId ?? undefined,
			measurementId: firebaseConfig?.MeasurementId ?? undefined
		});
	}

	const game = gameConfig.GameSystemName;

	// const routeParams = useParams<{ game?: string; adapter?: string }>();
	// const adapter: IframeType = (gameConfig.PublicWebClientConfig.ForcedAdapter ??
	// 	routeParams.adapter ??
	// 	"fxid") as IframeType;
	// const platform = IframeTypeToSupportedPlatformMap[adapter];

	useEffect(() => {
		document.body.classList.add("web-play");

		return () => {
			document.body.classList.remove("web-play");
		};
	}, []);

	log.info("Game: %s", game);
	log.info("Default adapter: %s", adapter);
	log.info("Default platform: %s", adapter);

	return (
		<Routes>
			<Route
				path="/"
				element={
					<MantineProvider withNormalizeCSS>
						<Suspense fallback={<FullscreenFallback />}>
							<WebPlayer disableStoreLink singleGameSite />
						</Suspense>
					</MantineProvider>
				}
			/>
			<Route
				path="/iframe/:adapter"
				element={
					<GameContextRouteUpdater gameConfig={gameConfig}>
						<Suspense fallback={<FullscreenFallback />}>
							<IframeScreen singleGameSite />
						</Suspense>
					</GameContextRouteUpdater>
				}
			/>
			<Route path="Store/Return/:id" element={<StoreReturnScreen />} />
			<Route path="/LoginFromEmail" element={<LoginFromEmail isMainSite={false} gameConfig={gameConfig} />} />
			<Route path="/LoginValidateEmail" element={<LoginFromFxEmail />} />
			<Route path="/LoginWithTempToken" element={<LoginWithTempToken />} />
			<Route path="/discord-qr" element={<DiscordQr />} />
			<Route
				path="Docs/:game/:adapter/:doc"
				element={
					<Suspense fallback={<FullscreenFallback />}>
						<DocsScreen portalConfig={portalConfig} />
					</Suspense>
				}
			/>
			<Route
				path="Docs/:adapter/:doc"
				element={
					<Suspense fallback={<FullscreenFallback />}>
						<DocsScreen portalConfig={portalConfig} />
					</Suspense>
				}
			/>
			<Route
				path="Docs/:doc"
				element={
					<Suspense fallback={<FullscreenFallback />}>
						<DocsScreen portalConfig={portalConfig} />
					</Suspense>
				}
			/>
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	);
};

const GameContextRouteUpdater = (props: {
	gameConfig: FxIdWebFeaturesGamesChooseGameResponsePublicGameData;
	children: ReactNode;
}) => {
	const { adapter: routeAdapter } = useParams();
	const location = useLocation();
	const adapterInStore = webPlayerStore((state) => state.adapter);
	const setAdapter = webPlayerStore.getState().setAdapter;
	const setPlatform = webPlayerStore.getState().setPlatform;

	useEffect(() => {
		if (adapterInStore && routeAdapter) {
			const newAdapter: IframeType = (props.gameConfig.PublicWebClientConfig.ForcedAdapter ??
				(routeAdapter as IframeType) ??
				"fxid") as IframeType;

			if (newAdapter && newAdapter !== adapterInStore) {
				const newPlatform = IframeTypeToSupportedPlatformMap[newAdapter];
				log.info("Setting adapter: %s, platform: %s", newAdapter, newPlatform);
				setAdapter(newAdapter);
				setPlatform(newPlatform);
			}
		}
	}, [routeAdapter, location.pathname, adapterInStore, setAdapter, setPlatform]);

	return <>{props.children}</>;
};

// тут надо выбрать игру из парамсов и создать гейм контекст запросив данные  и тоже сделать для вебплеера
const IframeScreenWithGameContext = () => {
	const routeParams = useParams<{ game: string; adapter: string }>();
	const adapter: IframeType = routeParams.adapter as IframeType;
	const platform = IframeTypeToSupportedPlatformMap[adapter];

	const gameConfig = webPlayerStore((state) => state.gameConfig);
	const setGameConfig = webPlayerStore.getState().setGameConfig;
	const setAdapter = webPlayerStore.getState().setAdapter;
	const setPlatform = webPlayerStore.getState().setPlatform;

	useAsyncEffect(async () => {
		try {
			const r = await OpenApiClient.PublicGameConfigApi.fxIdWebFeaturesPlayGamePublicConfigEndpoint(
				routeParams!.game!,
				platform
			);

			setGameConfig(r);
			setAdapter(adapter);
			setPlatform(platform);
		} catch (e) {
			log.error(e);
		}
	}, [routeParams]);

	log.info("Game: %s", routeParams.game);
	log.info("Adapter: %s", adapter);
	log.info("Platform: %s", platform);

	if (gameConfig == null) {
		return <></>;
	}

	return <IframeScreen singleGameSite={false} />;
};

const WebplayerScreenWithGameContext = () => {
	const routeParams = useParams<{ game: string; adapter: string }>();
	const adapter: IframeType = "fxid";
	const platform = IframeTypeToSupportedPlatformMap[adapter];

	const gameConfig = webPlayerStore((state) => state.gameConfig);
	const setGameConfig = webPlayerStore.getState().setGameConfig;
	const setAdapter = webPlayerStore.getState().setAdapter;
	const setPlatform = webPlayerStore.getState().setPlatform;

	useAsyncEffect(async () => {
		try {
			const r = await OpenApiClient.PublicGameConfigApi.fxIdWebFeaturesPlayGamePublicConfigEndpoint(
				routeParams!.game!,
				platform
			);

			setGameConfig(r);
			setAdapter(adapter);
			setPlatform(platform);
		} catch (e) {
			log.error(e);
		}
	}, [routeParams]);

	log.info("Game: %s", routeParams.game);
	log.info("Adapter: %s", adapter);
	log.info("Platform: %s", platform);

	if (gameConfig == null) {
		return <></>;
	}

	return <WebPlayer singleGameSite={false} />;
};

const StoreScreenWithGameContext = () => {
	const routeParams = useParams<{ game: string }>();
	const adapter: IframeType = "fxid";
	const platform = IframeTypeToSupportedPlatformMap[adapter];

	const gameConfig = webPlayerStore((state) => state.gameConfig);
	const setGameConfig = webPlayerStore.getState().setGameConfig;
	const setAdapter = webPlayerStore.getState().setAdapter;
	const setPlatform = webPlayerStore.getState().setPlatform;

	useAsyncEffect(async () => {
		try {
			const r = await OpenApiClient.PublicGameConfigApi.fxIdWebFeaturesPlayGamePublicConfigEndpoint(
				routeParams!.game!,
				platform
			);

			setGameConfig(r);
			setAdapter(adapter);
			setPlatform(platform);
		} catch (e) {
			log.error(e);
		}
	}, [routeParams]);

	log.info("Game: %s", routeParams.game);
	log.info("Adapter: %s", adapter);
	log.info("Platform: %s", platform);

	if (gameConfig == null) {
		return <></>;
	}

	return <NewStoreScreen />;
};

const MainRoutes = (props: { portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig }) => {
	log.info("Using main routes");

	const firebaseInit = useFirebaseInit();
	const firebaseConfig = props.portalConfig.PortalPublicWebConfig.CompanyBranding.FirebaseOptions;
	if (firebaseConfig != null) {
		log.info("Initializing firebase with %o", firebaseConfig);
		firebaseInit({
			apiKey: firebaseConfig.ApiKey ?? undefined,
			authDomain: firebaseConfig?.AuthDomain ?? undefined,
			databaseURL: firebaseConfig.DatabaseUrl ?? undefined,
			projectId: firebaseConfig.ProjectId ?? undefined,
			storageBucket: firebaseConfig.StorageBucket ?? undefined,
			messagingSenderId: firebaseConfig.MessagingSenderId ?? undefined,
			appId: firebaseConfig?.AppId ?? undefined,
			measurementId: firebaseConfig?.MeasurementId ?? undefined
		});
	}

	return (
		<Routes>
			{/* страница для отладки */}
			{/*{process.env.NODE_ENV !== "production" && (*/}
			{/*	<Route path="/debug" element={<WebPlayRouter />}>*/}
			{/*		<Route path=":game/iframe/:adapter" element={<DebugScreen />}></Route>*/}
			{/*	</Route>*/}
			{/*)}*/}
			{/*<Route path="/debug_events" element={<DebugEvents />} />*/}
			<Route path="/play" element={<WebPlayRouter />}>
				<Route
					path=":game/iframe/:adapter"
					element={
						<Suspense fallback={<FullscreenFallback />}>
							<IframeScreenWithGameContext />
						</Suspense>
					}
				/>
				<Route
					path=":game"
					element={
						<Suspense fallback={<FullscreenFallback />}>
							<WebplayerScreenWithGameContext />
						</Suspense>
					}
				/>
				{/*<Route path={`gc`} element={<Navigate to="/" replace />}></Route>*/}
			</Route>
			<Route
				path="/player/:game"
				element={
					<MantineProvider withNormalizeCSS>
						<Suspense fallback={<FullscreenFallback />}>
							<WebplayerScreenWithGameContext />
						</Suspense>
					</MantineProvider>
				}
			/>
			<Route path="/discord-qr" element={<DiscordQr />} />
			{/* Новая верстка */}
			<Route
				path="Launcher/Landing/:game"
				element={
					<Suspense fallback={<FullscreenFallback />}>
						<LauncherLanding />
					</Suspense>
				}
			/>
			<Route path="/LoginWithTempToken" element={<LoginWithTempToken />} />
			<Route
				path="Store/ContinueWithPI"
				element={
					<Suspense fallback={<FullscreenFallback />}>
						<StoreContinueWithPI />
					</Suspense>
				}
			/>
			<Route
				path="Store/MerchantPaymentCompleted/:token"
				element={
					<Suspense fallback={<FullscreenFallback />}>
						<MerchantPaymentCompleted />
					</Suspense>
				}
			/>
			<Route
				path="/"
				element={
					<Suspense fallback={<FullscreenFallback />}>
						<NewLayout portalConfig={props.portalConfig} />
					</Suspense>
				}
			>
				<Route
					index
					element={
						<Suspense fallback={<SmallFallback />}>
							<Landing />
						</Suspense>
					}
				/>
				<Route
					path="Store/:game"
					element={
						<Suspense fallback={<SmallFallback />}>
							<StoreScreenWithGameContext />
						</Suspense>
					}
				/>

				<Route path="Store/Return/:id" element={<StoreReturnScreen />} />
				<Route path="LoginFromEmail" element={<LoginFromEmail isMainSite={true} />} />
				<Route path="LoginValidateEmail" element={<LoginFromFxEmail />} />
				<Route path="Error" element={<Error />} />
				<Route
					path="Profile"
					element={
						<AuthRoute path="/Profile">
							<Suspense fallback={<SmallFallback />}>
								<Profile />
							</Suspense>
						</AuthRoute>
					}
				/>
			</Route>
			<Route
				path="Docs/:game/:adapter/:doc"
				element={
					<Suspense fallback={<FullscreenFallback />}>
						<DocsScreen portalConfig={props.portalConfig} />
					</Suspense>
				}
			/>
			<Route
				path="Docs/:adapter/:doc"
				element={
					<Suspense fallback={<FullscreenFallback />}>
						<DocsScreen portalConfig={props.portalConfig} />
					</Suspense>
				}
			/>
			<Route
				path="Docs/:doc"
				element={
					<Suspense fallback={<FullscreenFallback />}>
						<DocsScreen portalConfig={props.portalConfig} />
					</Suspense>
				}
			/>
			{/* Старая верстка */}
			<Route path="/" element={<Layout />}>
				<Route
					path="LoginFromGame/:game/:token"
					element={
						<Suspense fallback={<FullscreenFallback />}>
							<LoginFromGame />
						</Suspense>
					}
				/>
				{/* <Route path="Store" element={<StoreFront />} /> */}
				{/* <Route path="Store/:game" element={<StoreScreen />} /> */}
				{/* <Route
					path="Profile"
					element={
						<AuthRoute path="/Profile">
							<ProfileScreen />
						</AuthRoute>
					}
				/> */}
			</Route>
			<Route
				// в строку запроса необходимо передать "?PublicId=...&Token=..."
				path={`/Store/Bank131/PaymentForm`}
				element={
					<Suspense fallback={<FullscreenFallback />}>
						<PaymentFormPageLazy />
					</Suspense>
				}
			/>
		</Routes>
	);
};

const App = () => {
	log.info("Render App...");

	const query = useQuery();

	const game = import.meta.env.VITE_APP_GAME_OVERRIDE ?? (query.has("game") ? query.get("game") : undefined);

	const chooseGameResponse = webPlayerStore((state) => state.chooseGameResponse);
	const fetchChosenGame = webPlayerStore((state) => state.fetchChosenGame);

	useAsyncEffect(async () => {
		try {
			const payload = {
				GameSystemName: game,
				OriginUrl: window.location.origin
			};
			await fetchChosenGame(payload);
		} catch (e) {
			log.error(e);
		}
	}, []);

	const isMainSite = chooseGameResponse?.PublicGameData == null;

	const theme: MantineThemeOverride | undefined =
		chooseGameResponse?.PortalConfig?.PortalPublicWebConfig.CompanyBranding.ThemeType === "fxgames"
			? FxGamesTheme
			: undefined;

	const os = useOs();

	useEffect(() => {
		log.info("Remotejs: %o", query.has("remotejs"));
		log.info("Eruda: %o", query.has("eruda"));
	}, []);

	if (chooseGameResponse == null) {
		// тут можно крутилку получше.
		return <></>;
	}

	return (
		<QueryClientProvider client={queryClient}>
			<I18nextProvider i18n={i18n}>
				<IconProvider>
					<IconContext.Provider value={{ className: "react-icon" }}>
						<MantineProvider withNormalizeCSS withGlobalStyles withCSSVariables theme={theme}>
							<Global
								styles={() => ({
									'a[type="button"]:hover': { color: "unset", textDecoration: "none" },
									label: { marginBottom: "unset" },
									"input, select": {
										fontSize: os === "ios" ? "100% !important" : undefined
									}
								})}
							/>
							<ModalsProvider
								modals={{
									iframe: IframeModal,
									loginWithFirebase: LoginWithFirebaseModal,
									reportIssue: ReportIssueModal,
									linkShare: LinkShareModal,
									textCopyModal: TextCopyModal,
									social: SocialModal,
									stripeCheckoutModal: StripeCheckoutForm,
									info: InfoModal
								}}
								modalProps={{ centered: true, size: "xl" }}
							>
								<GlobalMessagesProvider
									messages={{
										"no-email": NoEmail,
										"validate-email": ValidateEmail,
										"install-game": InstallGame
									}}
								>
									{query.has("remotejs") && (
										<HelmetWithChildren>
											<script
												data-consolejs-channel={query.get("remotejs")}
												src="https://remotejs.com/agent/agent.js"
											></script>
										</HelmetWithChildren>
									)}
									{query.has("eruda") && (
										<HelmetWithChildren>
											<script src="//cdn.jsdelivr.net/npm/eruda"></script>
											<script>eruda.init();</script>
										</HelmetWithChildren>
									)}

									{!isMainSite && (
										<SingleGameSiteRoutes
											gameConfig={chooseGameResponse!.PublicGameData!}
											portalConfig={chooseGameResponse!.PortalConfig}
										/>
									)}
									{isMainSite && <MainRoutes portalConfig={chooseGameResponse!.PortalConfig} />}
								</GlobalMessagesProvider>
							</ModalsProvider>
						</MantineProvider>
					</IconContext.Provider>
				</IconProvider>
			</I18nextProvider>
			<ReactQueryDevtools />
		</QueryClientProvider>
	);
};

export default App;
