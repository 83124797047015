/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType } from './FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType';
import {
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSONTyped,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSONTyped,
} from './FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromImportRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateFromImportRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromImportRequest
     */
    GameSystemName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromImportRequest
     */
    Game?: number | null;
    /**
     * 
     * @type {FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromImportRequest
     */
    SocialIdentifierType: FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromImportRequest
     */
    SocialIdentifier: string;
}



/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromImportRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromImportRequest(value: object): value is FxIdWebFeaturesAuthAuthenticateFromImportRequest {
    if (!('SocialIdentifierType' in value) || value['SocialIdentifierType'] === undefined) return false;
    if (!('SocialIdentifier' in value) || value['SocialIdentifier'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromImportRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromImportRequest {
    return FxIdWebFeaturesAuthAuthenticateFromImportRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromImportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromImportRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'GameSystemName': json['GameSystemName'] == null ? undefined : json['GameSystemName'],
        'Game': json['Game'] == null ? undefined : json['Game'],
        'SocialIdentifierType': FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON(json['SocialIdentifierType']),
        'SocialIdentifier': json['SocialIdentifier'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromImportRequestToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromImportRequest {
    return FxIdWebFeaturesAuthAuthenticateFromImportRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromImportRequestToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromImportRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameSystemName': value['GameSystemName'],
        'Game': value['Game'],
        'SocialIdentifierType': FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON(value['SocialIdentifierType']),
        'SocialIdentifier': value['SocialIdentifier'],
    };
}

