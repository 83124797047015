/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions
 */
export interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions
     */
    QueryString: string;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions(value: object): value is FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions {
    if (!('QueryString' in value) || value['QueryString'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptionsFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'QueryString': json['QueryString'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptionsToJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptionsToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptionsToJSONTyped(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'QueryString': value['QueryString'],
    };
}

