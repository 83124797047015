/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse
     */
    Success: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse
     */
    Error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse
     */
    Token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse
     */
    RedirectUri?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse(value: object): value is FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse {
    if (!('Success' in value) || value['Success'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse {
    return FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Success': json['Success'],
        'Error': json['Error'] == null ? undefined : json['Error'],
        'Token': json['Token'] == null ? undefined : json['Token'],
        'RedirectUri': json['RedirectUri'] == null ? undefined : json['RedirectUri'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponseToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse {
    return FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponseToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromEmailInviteResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Success': value['Success'],
        'Error': value['Error'],
        'Token': value['Token'],
        'RedirectUri': value['RedirectUri'],
    };
}

