/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData } from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData';
import {
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataFromJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataFromJSONTyped,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataToJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataToJSONTyped,
} from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData';
import type { FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState } from './FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState';
import {
    FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateFromJSON,
    FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateFromJSONTyped,
    FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateToJSON,
    FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateToJSONTyped,
} from './FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState';
import type { FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameData } from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameData';
import {
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameDataFromJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameDataFromJSONTyped,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameDataToJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameDataToJSONTyped,
} from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
 */
export interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    Token: string;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    AccountId: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    Email?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    MyGamesTokenData: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData;
    /**
     * 
     * @type {FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameData}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    GameData?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameData | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    TransferState: FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState;
}



/**
 * Check if a given object implements the FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData interface.
 */
export function instanceOfFxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData(value: object): value is FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData {
    if (!('Token' in value) || value['Token'] === undefined) return false;
    if (!('AccountId' in value) || value['AccountId'] === undefined) return false;
    if (!('MyGamesTokenData' in value) || value['MyGamesTokenData'] === undefined) return false;
    if (!('TransferState' in value) || value['TransferState'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataFromJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData {
    if (json == null) {
        return json;
    }
    return {
        
        'Token': json['Token'],
        'AccountId': json['AccountId'],
        'Email': json['Email'] == null ? undefined : json['Email'],
        'MyGamesTokenData': FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataFromJSON(json['MyGamesTokenData']),
        'GameData': json['GameData'] == null ? undefined : FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameDataFromJSON(json['GameData']),
        'TransferState': FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateFromJSON(json['TransferState']),
    };
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataToJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataToJSONTyped(value?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Token': value['Token'],
        'AccountId': value['AccountId'],
        'Email': value['Email'],
        'MyGamesTokenData': FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataToJSON(value['MyGamesTokenData']),
        'GameData': FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameDataToJSON(value['GameData']),
        'TransferState': FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateToJSON(value['TransferState']),
    };
}

