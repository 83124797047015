/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromImportResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateFromImportResponse {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromImportResponse
     */
    Token: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromImportResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromImportResponse(value: object): value is FxIdWebFeaturesAuthAuthenticateFromImportResponse {
    if (!('Token' in value) || value['Token'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromImportResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromImportResponse {
    return FxIdWebFeaturesAuthAuthenticateFromImportResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromImportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromImportResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Token': json['Token'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromImportResponseToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromImportResponse {
    return FxIdWebFeaturesAuthAuthenticateFromImportResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromImportResponseToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromImportResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Token': value['Token'],
    };
}

