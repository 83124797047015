/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsTwitchAuthOptions } from './FxIdDomainSettingsTwitchAuthOptions';
import {
    instanceOfFxIdDomainSettingsTwitchAuthOptions,
    FxIdDomainSettingsTwitchAuthOptionsFromJSON,
    FxIdDomainSettingsTwitchAuthOptionsFromJSONTyped,
    FxIdDomainSettingsTwitchAuthOptionsToJSON,
} from './FxIdDomainSettingsTwitchAuthOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch
 * Настройки для Twitch
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch = FxIdDomainSettingsTwitchAuthOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsTwitchAuthOptions(json)) {
        return FxIdDomainSettingsTwitchAuthOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsTwitchAuthOptions(value)) {
        return FxIdDomainSettingsTwitchAuthOptionsToJSON(value as FxIdDomainSettingsTwitchAuthOptions);
    }

    return {};
}

