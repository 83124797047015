/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesFriendsGetFriendsResponseFriendData
 */
export interface FxIdWebFeaturesFriendsGetFriendsResponseFriendData {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    FriendAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    GameId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    Game: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    InThisGame: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    LastUpdatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    Status?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesFriendsGetFriendsResponseFriendData interface.
 */
export function instanceOfFxIdWebFeaturesFriendsGetFriendsResponseFriendData(value: object): value is FxIdWebFeaturesFriendsGetFriendsResponseFriendData {
    if (!('FriendAccountId' in value) || value['FriendAccountId'] === undefined) return false;
    if (!('GameId' in value) || value['GameId'] === undefined) return false;
    if (!('Game' in value) || value['Game'] === undefined) return false;
    if (!('InThisGame' in value) || value['InThisGame'] === undefined) return false;
    if (!('LastUpdatedAt' in value) || value['LastUpdatedAt'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesFriendsGetFriendsResponseFriendDataFromJSON(json: any): FxIdWebFeaturesFriendsGetFriendsResponseFriendData {
    return FxIdWebFeaturesFriendsGetFriendsResponseFriendDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesFriendsGetFriendsResponseFriendDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesFriendsGetFriendsResponseFriendData {
    if (json == null) {
        return json;
    }
    return {
        
        'FriendAccountId': json['FriendAccountId'],
        'GameId': json['GameId'],
        'Game': json['Game'],
        'InThisGame': json['InThisGame'],
        'LastUpdatedAt': (new Date(json['LastUpdatedAt'])),
        'Name': json['Name'] == null ? undefined : json['Name'],
        'Status': json['Status'] == null ? undefined : json['Status'],
    };
}

export function FxIdWebFeaturesFriendsGetFriendsResponseFriendDataToJSON(json: any): FxIdWebFeaturesFriendsGetFriendsResponseFriendData {
    return FxIdWebFeaturesFriendsGetFriendsResponseFriendDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesFriendsGetFriendsResponseFriendDataToJSONTyped(value?: FxIdWebFeaturesFriendsGetFriendsResponseFriendData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'FriendAccountId': value['FriendAccountId'],
        'GameId': value['GameId'],
        'Game': value['Game'],
        'InThisGame': value['InThisGame'],
        'LastUpdatedAt': ((value['LastUpdatedAt']).toISOString()),
        'Name': value['Name'],
        'Status': value['Status'],
    };
}

