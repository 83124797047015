/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesInfoNewsRequest
 */
export interface FxIdWebFeaturesInfoNewsRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsRequest
     */
    GameSystemName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsRequest
     */
    Locale?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesInfoNewsRequest interface.
 */
export function instanceOfFxIdWebFeaturesInfoNewsRequest(value: object): value is FxIdWebFeaturesInfoNewsRequest {
    if (!('GameSystemName' in value) || value['GameSystemName'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesInfoNewsRequestFromJSON(json: any): FxIdWebFeaturesInfoNewsRequest {
    return FxIdWebFeaturesInfoNewsRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInfoNewsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInfoNewsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'GameSystemName': json['GameSystemName'],
        'Locale': json['Locale'] == null ? undefined : json['Locale'],
    };
}

export function FxIdWebFeaturesInfoNewsRequestToJSON(json: any): FxIdWebFeaturesInfoNewsRequest {
    return FxIdWebFeaturesInfoNewsRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesInfoNewsRequestToJSONTyped(value?: FxIdWebFeaturesInfoNewsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameSystemName': value['GameSystemName'],
        'Locale': value['Locale'],
    };
}

