/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info
 */
export interface FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info
     */
    DemoMode: boolean;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info interface.
 */
export function instanceOfFxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info(value: object): value is FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info {
    if (!('DemoMode' in value) || value['DemoMode'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131InfoFromJSON(json: any): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info {
    return FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131InfoFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131InfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info {
    if (json == null) {
        return json;
    }
    return {
        
        'DemoMode': json['DemoMode'],
    };
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131InfoToJSON(json: any): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info {
    return FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131InfoToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131InfoToJSONTyped(value?: FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'DemoMode': value['DemoMode'],
    };
}

