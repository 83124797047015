/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationStoreOrderProductData } from './FxIdApplicationStoreOrderProductData';
import {
    FxIdApplicationStoreOrderProductDataFromJSON,
    FxIdApplicationStoreOrderProductDataFromJSONTyped,
    FxIdApplicationStoreOrderProductDataToJSON,
    FxIdApplicationStoreOrderProductDataToJSONTyped,
} from './FxIdApplicationStoreOrderProductData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData
 */
export interface FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData {
    /**
     * 
     * @type {FxIdApplicationStoreOrderProductData}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData
     */
    MainProduct: FxIdApplicationStoreOrderProductData;
    /**
     * 
     * @type {Array<FxIdApplicationStoreOrderProductData>}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData
     */
    AdditionalProducts?: Array<FxIdApplicationStoreOrderProductData> | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData interface.
 */
export function instanceOfFxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData(value: object): value is FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData {
    if (!('MainProduct' in value) || value['MainProduct'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSON(json: any): FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData {
    return FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData {
    if (json == null) {
        return json;
    }
    return {
        
        'MainProduct': FxIdApplicationStoreOrderProductDataFromJSON(json['MainProduct']),
        'AdditionalProducts': json['AdditionalProducts'] == null ? undefined : ((json['AdditionalProducts'] as Array<any>).map(FxIdApplicationStoreOrderProductDataFromJSON)),
    };
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataToJSON(json: any): FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData {
    return FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataToJSONTyped(value?: FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'MainProduct': FxIdApplicationStoreOrderProductDataToJSON(value['MainProduct']),
        'AdditionalProducts': value['AdditionalProducts'] == null ? undefined : ((value['AdditionalProducts'] as Array<any>).map(FxIdApplicationStoreOrderProductDataToJSON)),
    };
}

