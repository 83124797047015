/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions
 */
export interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions
     */
    GetPlayerSignature: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions
     */
    EnvironmentI18nLang: string;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions(value: object): value is FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions {
    if (!('GetPlayerSignature' in value) || value['GetPlayerSignature'] === undefined) return false;
    if (!('EnvironmentI18nLang' in value) || value['EnvironmentI18nLang'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'GetPlayerSignature': json['GetPlayerSignature'],
        'EnvironmentI18nLang': json['EnvironmentI18nLang'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsToJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsToJSONTyped(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GetPlayerSignature': value['GetPlayerSignature'],
        'EnvironmentI18nLang': value['EnvironmentI18nLang'],
    };
}

