/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStorePriceData
 */
export interface FxIdApplicationStorePriceData {
    /**
     * 
     * @type {number}
     * @memberof FxIdApplicationStorePriceData
     */
    Price: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStorePriceData
     */
    Currency: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStorePriceData
     */
    CurrencyIso4217: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStorePriceData
     */
    LocalizedPrice?: string | null;
}

/**
 * Check if a given object implements the FxIdApplicationStorePriceData interface.
 */
export function instanceOfFxIdApplicationStorePriceData(value: object): value is FxIdApplicationStorePriceData {
    if (!('Price' in value) || value['Price'] === undefined) return false;
    if (!('Currency' in value) || value['Currency'] === undefined) return false;
    if (!('CurrencyIso4217' in value) || value['CurrencyIso4217'] === undefined) return false;
    return true;
}

export function FxIdApplicationStorePriceDataFromJSON(json: any): FxIdApplicationStorePriceData {
    return FxIdApplicationStorePriceDataFromJSONTyped(json, false);
}

export function FxIdApplicationStorePriceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStorePriceData {
    if (json == null) {
        return json;
    }
    return {
        
        'Price': json['Price'],
        'Currency': json['Currency'],
        'CurrencyIso4217': json['CurrencyIso4217'],
        'LocalizedPrice': json['LocalizedPrice'] == null ? undefined : json['LocalizedPrice'],
    };
}

export function FxIdApplicationStorePriceDataToJSON(json: any): FxIdApplicationStorePriceData {
    return FxIdApplicationStorePriceDataToJSONTyped(json, false);
}

export function FxIdApplicationStorePriceDataToJSONTyped(value?: FxIdApplicationStorePriceData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Price': value['Price'],
        'Currency': value['Currency'],
        'CurrencyIso4217': value['CurrencyIso4217'],
        'LocalizedPrice': value['LocalizedPrice'],
    };
}

