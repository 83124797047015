/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsMyGamesPublicAuthOptions
 */
export interface FxIdDomainSettingsMyGamesPublicAuthOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsMyGamesPublicAuthOptions
     */
    ClientId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsMyGamesPublicAuthOptions
     */
    GameCode: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsMyGamesPublicAuthOptions interface.
 */
export function instanceOfFxIdDomainSettingsMyGamesPublicAuthOptions(value: object): value is FxIdDomainSettingsMyGamesPublicAuthOptions {
    if (!('ClientId' in value) || value['ClientId'] === undefined) return false;
    if (!('GameCode' in value) || value['GameCode'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSON(json: any): FxIdDomainSettingsMyGamesPublicAuthOptions {
    return FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsMyGamesPublicAuthOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'ClientId': json['ClientId'],
        'GameCode': json['GameCode'],
    };
}

export function FxIdDomainSettingsMyGamesPublicAuthOptionsToJSON(json: any): FxIdDomainSettingsMyGamesPublicAuthOptions {
    return FxIdDomainSettingsMyGamesPublicAuthOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsMyGamesPublicAuthOptionsToJSONTyped(value?: FxIdDomainSettingsMyGamesPublicAuthOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ClientId': value['ClientId'],
        'GameCode': value['GameCode'],
    };
}

