/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxIdWebFeaturesStoreYandexGamesPurchaseRequest,
  FxIdWebFeaturesStoreYandexGamesPurchaseResponse,
} from '../models/index';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxIdWebFeaturesStoreYandexGamesPurchaseRequestFromJSON,
    FxIdWebFeaturesStoreYandexGamesPurchaseRequestToJSON,
    FxIdWebFeaturesStoreYandexGamesPurchaseResponseFromJSON,
    FxIdWebFeaturesStoreYandexGamesPurchaseResponseToJSON,
} from '../models/index';

export interface FxIdWebFeaturesStoreYandexGamesPurchaseEndpointRequest {
    game: string;
    fxIdWebFeaturesStoreYandexGamesPurchaseRequest: FxIdWebFeaturesStoreYandexGamesPurchaseRequest;
}

/**
 * 
 */
export class YandexgamesApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesStoreYandexGamesPurchaseEndpointRaw(requestParameters: FxIdWebFeaturesStoreYandexGamesPurchaseEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreYandexGamesPurchaseResponse>> {
        if (requestParameters['game'] == null) {
            throw new runtime.RequiredError(
                'game',
                'Required parameter "game" was null or undefined when calling fxIdWebFeaturesStoreYandexGamesPurchaseEndpoint().'
            );
        }

        if (requestParameters['fxIdWebFeaturesStoreYandexGamesPurchaseRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesStoreYandexGamesPurchaseRequest',
                'Required parameter "fxIdWebFeaturesStoreYandexGamesPurchaseRequest" was null or undefined when calling fxIdWebFeaturesStoreYandexGamesPurchaseEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/yandexgames/purchase/{game}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters['game']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreYandexGamesPurchaseRequestToJSON(requestParameters['fxIdWebFeaturesStoreYandexGamesPurchaseRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreYandexGamesPurchaseResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreYandexGamesPurchaseEndpoint(game: string, fxIdWebFeaturesStoreYandexGamesPurchaseRequest: FxIdWebFeaturesStoreYandexGamesPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreYandexGamesPurchaseResponse> {
        const response = await this.fxIdWebFeaturesStoreYandexGamesPurchaseEndpointRaw({ game: game, fxIdWebFeaturesStoreYandexGamesPurchaseRequest: fxIdWebFeaturesStoreYandexGamesPurchaseRequest }, initOverrides);
        return await response.value();
    }

}
