/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesStoreYandexGamesPurchaseResponseResponseTokenResults } from './FxIdWebFeaturesStoreYandexGamesPurchaseResponseResponseTokenResults';
import {
    FxIdWebFeaturesStoreYandexGamesPurchaseResponseResponseTokenResultsFromJSON,
    FxIdWebFeaturesStoreYandexGamesPurchaseResponseResponseTokenResultsFromJSONTyped,
    FxIdWebFeaturesStoreYandexGamesPurchaseResponseResponseTokenResultsToJSON,
    FxIdWebFeaturesStoreYandexGamesPurchaseResponseResponseTokenResultsToJSONTyped,
} from './FxIdWebFeaturesStoreYandexGamesPurchaseResponseResponseTokenResults';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreYandexGamesPurchaseResponse
 */
export interface FxIdWebFeaturesStoreYandexGamesPurchaseResponse {
    /**
     * 
     * @type {Array<FxIdWebFeaturesStoreYandexGamesPurchaseResponseResponseTokenResults>}
     * @memberof FxIdWebFeaturesStoreYandexGamesPurchaseResponse
     */
    TokenSuccesses: Array<FxIdWebFeaturesStoreYandexGamesPurchaseResponseResponseTokenResults>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreYandexGamesPurchaseResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreYandexGamesPurchaseResponse(value: object): value is FxIdWebFeaturesStoreYandexGamesPurchaseResponse {
    if (!('TokenSuccesses' in value) || value['TokenSuccesses'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreYandexGamesPurchaseResponseFromJSON(json: any): FxIdWebFeaturesStoreYandexGamesPurchaseResponse {
    return FxIdWebFeaturesStoreYandexGamesPurchaseResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreYandexGamesPurchaseResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreYandexGamesPurchaseResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'TokenSuccesses': ((json['TokenSuccesses'] as Array<any>).map(FxIdWebFeaturesStoreYandexGamesPurchaseResponseResponseTokenResultsFromJSON)),
    };
}

export function FxIdWebFeaturesStoreYandexGamesPurchaseResponseToJSON(json: any): FxIdWebFeaturesStoreYandexGamesPurchaseResponse {
    return FxIdWebFeaturesStoreYandexGamesPurchaseResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreYandexGamesPurchaseResponseToJSONTyped(value?: FxIdWebFeaturesStoreYandexGamesPurchaseResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'TokenSuccesses': ((value['TokenSuccesses'] as Array<any>).map(FxIdWebFeaturesStoreYandexGamesPurchaseResponseResponseTokenResultsToJSON)),
    };
}

