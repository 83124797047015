/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsPresentationOptionsMinRatio } from './FxIdDomainSettingsPresentationOptionsMinRatio';
import {
    FxIdDomainSettingsPresentationOptionsMinRatioFromJSON,
    FxIdDomainSettingsPresentationOptionsMinRatioFromJSONTyped,
    FxIdDomainSettingsPresentationOptionsMinRatioToJSON,
    FxIdDomainSettingsPresentationOptionsMinRatioToJSONTyped,
} from './FxIdDomainSettingsPresentationOptionsMinRatio';
import type { FxIdDomainSettingsPresentationOptionsMinSize } from './FxIdDomainSettingsPresentationOptionsMinSize';
import {
    FxIdDomainSettingsPresentationOptionsMinSizeFromJSON,
    FxIdDomainSettingsPresentationOptionsMinSizeFromJSONTyped,
    FxIdDomainSettingsPresentationOptionsMinSizeToJSON,
    FxIdDomainSettingsPresentationOptionsMinSizeToJSONTyped,
} from './FxIdDomainSettingsPresentationOptionsMinSize';
import type { FxIdDomainSettingsPresentationOptionsMaxSize } from './FxIdDomainSettingsPresentationOptionsMaxSize';
import {
    FxIdDomainSettingsPresentationOptionsMaxSizeFromJSON,
    FxIdDomainSettingsPresentationOptionsMaxSizeFromJSONTyped,
    FxIdDomainSettingsPresentationOptionsMaxSizeToJSON,
    FxIdDomainSettingsPresentationOptionsMaxSizeToJSONTyped,
} from './FxIdDomainSettingsPresentationOptionsMaxSize';
import type { FxIdDomainSettingsPresentationOptionsMaxRatio } from './FxIdDomainSettingsPresentationOptionsMaxRatio';
import {
    FxIdDomainSettingsPresentationOptionsMaxRatioFromJSON,
    FxIdDomainSettingsPresentationOptionsMaxRatioFromJSONTyped,
    FxIdDomainSettingsPresentationOptionsMaxRatioToJSON,
    FxIdDomainSettingsPresentationOptionsMaxRatioToJSONTyped,
} from './FxIdDomainSettingsPresentationOptionsMaxRatio';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPresentationOptions
 */
export interface FxIdDomainSettingsPresentationOptions {
    /**
     * 
     * @type {FxIdDomainSettingsPresentationOptionsMinRatio}
     * @memberof FxIdDomainSettingsPresentationOptions
     */
    MinRatio?: FxIdDomainSettingsPresentationOptionsMinRatio | null;
    /**
     * 
     * @type {FxIdDomainSettingsPresentationOptionsMaxRatio}
     * @memberof FxIdDomainSettingsPresentationOptions
     */
    MaxRatio?: FxIdDomainSettingsPresentationOptionsMaxRatio | null;
    /**
     * 
     * @type {FxIdDomainSettingsPresentationOptionsMinSize}
     * @memberof FxIdDomainSettingsPresentationOptions
     */
    MinSize?: FxIdDomainSettingsPresentationOptionsMinSize | null;
    /**
     * 
     * @type {FxIdDomainSettingsPresentationOptionsMaxSize}
     * @memberof FxIdDomainSettingsPresentationOptions
     */
    MaxSize?: FxIdDomainSettingsPresentationOptionsMaxSize | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPresentationOptions interface.
 */
export function instanceOfFxIdDomainSettingsPresentationOptions(value: object): value is FxIdDomainSettingsPresentationOptions {
    return true;
}

export function FxIdDomainSettingsPresentationOptionsFromJSON(json: any): FxIdDomainSettingsPresentationOptions {
    return FxIdDomainSettingsPresentationOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPresentationOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPresentationOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'MinRatio': json['MinRatio'] == null ? undefined : FxIdDomainSettingsPresentationOptionsMinRatioFromJSON(json['MinRatio']),
        'MaxRatio': json['MaxRatio'] == null ? undefined : FxIdDomainSettingsPresentationOptionsMaxRatioFromJSON(json['MaxRatio']),
        'MinSize': json['MinSize'] == null ? undefined : FxIdDomainSettingsPresentationOptionsMinSizeFromJSON(json['MinSize']),
        'MaxSize': json['MaxSize'] == null ? undefined : FxIdDomainSettingsPresentationOptionsMaxSizeFromJSON(json['MaxSize']),
    };
}

export function FxIdDomainSettingsPresentationOptionsToJSON(json: any): FxIdDomainSettingsPresentationOptions {
    return FxIdDomainSettingsPresentationOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPresentationOptionsToJSONTyped(value?: FxIdDomainSettingsPresentationOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'MinRatio': FxIdDomainSettingsPresentationOptionsMinRatioToJSON(value['MinRatio']),
        'MaxRatio': FxIdDomainSettingsPresentationOptionsMaxRatioToJSON(value['MaxRatio']),
        'MinSize': FxIdDomainSettingsPresentationOptionsMinSizeToJSON(value['MinSize']),
        'MaxSize': FxIdDomainSettingsPresentationOptionsMaxSizeToJSON(value['MaxSize']),
    };
}

