/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions } from './FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions';

/**
 * @type FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGames
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGames = FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions;

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGames {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGames {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions(json)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGames | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions);
    }

    return {};
}

