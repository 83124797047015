/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData } from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData';
import {
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataFromJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataFromJSONTyped,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataToJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataToJSONTyped,
} from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData
 */
export interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData {
    /**
     * 
     * @type {FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData
     */
    AwGameData?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData interface.
 */
export function instanceOfFxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData(value: object): value is FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData {
    return true;
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataFromJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData {
    if (json == null) {
        return json;
    }
    return {
        
        'AwGameData': json['AwGameData'] == null ? undefined : FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataFromJSON(json['AwGameData']),
    };
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataToJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataToJSONTyped(value?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AwGameData': FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataToJSON(value['AwGameData']),
    };
}

