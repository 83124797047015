/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptions } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptions';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptions';

/**
 * @type FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQ
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQ = FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptions;

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQ {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQ {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptions(json)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQ | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQLoginOptions);
    }

    return {};
}

