/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks {
    /**
     * ссылка на веб магазин для лончера для игр для которых мы не сделали свой
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks
     */
    ShopUrl: string;
    /**
     * ссылка на веб саппорт для лончера
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks
     */
    SupportUrl: string;
    /**
     * ссылка на станицу помощи для лончера
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks
     */
    HelpUrl: string;
    /**
     * ссылка которую нужно дернуть когда игра запускается
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks
     */
    OnStartHookUrl: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks {
    if (!('ShopUrl' in value) || value['ShopUrl'] === undefined) return false;
    if (!('SupportUrl' in value) || value['SupportUrl'] === undefined) return false;
    if (!('HelpUrl' in value) || value['HelpUrl'] === undefined) return false;
    if (!('OnStartHookUrl' in value) || value['OnStartHookUrl'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinksFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinksFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks {
    if (json == null) {
        return json;
    }
    return {
        
        'ShopUrl': json['ShopUrl'],
        'SupportUrl': json['SupportUrl'],
        'HelpUrl': json['HelpUrl'],
        'OnStartHookUrl': json['OnStartHookUrl'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinksToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinksToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinksToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ShopUrl': value['ShopUrl'],
        'SupportUrl': value['SupportUrl'],
        'HelpUrl': value['HelpUrl'],
        'OnStartHookUrl': value['OnStartHookUrl'],
    };
}

