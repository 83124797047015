/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions
 */
export interface FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions
     */
    SteamId: number;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayPlatformDataOptionsSteamOptions(value: object): value is FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions {
    if (!('SteamId' in value) || value['SteamId'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsSteamOptionsFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsSteamOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsSteamOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'SteamId': json['SteamId'],
    };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsSteamOptionsToJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsSteamOptionsToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsSteamOptionsToJSONTyped(value?: FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'SteamId': value['SteamId'],
    };
}

