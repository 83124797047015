/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData } from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthDataFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthDataFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthDataToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData';

/**
 * @type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLogin
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLogin = FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData;

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLogin {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLogin {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData(json)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLogin | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthDataToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamOpenIdSteamOpenIdAuthData);
    }

    return {};
}

