/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest
 */
export interface FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest
     */
    FriendAccountId: number;
}

/**
 * Check if a given object implements the FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest interface.
 */
export function instanceOfFxIdWebFeaturesFriendsSendFriendInviteNotificationRequest(value: object): value is FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest {
    if (!('FriendAccountId' in value) || value['FriendAccountId'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesFriendsSendFriendInviteNotificationRequestFromJSON(json: any): FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest {
    return FxIdWebFeaturesFriendsSendFriendInviteNotificationRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesFriendsSendFriendInviteNotificationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'FriendAccountId': json['FriendAccountId'],
    };
}

export function FxIdWebFeaturesFriendsSendFriendInviteNotificationRequestToJSON(json: any): FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest {
    return FxIdWebFeaturesFriendsSendFriendInviteNotificationRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesFriendsSendFriendInviteNotificationRequestToJSONTyped(value?: FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'FriendAccountId': value['FriendAccountId'],
    };
}

