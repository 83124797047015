/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState {
    CanBeTransferred = 0,
    AlreadyTransferred = 1,
    NUMBER_unknown_default_open_api = 11184809
}


export function instanceOfFxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState(value: any): boolean {
    for (const key in FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState) {
        if (Object.prototype.hasOwnProperty.call(FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState, key)) {
            if (FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState[key as keyof typeof FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateFromJSON(json: any): FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState {
    return FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState {
    return json as FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState;
}

export function FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateToJSON(value?: FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState | null): any {
    return value as any;
}

export function FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateToJSONTyped(value: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState {
    return value as FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState;
}

