/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions } from './FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions';
import {
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions,
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions';

/**
 * @type FxIdDomainSettingsCompanyBrandingConfigFirebaseOptions
 * Настройки компании для Firebase.
 * Используются для авторизации на портале или если не указаны индивидуальные настройки для игры
 * @export
 */
export type FxIdDomainSettingsCompanyBrandingConfigFirebaseOptions = FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions;

export function FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsFromJSON(json: any): FxIdDomainSettingsCompanyBrandingConfigFirebaseOptions {
    return FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsCompanyBrandingConfigFirebaseOptions {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions(json)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsToJSON(json: any): any {
    return FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsCompanyBrandingConfigFirebaseOptionsToJSONTyped(value?: FxIdDomainSettingsCompanyBrandingConfigFirebaseOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions);
    }

    return {};
}

