/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier';
import {
    instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier';

/**
 * @type FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier = FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier;

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier(json)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier(value)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierToJSON(value as FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier);
    }

    return {};
}

