/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse
 */
export interface FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse
     */
    RedirectUrl: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse(value: object): value is FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse {
    if (!('RedirectUrl' in value) || value['RedirectUrl'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseFromJSON(json: any): FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse {
    return FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'RedirectUrl': json['RedirectUrl'],
    };
}

export function FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseToJSON(json: any): FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse {
    return FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseToJSONTyped(value?: FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'RedirectUrl': value['RedirectUrl'],
    };
}

