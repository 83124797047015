/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsAdvertisementYandexRewardedOptions
 */
export interface FxIdDomainSettingsAdvertisementYandexRewardedOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsAdvertisementYandexRewardedOptions
     */
    MobileBlockId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsAdvertisementYandexRewardedOptions
     */
    DesktopBlockId?: string | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsAdvertisementYandexRewardedOptions interface.
 */
export function instanceOfFxIdDomainSettingsAdvertisementYandexRewardedOptions(value: object): value is FxIdDomainSettingsAdvertisementYandexRewardedOptions {
    return true;
}

export function FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSON(json: any): FxIdDomainSettingsAdvertisementYandexRewardedOptions {
    return FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementYandexRewardedOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'MobileBlockId': json['MobileBlockId'] == null ? undefined : json['MobileBlockId'],
        'DesktopBlockId': json['DesktopBlockId'] == null ? undefined : json['DesktopBlockId'],
    };
}

export function FxIdDomainSettingsAdvertisementYandexRewardedOptionsToJSON(json: any): FxIdDomainSettingsAdvertisementYandexRewardedOptions {
    return FxIdDomainSettingsAdvertisementYandexRewardedOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementYandexRewardedOptionsToJSONTyped(value?: FxIdDomainSettingsAdvertisementYandexRewardedOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'MobileBlockId': value['MobileBlockId'],
        'DesktopBlockId': value['DesktopBlockId'],
    };
}

