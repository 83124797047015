/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings {
    /**
     * путь до лог файлов специфических для этой игры
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings
     */
    GameSpecificLogPath: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings {
    if (!('GameSpecificLogPath' in value) || value['GameSpecificLogPath'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'GameSpecificLogPath': json['GameSpecificLogPath'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameSpecificLogPath': value['GameSpecificLogPath'],
    };
}

