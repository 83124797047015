/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks';
import {
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinksFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinksFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinksToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks(json)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinksFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinksToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherLinks);
    }

    return {};
}

