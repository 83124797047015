/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreBank131PayWithSbpResponse
 */
export interface FxIdWebFeaturesStoreBank131PayWithSbpResponse {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreBank131PayWithSbpResponse
     */
    SbpLink: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreBank131PayWithSbpResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreBank131PayWithSbpResponse(value: object): value is FxIdWebFeaturesStoreBank131PayWithSbpResponse {
    if (!('SbpLink' in value) || value['SbpLink'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreBank131PayWithSbpResponseFromJSON(json: any): FxIdWebFeaturesStoreBank131PayWithSbpResponse {
    return FxIdWebFeaturesStoreBank131PayWithSbpResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PayWithSbpResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreBank131PayWithSbpResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'SbpLink': json['SbpLink'],
    };
}

export function FxIdWebFeaturesStoreBank131PayWithSbpResponseToJSON(json: any): FxIdWebFeaturesStoreBank131PayWithSbpResponse {
    return FxIdWebFeaturesStoreBank131PayWithSbpResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PayWithSbpResponseToJSONTyped(value?: FxIdWebFeaturesStoreBank131PayWithSbpResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'SbpLink': value['SbpLink'],
    };
}

