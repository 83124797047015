/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FxIdWebFeaturesGamesListResponse,
  FxIdWebFeaturesGamesListWithConfigsResponse,
} from '../models/index';
import {
    FxIdWebFeaturesGamesListResponseFromJSON,
    FxIdWebFeaturesGamesListResponseToJSON,
    FxIdWebFeaturesGamesListWithConfigsResponseFromJSON,
    FxIdWebFeaturesGamesListWithConfigsResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class GamesApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesGamesListEndpointRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesGamesListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/games/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesGamesListResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesGamesListEndpoint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesGamesListResponse> {
        const response = await this.fxIdWebFeaturesGamesListEndpointRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesGamesListWithConfigsEndpointRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesGamesListWithConfigsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/games/list_with_configs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesGamesListWithConfigsResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesGamesListWithConfigsEndpoint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesGamesListWithConfigsResponse> {
        const response = await this.fxIdWebFeaturesGamesListWithConfigsEndpointRaw(initOverrides);
        return await response.value();
    }

}
