/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthData } from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthData';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthData,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthDataFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthDataFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthDataToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthData';

/**
 * @type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLogin
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLogin = FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthData;

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLogin {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLogin {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthData(json)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLogin | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthData(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthDataToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersSteamSteamAuthData);
    }

    return {};
}

