/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption {
    Unspecified = 0,
    Disabled = 1,
    Brotli = 2,
    Gzip = 3,
    NUMBER_unknown_default_open_api = 11184809
}


export function instanceOfFxIdDomainSettingsUnityOptionsDistributionForceCompressionOption(value: any): boolean {
    for (const key in FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption) {
        if (Object.prototype.hasOwnProperty.call(FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption, key)) {
            if (FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption[key as keyof typeof FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FxIdDomainSettingsUnityOptionsDistributionForceCompressionOptionFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption {
    return FxIdDomainSettingsUnityOptionsDistributionForceCompressionOptionFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionForceCompressionOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption {
    return json as FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption;
}

export function FxIdDomainSettingsUnityOptionsDistributionForceCompressionOptionToJSON(value?: FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption | null): any {
    return value as any;
}

export function FxIdDomainSettingsUnityOptionsDistributionForceCompressionOptionToJSONTyped(value: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption {
    return value as FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption;
}

