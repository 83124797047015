/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreStoreDataResponseStoreMeta
 */
export interface FxIdWebFeaturesStoreStoreDataResponseStoreMeta {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesStoreStoreDataResponseStoreMeta
     */
    SubscriptionsSupported: boolean;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreStoreDataResponseStoreMeta interface.
 */
export function instanceOfFxIdWebFeaturesStoreStoreDataResponseStoreMeta(value: object): value is FxIdWebFeaturesStoreStoreDataResponseStoreMeta {
    if (!('SubscriptionsSupported' in value) || value['SubscriptionsSupported'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreStoreDataResponseStoreMetaFromJSON(json: any): FxIdWebFeaturesStoreStoreDataResponseStoreMeta {
    return FxIdWebFeaturesStoreStoreDataResponseStoreMetaFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreStoreDataResponseStoreMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreStoreDataResponseStoreMeta {
    if (json == null) {
        return json;
    }
    return {
        
        'SubscriptionsSupported': json['SubscriptionsSupported'],
    };
}

export function FxIdWebFeaturesStoreStoreDataResponseStoreMetaToJSON(json: any): FxIdWebFeaturesStoreStoreDataResponseStoreMeta {
    return FxIdWebFeaturesStoreStoreDataResponseStoreMetaToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreStoreDataResponseStoreMetaToJSONTyped(value?: FxIdWebFeaturesStoreStoreDataResponseStoreMeta | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'SubscriptionsSupported': value['SubscriptionsSupported'],
    };
}

