/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsAdvertisementOptionsYandex } from './FxIdDomainSettingsAdvertisementOptionsYandex';
import {
    FxIdDomainSettingsAdvertisementOptionsYandexFromJSON,
    FxIdDomainSettingsAdvertisementOptionsYandexFromJSONTyped,
    FxIdDomainSettingsAdvertisementOptionsYandexToJSON,
    FxIdDomainSettingsAdvertisementOptionsYandexToJSONTyped,
} from './FxIdDomainSettingsAdvertisementOptionsYandex';
import type { FxIdDomainSettingsAdvertisementOptionsFacebookInstantGames } from './FxIdDomainSettingsAdvertisementOptionsFacebookInstantGames';
import {
    FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesFromJSON,
    FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesFromJSONTyped,
    FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesToJSON,
    FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesToJSONTyped,
} from './FxIdDomainSettingsAdvertisementOptionsFacebookInstantGames';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsAdvertisementOptions
 */
export interface FxIdDomainSettingsAdvertisementOptions {
    /**
     * 
     * @type {FxIdDomainSettingsAdvertisementOptionsYandex}
     * @memberof FxIdDomainSettingsAdvertisementOptions
     */
    Yandex?: FxIdDomainSettingsAdvertisementOptionsYandex | null;
    /**
     * 
     * @type {FxIdDomainSettingsAdvertisementOptionsFacebookInstantGames}
     * @memberof FxIdDomainSettingsAdvertisementOptions
     */
    FacebookInstantGames?: FxIdDomainSettingsAdvertisementOptionsFacebookInstantGames | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsAdvertisementOptions interface.
 */
export function instanceOfFxIdDomainSettingsAdvertisementOptions(value: object): value is FxIdDomainSettingsAdvertisementOptions {
    return true;
}

export function FxIdDomainSettingsAdvertisementOptionsFromJSON(json: any): FxIdDomainSettingsAdvertisementOptions {
    return FxIdDomainSettingsAdvertisementOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'Yandex': json['Yandex'] == null ? undefined : FxIdDomainSettingsAdvertisementOptionsYandexFromJSON(json['Yandex']),
        'FacebookInstantGames': json['FacebookInstantGames'] == null ? undefined : FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesFromJSON(json['FacebookInstantGames']),
    };
}

export function FxIdDomainSettingsAdvertisementOptionsToJSON(json: any): FxIdDomainSettingsAdvertisementOptions {
    return FxIdDomainSettingsAdvertisementOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementOptionsToJSONTyped(value?: FxIdDomainSettingsAdvertisementOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Yandex': FxIdDomainSettingsAdvertisementOptionsYandexToJSON(value['Yandex']),
        'FacebookInstantGames': FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesToJSON(value['FacebookInstantGames']),
    };
}

