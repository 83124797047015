/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsDimensions } from './FxIdDomainSettingsDimensions';
import {
    instanceOfFxIdDomainSettingsDimensions,
    FxIdDomainSettingsDimensionsFromJSON,
    FxIdDomainSettingsDimensionsFromJSONTyped,
    FxIdDomainSettingsDimensionsToJSON,
} from './FxIdDomainSettingsDimensions';

/**
 * @type FxIdDomainSettingsPresentationOptionsMaxSize
 * Максимальный размер
 * @export
 */
export type FxIdDomainSettingsPresentationOptionsMaxSize = FxIdDomainSettingsDimensions;

export function FxIdDomainSettingsPresentationOptionsMaxSizeFromJSON(json: any): FxIdDomainSettingsPresentationOptionsMaxSize {
    return FxIdDomainSettingsPresentationOptionsMaxSizeFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPresentationOptionsMaxSizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPresentationOptionsMaxSize {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsDimensions(json)) {
        return FxIdDomainSettingsDimensionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPresentationOptionsMaxSizeToJSON(json: any): any {
    return FxIdDomainSettingsPresentationOptionsMaxSizeToJSONTyped(json, false);
}

export function FxIdDomainSettingsPresentationOptionsMaxSizeToJSONTyped(value?: FxIdDomainSettingsPresentationOptionsMaxSize | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsDimensions(value)) {
        return FxIdDomainSettingsDimensionsToJSON(value as FxIdDomainSettingsDimensions);
    }

    return {};
}

