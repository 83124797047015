/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData';
import {
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantDataFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantDataFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantDataToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData(json)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesToJSON(json: any): any {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantDataToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData);
    }

    return {};
}

