/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsAdvertisementYandexRewardedOptions } from './FxIdDomainSettingsAdvertisementYandexRewardedOptions';
import {
    instanceOfFxIdDomainSettingsAdvertisementYandexRewardedOptions,
    FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSON,
    FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSONTyped,
    FxIdDomainSettingsAdvertisementYandexRewardedOptionsToJSON,
} from './FxIdDomainSettingsAdvertisementYandexRewardedOptions';

/**
 * @type FxIdDomainSettingsAdvertisementYandexOptionsRewarded
 * 
 * @export
 */
export type FxIdDomainSettingsAdvertisementYandexOptionsRewarded = FxIdDomainSettingsAdvertisementYandexRewardedOptions;

export function FxIdDomainSettingsAdvertisementYandexOptionsRewardedFromJSON(json: any): FxIdDomainSettingsAdvertisementYandexOptionsRewarded {
    return FxIdDomainSettingsAdvertisementYandexOptionsRewardedFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementYandexOptionsRewardedFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementYandexOptionsRewarded {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsAdvertisementYandexRewardedOptions(json)) {
        return FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsAdvertisementYandexOptionsRewardedToJSON(json: any): any {
    return FxIdDomainSettingsAdvertisementYandexOptionsRewardedToJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementYandexOptionsRewardedToJSONTyped(value?: FxIdDomainSettingsAdvertisementYandexOptionsRewarded | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsAdvertisementYandexRewardedOptions(value)) {
        return FxIdDomainSettingsAdvertisementYandexRewardedOptionsToJSON(value as FxIdDomainSettingsAdvertisementYandexRewardedOptions);
    }

    return {};
}

