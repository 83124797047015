/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials';
import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsToJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials';
import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier';
import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierToJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier';
import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptions } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptions';
import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsToJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptions';
import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo';
import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoToJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    Locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    RefreshToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    SessionId?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    EmailCredentials?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    ExternalSocialIdentifier?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    ExternalInfo?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptions}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    AdditionalOptions?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptions | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdRequest(value: object): value is FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest {
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'Locale': json['Locale'] == null ? undefined : json['Locale'],
        'RefreshToken': json['RefreshToken'] == null ? undefined : json['RefreshToken'],
        'SessionId': json['SessionId'] == null ? undefined : json['SessionId'],
        'EmailCredentials': json['EmailCredentials'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsFromJSON(json['EmailCredentials']),
        'ExternalSocialIdentifier': json['ExternalSocialIdentifier'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSON(json['ExternalSocialIdentifier']),
        'ExternalInfo': json['ExternalInfo'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoFromJSON(json['ExternalInfo']),
        'AdditionalOptions': json['AdditionalOptions'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsFromJSON(json['AdditionalOptions']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestToJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Locale': value['Locale'],
        'RefreshToken': value['RefreshToken'],
        'SessionId': value['SessionId'],
        'EmailCredentials': FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsToJSON(value['EmailCredentials']),
        'ExternalSocialIdentifier': FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierToJSON(value['ExternalSocialIdentifier']),
        'ExternalInfo': FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoToJSON(value['ExternalInfo']),
        'AdditionalOptions': FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsToJSON(value['AdditionalOptions']),
    };
}

