/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions';
import {
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentRequestSteam
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentRequestSteam = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions;

export function FxIdWebFeaturesStoreCreatePaymentRequestSteamFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequestSteam {
    return FxIdWebFeaturesStoreCreatePaymentRequestSteamFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestSteamFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentRequestSteam {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions(json)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesStoreCreatePaymentRequestSteamToJSON(json: any): any {
    return FxIdWebFeaturesStoreCreatePaymentRequestSteamToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestSteamToJSONTyped(value?: FxIdWebFeaturesStoreCreatePaymentRequestSteam | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions);
    }

    return {};
}

