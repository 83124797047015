/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesInviteEmailInviteRequest
 */
export interface FxIdWebFeaturesInviteEmailInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailInviteRequest
     */
    Email: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailInviteRequest
     */
    GameSystemName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesInviteEmailInviteRequest
     */
    Game?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailInviteRequest
     */
    CaptchaResult: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesInviteEmailInviteRequest interface.
 */
export function instanceOfFxIdWebFeaturesInviteEmailInviteRequest(value: object): value is FxIdWebFeaturesInviteEmailInviteRequest {
    if (!('Email' in value) || value['Email'] === undefined) return false;
    if (!('CaptchaResult' in value) || value['CaptchaResult'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesInviteEmailInviteRequestFromJSON(json: any): FxIdWebFeaturesInviteEmailInviteRequest {
    return FxIdWebFeaturesInviteEmailInviteRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteEmailInviteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInviteEmailInviteRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'Email': json['Email'],
        'GameSystemName': json['GameSystemName'] == null ? undefined : json['GameSystemName'],
        'Game': json['Game'] == null ? undefined : json['Game'],
        'CaptchaResult': json['CaptchaResult'],
    };
}

export function FxIdWebFeaturesInviteEmailInviteRequestToJSON(json: any): FxIdWebFeaturesInviteEmailInviteRequest {
    return FxIdWebFeaturesInviteEmailInviteRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteEmailInviteRequestToJSONTyped(value?: FxIdWebFeaturesInviteEmailInviteRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Email': value['Email'],
        'GameSystemName': value['GameSystemName'],
        'Game': value['Game'],
        'CaptchaResult': value['CaptchaResult'],
    };
}

