/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ВНИМАНИЕ! Строковые имена этого энума используются в у клинтов
 * менять их нельзя или надо делать мапинг
 *             
 * SocialIdentifierType в протобуфах нужно обновлять после обновления тут
 * @export
 * @enum {string}
 */
export enum FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType {
    Unspecified = 0,
    Email = 1,
    Telegram = 2,
    Firebase = 3,
    VkontaktePlay = 4,
    Odnoklassniki = 5,
    VkontakteCom = 6,
    YandexGames = 7,
    FxIdGuest = 8,
    YandexGamesGuest = 9,
    Facebook = 10,
    Google = 11,
    Apple = 12,
    AbsoluteGamesGuest = 13,
    AbsoluteGames = 14,
    KongregateGuest = 15,
    Kongregate = 16,
    VkontakteComOauth = 17,
    Xsolla = 18,
    FacebookGames = 19,
    CrazyGamesGuest = 20,
    CrazyGames = 21,
    MyGamesId = 22,
    FacebookNamespaced = 23,
    WizQ = 24,
    Steam = 25,
    Egs = 26,
    Twitch = 27,
    NUMBER_unknown_default_open_api = 11184809
}


export function instanceOfFxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType(value: any): boolean {
    for (const key in FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType) {
        if (Object.prototype.hasOwnProperty.call(FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType, key)) {
            if (FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType[key as keyof typeof FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON(json: any): FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType {
    return FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSONTyped(json, false);
}

export function FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType {
    return json as FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType;
}

export function FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON(value?: FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType | null): any {
    return value as any;
}

export function FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType {
    return value as FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType;
}

