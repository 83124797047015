/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResult } from './FxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResult';
import {
    instanceOfFxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResult,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResultFromJSON,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResultFromJSONTyped,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResultToJSON,
} from './FxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResult';

/**
 * @type FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult
 * 
 * @export
 */
export type FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult = FxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResult;

export function FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultFromJSON(json: any): FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult {
    return FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResult(json)) {
        return FxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResultFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultToJSON(json: any): any {
    return FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultToJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultToJSONTyped(value?: FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResult(value)) {
        return FxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResultToJSON(value as FxIdWebFeaturesProfileFetchFxIdGameConnectionFetchGameConnectionSuccessfulResult);
    }

    return {};
}

