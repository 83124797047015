/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesFriendsSetStatusRequest
 */
export interface FxIdWebFeaturesFriendsSetStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesFriendsSetStatusRequest
     */
    Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesFriendsSetStatusRequest
     */
    Status: string;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesFriendsSetStatusRequest
     */
    Priority: number;
}

/**
 * Check if a given object implements the FxIdWebFeaturesFriendsSetStatusRequest interface.
 */
export function instanceOfFxIdWebFeaturesFriendsSetStatusRequest(value: object): value is FxIdWebFeaturesFriendsSetStatusRequest {
    if (!('Status' in value) || value['Status'] === undefined) return false;
    if (!('Priority' in value) || value['Priority'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesFriendsSetStatusRequestFromJSON(json: any): FxIdWebFeaturesFriendsSetStatusRequest {
    return FxIdWebFeaturesFriendsSetStatusRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesFriendsSetStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesFriendsSetStatusRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'Name': json['Name'] == null ? undefined : json['Name'],
        'Status': json['Status'],
        'Priority': json['Priority'],
    };
}

export function FxIdWebFeaturesFriendsSetStatusRequestToJSON(json: any): FxIdWebFeaturesFriendsSetStatusRequest {
    return FxIdWebFeaturesFriendsSetStatusRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesFriendsSetStatusRequestToJSONTyped(value?: FxIdWebFeaturesFriendsSetStatusRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Name': value['Name'],
        'Status': value['Status'],
        'Priority': value['Priority'],
    };
}

