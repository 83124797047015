/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData
     */
    ConfirmationUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData
     */
    PaymentToken: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData
     */
    Sandbox: boolean;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData(value: object): value is FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData {
    if (!('ConfirmationUrl' in value) || value['ConfirmationUrl'] === undefined) return false;
    if (!('PaymentToken' in value) || value['PaymentToken'] === undefined) return false;
    if (!('Sandbox' in value) || value['Sandbox'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantDataFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData {
    if (json == null) {
        return json;
    }
    return {
        
        'ConfirmationUrl': json['ConfirmationUrl'],
        'PaymentToken': json['PaymentToken'],
        'Sandbox': json['Sandbox'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantDataToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantDataToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantDataToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ConfirmationUrl': value['ConfirmationUrl'],
        'PaymentToken': value['PaymentToken'],
        'Sandbox': value['Sandbox'],
    };
}

