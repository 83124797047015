/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId
 */
export interface FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId
     */
    GameSystemName: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId interface.
 */
export function instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId(value: object): value is FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId {
    if (!('GameSystemName' in value) || value['GameSystemName'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxIdFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId {
    return FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxIdFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId {
    if (json == null) {
        return json;
    }
    return {
        
        'GameSystemName': json['GameSystemName'],
    };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxIdToJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId {
    return FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxIdToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxIdToJSONTyped(value?: FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameSystemName': value['GameSystemName'],
    };
}

