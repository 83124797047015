/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainStoreEnumsOrderState } from './FxIdDomainStoreEnumsOrderState';
import {
    FxIdDomainStoreEnumsOrderStateFromJSON,
    FxIdDomainStoreEnumsOrderStateFromJSONTyped,
    FxIdDomainStoreEnumsOrderStateToJSON,
    FxIdDomainStoreEnumsOrderStateToJSONTyped,
} from './FxIdDomainStoreEnumsOrderState';
import type { FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData } from './FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData';
import {
    FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSON,
    FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSONTyped,
    FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataToJSON,
    FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataToJSONTyped,
} from './FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData';
import type { FxIdApplicationStorePriceData } from './FxIdApplicationStorePriceData';
import {
    FxIdApplicationStorePriceDataFromJSON,
    FxIdApplicationStorePriceDataFromJSONTyped,
    FxIdApplicationStorePriceDataToJSON,
    FxIdApplicationStorePriceDataToJSONTyped,
} from './FxIdApplicationStorePriceData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreGetPaymentStatusResponse
 */
export interface FxIdWebFeaturesStoreGetPaymentStatusResponse {
    /**
     * 
     * @type {FxIdDomainStoreEnumsOrderState}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponse
     */
    Status: FxIdDomainStoreEnumsOrderState;
    /**
     * 
     * @type {FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponse
     */
    OrderProducts: FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData;
    /**
     * 
     * @type {FxIdApplicationStorePriceData}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponse
     */
    TotalPrice: FxIdApplicationStorePriceData;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponse
     */
    Game: string;
}



/**
 * Check if a given object implements the FxIdWebFeaturesStoreGetPaymentStatusResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreGetPaymentStatusResponse(value: object): value is FxIdWebFeaturesStoreGetPaymentStatusResponse {
    if (!('Status' in value) || value['Status'] === undefined) return false;
    if (!('OrderProducts' in value) || value['OrderProducts'] === undefined) return false;
    if (!('TotalPrice' in value) || value['TotalPrice'] === undefined) return false;
    if (!('Game' in value) || value['Game'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseFromJSON(json: any): FxIdWebFeaturesStoreGetPaymentStatusResponse {
    return FxIdWebFeaturesStoreGetPaymentStatusResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreGetPaymentStatusResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Status': FxIdDomainStoreEnumsOrderStateFromJSON(json['Status']),
        'OrderProducts': FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSON(json['OrderProducts']),
        'TotalPrice': FxIdApplicationStorePriceDataFromJSON(json['TotalPrice']),
        'Game': json['Game'],
    };
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseToJSON(json: any): FxIdWebFeaturesStoreGetPaymentStatusResponse {
    return FxIdWebFeaturesStoreGetPaymentStatusResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseToJSONTyped(value?: FxIdWebFeaturesStoreGetPaymentStatusResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Status': FxIdDomainStoreEnumsOrderStateToJSON(value['Status']),
        'OrderProducts': FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataToJSON(value['OrderProducts']),
        'TotalPrice': FxIdApplicationStorePriceDataToJSON(value['TotalPrice']),
        'Game': value['Game'],
    };
}

