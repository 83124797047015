/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromGameRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateFromGameRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    Token: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    Game: string;
    /**
     * Игра откроет ссылку с этми параметром
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    Platform?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    Standalone?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    DeviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    Locale?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromGameRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromGameRequest(value: object): value is FxIdWebFeaturesAuthAuthenticateFromGameRequest {
    if (!('Token' in value) || value['Token'] === undefined) return false;
    if (!('Game' in value) || value['Game'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromGameRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromGameRequest {
    return FxIdWebFeaturesAuthAuthenticateFromGameRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromGameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromGameRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'Token': json['Token'],
        'Game': json['Game'],
        'Platform': json['Platform'] == null ? undefined : json['Platform'],
        'Standalone': json['Standalone'] == null ? undefined : json['Standalone'],
        'DeviceId': json['DeviceId'] == null ? undefined : json['DeviceId'],
        'Locale': json['Locale'] == null ? undefined : json['Locale'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromGameRequestToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromGameRequest {
    return FxIdWebFeaturesAuthAuthenticateFromGameRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromGameRequestToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromGameRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Token': value['Token'],
        'Game': value['Game'],
        'Platform': value['Platform'],
        'Standalone': value['Standalone'],
        'DeviceId': value['DeviceId'],
        'Locale': value['Locale'],
    };
}

