/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainStoreStoreProductLabels } from './FxIdDomainStoreStoreProductLabels';
import {
    instanceOfFxIdDomainStoreStoreProductLabels,
    FxIdDomainStoreStoreProductLabelsFromJSON,
    FxIdDomainStoreStoreProductLabelsFromJSONTyped,
    FxIdDomainStoreStoreProductLabelsToJSON,
} from './FxIdDomainStoreStoreProductLabels';

/**
 * @type FxIdApplicationStoreProductDataLabels
 * 
 * @export
 */
export type FxIdApplicationStoreProductDataLabels = FxIdDomainStoreStoreProductLabels;

export function FxIdApplicationStoreProductDataLabelsFromJSON(json: any): FxIdApplicationStoreProductDataLabels {
    return FxIdApplicationStoreProductDataLabelsFromJSONTyped(json, false);
}

export function FxIdApplicationStoreProductDataLabelsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreProductDataLabels {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainStoreStoreProductLabels(json)) {
        return FxIdDomainStoreStoreProductLabelsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdApplicationStoreProductDataLabelsToJSON(json: any): any {
    return FxIdApplicationStoreProductDataLabelsToJSONTyped(json, false);
}

export function FxIdApplicationStoreProductDataLabelsToJSONTyped(value?: FxIdApplicationStoreProductDataLabels | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainStoreStoreProductLabels(value)) {
        return FxIdDomainStoreStoreProductLabelsToJSON(value as FxIdDomainStoreStoreProductLabels);
    }

    return {};
}

