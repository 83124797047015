/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeToJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeToJSONTyped,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownload } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownload';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadToJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadToJSONTyped,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownload';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSONTyped,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent';

/**
 * 
 * @export
 * @interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
 */
export interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact {
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    SourceBuildNumber: number;
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    DestinationBuildNumber: number;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    Torrent: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownload}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    HttpDownload: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownload;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    ManifestUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    ManifestSha256: string;
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    PatchSize: number;
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    FullSize: number;
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    MaxFileSize: number;
    /**
     * 
     * @type {Date}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    CreatedAt: Date;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    Type: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType;
}



/**
 * Check if a given object implements the FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact interface.
 */
export function instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact(value: object): value is FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact {
    if (!('SourceBuildNumber' in value) || value['SourceBuildNumber'] === undefined) return false;
    if (!('DestinationBuildNumber' in value) || value['DestinationBuildNumber'] === undefined) return false;
    if (!('Torrent' in value) || value['Torrent'] === undefined) return false;
    if (!('HttpDownload' in value) || value['HttpDownload'] === undefined) return false;
    if (!('ManifestUrl' in value) || value['ManifestUrl'] === undefined) return false;
    if (!('ManifestSha256' in value) || value['ManifestSha256'] === undefined) return false;
    if (!('PatchSize' in value) || value['PatchSize'] === undefined) return false;
    if (!('FullSize' in value) || value['FullSize'] === undefined) return false;
    if (!('MaxFileSize' in value) || value['MaxFileSize'] === undefined) return false;
    if (!('CreatedAt' in value) || value['CreatedAt'] === undefined) return false;
    if (!('Type' in value) || value['Type'] === undefined) return false;
    return true;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact {
    if (json == null) {
        return json;
    }
    return {
        
        'SourceBuildNumber': json['sourceBuildNumber'],
        'DestinationBuildNumber': json['destinationBuildNumber'],
        'Torrent': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSON(json['torrent']),
        'HttpDownload': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadFromJSON(json['httpDownload']),
        'ManifestUrl': json['manifestUrl'],
        'ManifestSha256': json['manifestSha256'],
        'PatchSize': json['patchSize'],
        'FullSize': json['fullSize'],
        'MaxFileSize': json['maxFileSize'],
        'CreatedAt': (new Date(json['createdAt'])),
        'Type': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeFromJSON(json['type']),
    };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactToJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactToJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactToJSONTyped(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'sourceBuildNumber': value['SourceBuildNumber'],
        'destinationBuildNumber': value['DestinationBuildNumber'],
        'torrent': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSON(value['Torrent']),
        'httpDownload': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadToJSON(value['HttpDownload']),
        'manifestUrl': value['ManifestUrl'],
        'manifestSha256': value['ManifestSha256'],
        'patchSize': value['PatchSize'],
        'fullSize': value['FullSize'],
        'maxFileSize': value['MaxFileSize'],
        'createdAt': ((value['CreatedAt']).toISOString()),
        'type': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeToJSON(value['Type']),
    };
}

