/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsDimensions
 */
export interface FxIdDomainSettingsDimensions {
    /**
     * Ширина
     * @type {number}
     * @memberof FxIdDomainSettingsDimensions
     */
    Width: number;
    /**
     * Высота
     * @type {number}
     * @memberof FxIdDomainSettingsDimensions
     */
    Height: number;
}

/**
 * Check if a given object implements the FxIdDomainSettingsDimensions interface.
 */
export function instanceOfFxIdDomainSettingsDimensions(value: object): value is FxIdDomainSettingsDimensions {
    if (!('Width' in value) || value['Width'] === undefined) return false;
    if (!('Height' in value) || value['Height'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsDimensionsFromJSON(json: any): FxIdDomainSettingsDimensions {
    return FxIdDomainSettingsDimensionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsDimensionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsDimensions {
    if (json == null) {
        return json;
    }
    return {
        
        'Width': json['Width'],
        'Height': json['Height'],
    };
}

export function FxIdDomainSettingsDimensionsToJSON(json: any): FxIdDomainSettingsDimensions {
    return FxIdDomainSettingsDimensionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsDimensionsToJSONTyped(value?: FxIdDomainSettingsDimensions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Width': value['Width'],
        'Height': value['Height'],
    };
}

