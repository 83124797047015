/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainEntitiesClientErrorCode } from './FxIdDomainEntitiesClientErrorCode';
import {
    FxIdDomainEntitiesClientErrorCodeFromJSON,
    FxIdDomainEntitiesClientErrorCodeFromJSONTyped,
    FxIdDomainEntitiesClientErrorCodeToJSON,
    FxIdDomainEntitiesClientErrorCodeToJSONTyped,
} from './FxIdDomainEntitiesClientErrorCode';

/**
 * 
 * @export
 * @interface FxIdDomainEntitiesClientError
 */
export interface FxIdDomainEntitiesClientError {
    /**
     * 
     * @type {FxIdDomainEntitiesClientErrorCode}
     * @memberof FxIdDomainEntitiesClientError
     */
    ErrorCode: FxIdDomainEntitiesClientErrorCode;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainEntitiesClientError
     */
    GenericMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainEntitiesClientError
     */
    L10NKey?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FxIdDomainEntitiesClientError
     */
    L10NParam?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainEntitiesClientError
     */
    RequestId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainEntitiesClientError
     */
    AccountId?: number | null;
}



/**
 * Check if a given object implements the FxIdDomainEntitiesClientError interface.
 */
export function instanceOfFxIdDomainEntitiesClientError(value: object): value is FxIdDomainEntitiesClientError {
    if (!('ErrorCode' in value) || value['ErrorCode'] === undefined) return false;
    return true;
}

export function FxIdDomainEntitiesClientErrorFromJSON(json: any): FxIdDomainEntitiesClientError {
    return FxIdDomainEntitiesClientErrorFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesClientErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesClientError {
    if (json == null) {
        return json;
    }
    return {
        
        'ErrorCode': FxIdDomainEntitiesClientErrorCodeFromJSON(json['ErrorCode']),
        'GenericMessage': json['GenericMessage'] == null ? undefined : json['GenericMessage'],
        'L10NKey': json['L10NKey'] == null ? undefined : json['L10NKey'],
        'L10NParam': json['L10NParam'] == null ? undefined : json['L10NParam'],
        'RequestId': json['RequestId'] == null ? undefined : json['RequestId'],
        'AccountId': json['AccountId'] == null ? undefined : json['AccountId'],
    };
}

export function FxIdDomainEntitiesClientErrorToJSON(json: any): FxIdDomainEntitiesClientError {
    return FxIdDomainEntitiesClientErrorToJSONTyped(json, false);
}

export function FxIdDomainEntitiesClientErrorToJSONTyped(value?: FxIdDomainEntitiesClientError | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ErrorCode': FxIdDomainEntitiesClientErrorCodeToJSON(value['ErrorCode']),
        'GenericMessage': value['GenericMessage'],
        'L10NKey': value['L10NKey'],
        'L10NParam': value['L10NParam'],
        'RequestId': value['RequestId'],
        'AccountId': value['AccountId'],
    };
}

