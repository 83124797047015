/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthCreateTempTokenResponse
 */
export interface FxIdWebFeaturesAuthCreateTempTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthCreateTempTokenResponse
     */
    TempToken: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthCreateTempTokenResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthCreateTempTokenResponse(value: object): value is FxIdWebFeaturesAuthCreateTempTokenResponse {
    if (!('TempToken' in value) || value['TempToken'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthCreateTempTokenResponseFromJSON(json: any): FxIdWebFeaturesAuthCreateTempTokenResponse {
    return FxIdWebFeaturesAuthCreateTempTokenResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthCreateTempTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthCreateTempTokenResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'TempToken': json['TempToken'],
    };
}

export function FxIdWebFeaturesAuthCreateTempTokenResponseToJSON(json: any): FxIdWebFeaturesAuthCreateTempTokenResponse {
    return FxIdWebFeaturesAuthCreateTempTokenResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthCreateTempTokenResponseToJSONTyped(value?: FxIdWebFeaturesAuthCreateTempTokenResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'TempToken': value['TempToken'],
    };
}

