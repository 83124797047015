/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo
 */
export interface FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo
     */
    Brand: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo
     */
    Last4: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo interface.
 */
export function instanceOfFxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo(value: object): value is FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo {
    if (!('Brand' in value) || value['Brand'] === undefined) return false;
    if (!('Last4' in value) || value['Last4'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfoFromJSON(json: any): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo {
    return FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfoFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'Brand': json['Brand'],
        'Last4': json['Last4'],
    };
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfoToJSON(json: any): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo {
    return FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfoToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfoToJSONTyped(value?: FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Brand': value['Brand'],
        'Last4': value['Last4'],
    };
}

