/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreGetPaymentStatusRequest
 */
export interface FxIdWebFeaturesStoreGetPaymentStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusRequest
     */
    PaymentId: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreGetPaymentStatusRequest interface.
 */
export function instanceOfFxIdWebFeaturesStoreGetPaymentStatusRequest(value: object): value is FxIdWebFeaturesStoreGetPaymentStatusRequest {
    if (!('PaymentId' in value) || value['PaymentId'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreGetPaymentStatusRequestFromJSON(json: any): FxIdWebFeaturesStoreGetPaymentStatusRequest {
    return FxIdWebFeaturesStoreGetPaymentStatusRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreGetPaymentStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreGetPaymentStatusRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'PaymentId': json['PaymentId'],
    };
}

export function FxIdWebFeaturesStoreGetPaymentStatusRequestToJSON(json: any): FxIdWebFeaturesStoreGetPaymentStatusRequest {
    return FxIdWebFeaturesStoreGetPaymentStatusRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreGetPaymentStatusRequestToJSONTyped(value?: FxIdWebFeaturesStoreGetPaymentStatusRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'PaymentId': value['PaymentId'],
    };
}

