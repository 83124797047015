/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationStoreProductData } from './FxIdApplicationStoreProductData';
import {
    FxIdApplicationStoreProductDataFromJSON,
    FxIdApplicationStoreProductDataFromJSONTyped,
    FxIdApplicationStoreProductDataToJSON,
    FxIdApplicationStoreProductDataToJSONTyped,
} from './FxIdApplicationStoreProductData';
import type { FxIdApplicationStorePriceData } from './FxIdApplicationStorePriceData';
import {
    FxIdApplicationStorePriceDataFromJSON,
    FxIdApplicationStorePriceDataFromJSONTyped,
    FxIdApplicationStorePriceDataToJSON,
    FxIdApplicationStorePriceDataToJSONTyped,
} from './FxIdApplicationStorePriceData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreStoreDataResponseProductWithPrice
 */
export interface FxIdWebFeaturesStoreStoreDataResponseProductWithPrice {
    /**
     * 
     * @type {FxIdApplicationStoreProductData}
     * @memberof FxIdWebFeaturesStoreStoreDataResponseProductWithPrice
     */
    Product: FxIdApplicationStoreProductData;
    /**
     * 
     * @type {FxIdApplicationStorePriceData}
     * @memberof FxIdWebFeaturesStoreStoreDataResponseProductWithPrice
     */
    Price: FxIdApplicationStorePriceData;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreStoreDataResponseProductWithPrice interface.
 */
export function instanceOfFxIdWebFeaturesStoreStoreDataResponseProductWithPrice(value: object): value is FxIdWebFeaturesStoreStoreDataResponseProductWithPrice {
    if (!('Product' in value) || value['Product'] === undefined) return false;
    if (!('Price' in value) || value['Price'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreStoreDataResponseProductWithPriceFromJSON(json: any): FxIdWebFeaturesStoreStoreDataResponseProductWithPrice {
    return FxIdWebFeaturesStoreStoreDataResponseProductWithPriceFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreStoreDataResponseProductWithPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreStoreDataResponseProductWithPrice {
    if (json == null) {
        return json;
    }
    return {
        
        'Product': FxIdApplicationStoreProductDataFromJSON(json['Product']),
        'Price': FxIdApplicationStorePriceDataFromJSON(json['Price']),
    };
}

export function FxIdWebFeaturesStoreStoreDataResponseProductWithPriceToJSON(json: any): FxIdWebFeaturesStoreStoreDataResponseProductWithPrice {
    return FxIdWebFeaturesStoreStoreDataResponseProductWithPriceToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreStoreDataResponseProductWithPriceToJSONTyped(value?: FxIdWebFeaturesStoreStoreDataResponseProductWithPrice | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Product': FxIdApplicationStoreProductDataToJSON(value['Product']),
        'Price': FxIdApplicationStorePriceDataToJSON(value['Price']),
    };
}

