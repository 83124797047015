/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom } from './FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom';
import {
    instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteComFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteComFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteComToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom';

/**
 * @type FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom = FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom;

export function FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom {
    return FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom(json)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteComFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComToJSON(json: any): any {
    return FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComToJSONTyped(value?: FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom(value)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteComToJSON(value as FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom);
    }

    return {};
}

