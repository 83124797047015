/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesPlayPublicDataBaseSupportOptions } from './FxIdWebFeaturesPlayPublicDataBaseSupportOptions';
import {
    instanceOfFxIdWebFeaturesPlayPublicDataBaseSupportOptions,
    FxIdWebFeaturesPlayPublicDataBaseSupportOptionsFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseSupportOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseSupportOptionsToJSON,
} from './FxIdWebFeaturesPlayPublicDataBaseSupportOptions';

/**
 * @type FxIdWebFeaturesPlayPublicDataBaseSupport
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPublicDataBaseSupport = FxIdWebFeaturesPlayPublicDataBaseSupportOptions;

export function FxIdWebFeaturesPlayPublicDataBaseSupportFromJSON(json: any): FxIdWebFeaturesPlayPublicDataBaseSupport {
    return FxIdWebFeaturesPlayPublicDataBaseSupportFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseSupportFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPublicDataBaseSupport {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesPlayPublicDataBaseSupportOptions(json)) {
        return FxIdWebFeaturesPlayPublicDataBaseSupportOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesPlayPublicDataBaseSupportToJSON(json: any): any {
    return FxIdWebFeaturesPlayPublicDataBaseSupportToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseSupportToJSONTyped(value?: FxIdWebFeaturesPlayPublicDataBaseSupport | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesPlayPublicDataBaseSupportOptions(value)) {
        return FxIdWebFeaturesPlayPublicDataBaseSupportOptionsToJSON(value as FxIdWebFeaturesPlayPublicDataBaseSupportOptions);
    }

    return {};
}

