/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Ссылки на социалки для лончера
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials
     */
    Discord: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials
     */
    Twitter: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials
     */
    Facebook: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials {
    if (!('Discord' in value) || value['Discord'] === undefined) return false;
    if (!('Twitter' in value) || value['Twitter'] === undefined) return false;
    if (!('Facebook' in value) || value['Facebook'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials {
    if (json == null) {
        return json;
    }
    return {
        
        'Discord': json['Discord'],
        'Twitter': json['Twitter'],
        'Facebook': json['Facebook'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Discord': value['Discord'],
        'Twitter': value['Twitter'],
        'Facebook': value['Facebook'],
    };
}

