/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptions
 */
export interface FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptions
     */
    SteamUserId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptions
     */
    SteamSessionTicket: string;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptions interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptions(value: object): value is FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptions {
    if (!('SteamUserId' in value) || value['SteamUserId'] === undefined) return false;
    if (!('SteamSessionTicket' in value) || value['SteamSessionTicket'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptionsFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'SteamUserId': json['SteamUserId'],
        'SteamSessionTicket': json['SteamSessionTicket'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptionsToJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptionsToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptionsToJSONTyped(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialSteamLoginOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'SteamUserId': value['SteamUserId'],
        'SteamSessionTicket': value['SteamSessionTicket'],
    };
}

