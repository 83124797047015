/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData
 */
export interface FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData
     */
    RedirectUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData
     */
    OrderPublicId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData
     */
    MerchantRedirectType?: string | null;
}

/**
 * Check if a given object implements the FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData interface.
 */
export function instanceOfFxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData(value: object): value is FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData {
    return true;
}

export function FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataFromJSON(json: any): FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData {
    return FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataFromJSONTyped(json, false);
}

export function FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData {
    if (json == null) {
        return json;
    }
    return {
        
        'RedirectUri': json['redirect_uri'] == null ? undefined : json['redirect_uri'],
        'OrderPublicId': json['order_public_id'] == null ? undefined : json['order_public_id'],
        'MerchantRedirectType': json['merchant_redirect_type'] == null ? undefined : json['merchant_redirect_type'],
    };
}

export function FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataToJSON(json: any): FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData {
    return FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataToJSONTyped(json, false);
}

export function FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataToJSONTyped(value?: FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'redirect_uri': value['RedirectUri'],
        'order_public_id': value['OrderPublicId'],
        'merchant_redirect_type': value['MerchantRedirectType'],
    };
}

