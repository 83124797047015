/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsMyGamesPublicAuthOptions } from './FxIdDomainSettingsMyGamesPublicAuthOptions';
import {
    instanceOfFxIdDomainSettingsMyGamesPublicAuthOptions,
    FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSON,
    FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSONTyped,
    FxIdDomainSettingsMyGamesPublicAuthOptionsToJSON,
} from './FxIdDomainSettingsMyGamesPublicAuthOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames
 * Настройки для MyGames oauth
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames = FxIdDomainSettingsMyGamesPublicAuthOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsMyGamesPublicAuthOptions(json)) {
        return FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsMyGamesPublicAuthOptions(value)) {
        return FxIdDomainSettingsMyGamesPublicAuthOptionsToJSON(value as FxIdDomainSettingsMyGamesPublicAuthOptions);
    }

    return {};
}

