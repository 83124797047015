/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesFriendsGetFriendsRequest
 */
export interface FxIdWebFeaturesFriendsGetFriendsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesFriendsGetFriendsRequest
     */
    FriendsIds: Array<string>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesFriendsGetFriendsRequest interface.
 */
export function instanceOfFxIdWebFeaturesFriendsGetFriendsRequest(value: object): value is FxIdWebFeaturesFriendsGetFriendsRequest {
    if (!('FriendsIds' in value) || value['FriendsIds'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesFriendsGetFriendsRequestFromJSON(json: any): FxIdWebFeaturesFriendsGetFriendsRequest {
    return FxIdWebFeaturesFriendsGetFriendsRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesFriendsGetFriendsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesFriendsGetFriendsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'FriendsIds': json['FriendsIds'],
    };
}

export function FxIdWebFeaturesFriendsGetFriendsRequestToJSON(json: any): FxIdWebFeaturesFriendsGetFriendsRequest {
    return FxIdWebFeaturesFriendsGetFriendsRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesFriendsGetFriendsRequestToJSONTyped(value?: FxIdWebFeaturesFriendsGetFriendsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'FriendsIds': value['FriendsIds'],
    };
}

