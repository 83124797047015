/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataLoginCredentialType } from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataLoginCredentialType';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataLoginCredentialTypeFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataLoginCredentialTypeFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataLoginCredentialTypeToJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataLoginCredentialTypeToJSONTyped,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataLoginCredentialType';

/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData
 */
export interface FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData
     */
    Token: string;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataLoginCredentialType}
     * @memberof FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData
     */
    LoginCredentialType: FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataLoginCredentialType;
}



/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData(value: object): value is FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData {
    if (!('Token' in value) || value['Token'] === undefined) return false;
    if (!('LoginCredentialType' in value) || value['LoginCredentialType'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthDataFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthDataFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData {
    if (json == null) {
        return json;
    }
    return {
        
        'Token': json['Token'],
        'LoginCredentialType': FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataLoginCredentialTypeFromJSON(json['LoginCredentialType']),
    };
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthDataToJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthDataToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthDataToJSONTyped(value?: FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataEgsAuthData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Token': value['Token'],
        'LoginCredentialType': FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersEpicGameStoreAuthDataLoginCredentialTypeToJSON(value['LoginCredentialType']),
    };
}

