/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions } from './FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions';
import {
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions,
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsBackground
 * Фон для игры
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsBackground = FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsBackground {
    return FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsBackground {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions(json)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsBackground | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions);
    }

    return {};
}

