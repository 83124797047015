/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData
     */
    PaymentUri: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData(value: object): value is FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData {
    if (!('PaymentUri' in value) || value['PaymentUri'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData {
    if (json == null) {
        return json;
    }
    return {
        
        'PaymentUri': json['PaymentUri'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'PaymentUri': value['PaymentUri'],
    };
}

