/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptions } from './FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptions';
import {
    instanceOfFxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptions,
    FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptionsFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptionsToJSON,
} from './FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptions';

/**
 * @type FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDev
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDev = FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptions;

export function FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevFromJSON(json: any): FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDev {
    return FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDev {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptions(json)) {
        return FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevToJSON(json: any): any {
    return FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevToJSONTyped(value?: FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDev | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptions(value)) {
        return FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptionsToJSON(value as FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevOptions);
    }

    return {};
}

