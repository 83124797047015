/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesGamesListResponse
 */
export interface FxIdWebFeaturesGamesListResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesGamesListResponse
     */
    AvailableGames: Array<string>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesGamesListResponse interface.
 */
export function instanceOfFxIdWebFeaturesGamesListResponse(value: object): value is FxIdWebFeaturesGamesListResponse {
    if (!('AvailableGames' in value) || value['AvailableGames'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesGamesListResponseFromJSON(json: any): FxIdWebFeaturesGamesListResponse {
    return FxIdWebFeaturesGamesListResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesGamesListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'AvailableGames': json['AvailableGames'],
    };
}

export function FxIdWebFeaturesGamesListResponseToJSON(json: any): FxIdWebFeaturesGamesListResponse {
    return FxIdWebFeaturesGamesListResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesListResponseToJSONTyped(value?: FxIdWebFeaturesGamesListResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AvailableGames': value['AvailableGames'],
    };
}

