/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxIdWebFeaturesFriendsGetFriendsRequest,
  FxIdWebFeaturesFriendsGetFriendsResponse,
  FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest,
  FxIdWebFeaturesFriendsSendFriendInviteNotificationResponse,
  FxIdWebFeaturesFriendsSetStatusRequest,
} from '../models/index';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxIdWebFeaturesFriendsGetFriendsRequestFromJSON,
    FxIdWebFeaturesFriendsGetFriendsRequestToJSON,
    FxIdWebFeaturesFriendsGetFriendsResponseFromJSON,
    FxIdWebFeaturesFriendsGetFriendsResponseToJSON,
    FxIdWebFeaturesFriendsSendFriendInviteNotificationRequestFromJSON,
    FxIdWebFeaturesFriendsSendFriendInviteNotificationRequestToJSON,
    FxIdWebFeaturesFriendsSendFriendInviteNotificationResponseFromJSON,
    FxIdWebFeaturesFriendsSendFriendInviteNotificationResponseToJSON,
    FxIdWebFeaturesFriendsSetStatusRequestFromJSON,
    FxIdWebFeaturesFriendsSetStatusRequestToJSON,
} from '../models/index';

export interface FxIdWebFeaturesFriendsGetFriendsEndpointRequest {
    fxIdWebFeaturesFriendsGetFriendsRequest: FxIdWebFeaturesFriendsGetFriendsRequest;
}

export interface FxIdWebFeaturesFriendsSendFriendInviteNotificationEndpointRequest {
    fxIdWebFeaturesFriendsSendFriendInviteNotificationRequest: FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest;
}

export interface FxIdWebFeaturesFriendsSetStatusEndpointRequest {
    fxIdWebFeaturesFriendsSetStatusRequest: FxIdWebFeaturesFriendsSetStatusRequest;
}

/**
 * 
 */
export class FriendsApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesFriendsGetFriendsEndpointRaw(requestParameters: FxIdWebFeaturesFriendsGetFriendsEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesFriendsGetFriendsResponse>> {
        if (requestParameters['fxIdWebFeaturesFriendsGetFriendsRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesFriendsGetFriendsRequest',
                'Required parameter "fxIdWebFeaturesFriendsGetFriendsRequest" was null or undefined when calling fxIdWebFeaturesFriendsGetFriendsEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/friends/get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesFriendsGetFriendsRequestToJSON(requestParameters['fxIdWebFeaturesFriendsGetFriendsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesFriendsGetFriendsResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesFriendsGetFriendsEndpoint(fxIdWebFeaturesFriendsGetFriendsRequest: FxIdWebFeaturesFriendsGetFriendsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesFriendsGetFriendsResponse> {
        const response = await this.fxIdWebFeaturesFriendsGetFriendsEndpointRaw({ fxIdWebFeaturesFriendsGetFriendsRequest: fxIdWebFeaturesFriendsGetFriendsRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesFriendsSendFriendInviteNotificationEndpointRaw(requestParameters: FxIdWebFeaturesFriendsSendFriendInviteNotificationEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesFriendsSendFriendInviteNotificationResponse>> {
        if (requestParameters['fxIdWebFeaturesFriendsSendFriendInviteNotificationRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesFriendsSendFriendInviteNotificationRequest',
                'Required parameter "fxIdWebFeaturesFriendsSendFriendInviteNotificationRequest" was null or undefined when calling fxIdWebFeaturesFriendsSendFriendInviteNotificationEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/friends/send_invite_notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesFriendsSendFriendInviteNotificationRequestToJSON(requestParameters['fxIdWebFeaturesFriendsSendFriendInviteNotificationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesFriendsSendFriendInviteNotificationResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesFriendsSendFriendInviteNotificationEndpoint(fxIdWebFeaturesFriendsSendFriendInviteNotificationRequest: FxIdWebFeaturesFriendsSendFriendInviteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesFriendsSendFriendInviteNotificationResponse> {
        const response = await this.fxIdWebFeaturesFriendsSendFriendInviteNotificationEndpointRaw({ fxIdWebFeaturesFriendsSendFriendInviteNotificationRequest: fxIdWebFeaturesFriendsSendFriendInviteNotificationRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesFriendsSetStatusEndpointRaw(requestParameters: FxIdWebFeaturesFriendsSetStatusEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['fxIdWebFeaturesFriendsSetStatusRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesFriendsSetStatusRequest',
                'Required parameter "fxIdWebFeaturesFriendsSetStatusRequest" was null or undefined when calling fxIdWebFeaturesFriendsSetStatusEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/friends/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesFriendsSetStatusRequestToJSON(requestParameters['fxIdWebFeaturesFriendsSetStatusRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fxIdWebFeaturesFriendsSetStatusEndpoint(fxIdWebFeaturesFriendsSetStatusRequest: FxIdWebFeaturesFriendsSetStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fxIdWebFeaturesFriendsSetStatusEndpointRaw({ fxIdWebFeaturesFriendsSetStatusRequest: fxIdWebFeaturesFriendsSetStatusRequest }, initOverrides);
        return await response.value();
    }

}
