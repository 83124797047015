/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent
 */
export interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent {
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent
     */
    Url: string;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent
     */
    Sha256: string;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent
     */
    MagnetUrl: string;
}

/**
 * Check if a given object implements the FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent interface.
 */
export function instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent(value: object): value is FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent {
    if (!('Url' in value) || value['Url'] === undefined) return false;
    if (!('Sha256' in value) || value['Sha256'] === undefined) return false;
    if (!('MagnetUrl' in value) || value['MagnetUrl'] === undefined) return false;
    return true;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent {
    if (json == null) {
        return json;
    }
    return {
        
        'Url': json['url'],
        'Sha256': json['sha256'],
        'MagnetUrl': json['magnetUrl'],
    };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSONTyped(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'url': value['Url'],
        'sha256': value['Sha256'],
        'magnetUrl': value['MagnetUrl'],
    };
}

