/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthAuthenticateWithExternalIdState } from './FxIdApplicationHandlersAuthAuthenticateWithExternalIdState';
import {
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSON,
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateToJSON,
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateToJSONTyped,
} from './FxIdApplicationHandlersAuthAuthenticateWithExternalIdState';
import type { FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens } from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens';
import {
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensToJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens';
import type { FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptions } from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptions';
import {
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptionsFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptionsFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptionsToJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptionsToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptions';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse {
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateWithExternalIdState}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
     */
    State: FxIdApplicationHandlersAuthAuthenticateWithExternalIdState;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
     */
    Tokens?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
     */
    Hint: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
     */
    Email?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptions}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
     */
    TransferOptions?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptions | null;
}



/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse(value: object): value is FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse {
    if (!('State' in value) || value['State'] === undefined) return false;
    if (!('Hint' in value) || value['Hint'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'State': FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSON(json['State']),
        'Tokens': json['Tokens'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensFromJSON(json['Tokens']),
        'Hint': json['Hint'],
        'Email': json['Email'] == null ? undefined : json['Email'],
        'TransferOptions': json['TransferOptions'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptionsFromJSON(json['TransferOptions']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseToJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'State': FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateToJSON(value['State']),
        'Tokens': FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensToJSON(value['Tokens']),
        'Hint': value['Hint'],
        'Email': value['Email'],
        'TransferOptions': FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptionsToJSON(value['TransferOptions']),
    };
}

