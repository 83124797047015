/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData
 */
export interface FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData
     */
    GameAccessToken: string;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData(value: object): value is FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData {
    if (!('GameAccessToken' in value) || value['GameAccessToken'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthDataFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthDataFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData {
    if (json == null) {
        return json;
    }
    return {
        
        'GameAccessToken': json['GameAccessToken'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthDataToJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthDataToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthDataToJSONTyped(value?: FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGameAccessTokenGameAccessTokenAuthData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameAccessToken': value['GameAccessToken'],
    };
}

