/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom } from './FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComToJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComToJSONTyped,
} from './FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom';
import type { FxIdWebFeaturesPlayPlatformDataOptionsWindows } from './FxIdWebFeaturesPlayPlatformDataOptionsWindows';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsWindowsFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsWindowsFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsWindowsToJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsWindowsToJSONTyped,
} from './FxIdWebFeaturesPlayPlatformDataOptionsWindows';
import type { FxIdWebFeaturesPlayPlatformDataOptionsSteam } from './FxIdWebFeaturesPlayPlatformDataOptionsSteam';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsSteamFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsSteamFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsSteamToJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsSteamToJSONTyped,
} from './FxIdWebFeaturesPlayPlatformDataOptionsSteam';
import type { FxIdWebFeaturesPlayPlatformDataOptionsAndroid } from './FxIdWebFeaturesPlayPlatformDataOptionsAndroid';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsAndroidFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsAndroidFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsAndroidToJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsAndroidToJSONTyped,
} from './FxIdWebFeaturesPlayPlatformDataOptionsAndroid';
import type { FxIdWebFeaturesPlayPlatformDataOptionsWeb } from './FxIdWebFeaturesPlayPlatformDataOptionsWeb';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsWebFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsWebFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsWebToJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsWebToJSONTyped,
} from './FxIdWebFeaturesPlayPlatformDataOptionsWeb';
import type { FxIdWebFeaturesPlayPlatformDataOptionsIos } from './FxIdWebFeaturesPlayPlatformDataOptionsIos';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsIosFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsIosFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsIosToJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsIosToJSONTyped,
} from './FxIdWebFeaturesPlayPlatformDataOptionsIos';
import type { FxIdWebFeaturesPlayPlatformDataOptionsFxId } from './FxIdWebFeaturesPlayPlatformDataOptionsFxId';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsFxIdFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsFxIdFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsFxIdToJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsFxIdToJSONTyped,
} from './FxIdWebFeaturesPlayPlatformDataOptionsFxId';
import type { FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay } from './FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayToJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayToJSONTyped,
} from './FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay';
import type { FxIdWebFeaturesPlayPlatformDataOptionsFacebookGames } from './FxIdWebFeaturesPlayPlatformDataOptionsFacebookGames';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsFacebookGamesFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsFacebookGamesFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsFacebookGamesToJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsFacebookGamesToJSONTyped,
} from './FxIdWebFeaturesPlayPlatformDataOptionsFacebookGames';
import type { FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki } from './FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiToJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiToJSONTyped,
} from './FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPlatformDataOptions
 */
export interface FxIdWebFeaturesPlayPlatformDataOptions {
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsFacebookGames}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    FacebookGames?: FxIdWebFeaturesPlayPlatformDataOptionsFacebookGames | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsFxId}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    FxId?: FxIdWebFeaturesPlayPlatformDataOptionsFxId | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    VkontaktePlay?: FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    VkontakteCom?: FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Odnoklassniki?: FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsAndroid}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Android?: FxIdWebFeaturesPlayPlatformDataOptionsAndroid | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsIos}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Ios?: FxIdWebFeaturesPlayPlatformDataOptionsIos | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsWindows}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Windows?: FxIdWebFeaturesPlayPlatformDataOptionsWindows | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsWeb}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Web?: FxIdWebFeaturesPlayPlatformDataOptionsWeb | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsSteam}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Steam?: FxIdWebFeaturesPlayPlatformDataOptionsSteam | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPlatformDataOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayPlatformDataOptions(value: object): value is FxIdWebFeaturesPlayPlatformDataOptions {
    return true;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'FacebookGames': json['FacebookGames'] == null ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsFacebookGamesFromJSON(json['FacebookGames']),
        'FxId': json['FxId'] == null ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsFxIdFromJSON(json['FxId']),
        'VkontaktePlay': json['VkontaktePlay'] == null ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayFromJSON(json['VkontaktePlay']),
        'VkontakteCom': json['VkontakteCom'] == null ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSON(json['VkontakteCom']),
        'Odnoklassniki': json['Odnoklassniki'] == null ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiFromJSON(json['Odnoklassniki']),
        'Android': json['Android'] == null ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsAndroidFromJSON(json['Android']),
        'Ios': json['Ios'] == null ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsIosFromJSON(json['Ios']),
        'Windows': json['Windows'] == null ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsWindowsFromJSON(json['Windows']),
        'Web': json['Web'] == null ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsWebFromJSON(json['Web']),
        'Steam': json['Steam'] == null ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsSteamFromJSON(json['Steam']),
    };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsToJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsToJSONTyped(value?: FxIdWebFeaturesPlayPlatformDataOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'FacebookGames': FxIdWebFeaturesPlayPlatformDataOptionsFacebookGamesToJSON(value['FacebookGames']),
        'FxId': FxIdWebFeaturesPlayPlatformDataOptionsFxIdToJSON(value['FxId']),
        'VkontaktePlay': FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayToJSON(value['VkontaktePlay']),
        'VkontakteCom': FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComToJSON(value['VkontakteCom']),
        'Odnoklassniki': FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiToJSON(value['Odnoklassniki']),
        'Android': FxIdWebFeaturesPlayPlatformDataOptionsAndroidToJSON(value['Android']),
        'Ios': FxIdWebFeaturesPlayPlatformDataOptionsIosToJSON(value['Ios']),
        'Windows': FxIdWebFeaturesPlayPlatformDataOptionsWindowsToJSON(value['Windows']),
        'Web': FxIdWebFeaturesPlayPlatformDataOptionsWebToJSON(value['Web']),
        'Steam': FxIdWebFeaturesPlayPlatformDataOptionsSteamToJSON(value['Steam']),
    };
}

