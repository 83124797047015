/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesInviteEmailConnectGameResponse
 */
export interface FxIdWebFeaturesInviteEmailConnectGameResponse {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailConnectGameResponse
     */
    EmailInviteToken: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailConnectGameResponse
     */
    EmailInviteMailTo: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailConnectGameResponse
     */
    EmailSubject: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailConnectGameResponse
     */
    EmailTo: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesInviteEmailConnectGameResponse interface.
 */
export function instanceOfFxIdWebFeaturesInviteEmailConnectGameResponse(value: object): value is FxIdWebFeaturesInviteEmailConnectGameResponse {
    if (!('EmailInviteToken' in value) || value['EmailInviteToken'] === undefined) return false;
    if (!('EmailInviteMailTo' in value) || value['EmailInviteMailTo'] === undefined) return false;
    if (!('EmailSubject' in value) || value['EmailSubject'] === undefined) return false;
    if (!('EmailTo' in value) || value['EmailTo'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesInviteEmailConnectGameResponseFromJSON(json: any): FxIdWebFeaturesInviteEmailConnectGameResponse {
    return FxIdWebFeaturesInviteEmailConnectGameResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteEmailConnectGameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInviteEmailConnectGameResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'EmailInviteToken': json['EmailInviteToken'],
        'EmailInviteMailTo': json['EmailInviteMailTo'],
        'EmailSubject': json['EmailSubject'],
        'EmailTo': json['EmailTo'],
    };
}

export function FxIdWebFeaturesInviteEmailConnectGameResponseToJSON(json: any): FxIdWebFeaturesInviteEmailConnectGameResponse {
    return FxIdWebFeaturesInviteEmailConnectGameResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteEmailConnectGameResponseToJSONTyped(value?: FxIdWebFeaturesInviteEmailConnectGameResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'EmailInviteToken': value['EmailInviteToken'],
        'EmailInviteMailTo': value['EmailInviteMailTo'],
        'EmailSubject': value['EmailSubject'],
        'EmailTo': value['EmailTo'],
    };
}

