/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens
 */
export interface FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens
     */
    RefreshToken: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens
     */
    GameAccessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens
     */
    AccessToken?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdTokens(value: object): value is FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens {
    if (!('RefreshToken' in value) || value['RefreshToken'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens {
    if (json == null) {
        return json;
    }
    return {
        
        'RefreshToken': json['RefreshToken'],
        'GameAccessToken': json['GameAccessToken'] == null ? undefined : json['GameAccessToken'],
        'AccessToken': json['AccessToken'] == null ? undefined : json['AccessToken'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensToJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'RefreshToken': value['RefreshToken'],
        'GameAccessToken': value['GameAccessToken'],
        'AccessToken': value['AccessToken'],
    };
}

