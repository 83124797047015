/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOption } from './FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOption';
import {
    FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionToJSON,
    FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionToJSONTyped,
} from './FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOption';
import type { FxIdDomainSettingsUnityOptionsDistributionOptionsComponent } from './FxIdDomainSettingsUnityOptionsDistributionOptionsComponent';
import {
    FxIdDomainSettingsUnityOptionsDistributionOptionsComponentFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionOptionsComponentFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionOptionsComponentToJSON,
    FxIdDomainSettingsUnityOptionsDistributionOptionsComponentToJSONTyped,
} from './FxIdDomainSettingsUnityOptionsDistributionOptionsComponent';
import type { FxIdDomainSettingsUnityOptionsDistributionOptionsWebGlContextOpts } from './FxIdDomainSettingsUnityOptionsDistributionOptionsWebGlContextOpts';
import {
    FxIdDomainSettingsUnityOptionsDistributionOptionsWebGlContextOptsFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionOptionsWebGlContextOptsFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionOptionsWebGlContextOptsToJSON,
    FxIdDomainSettingsUnityOptionsDistributionOptionsWebGlContextOptsToJSONTyped,
} from './FxIdDomainSettingsUnityOptionsDistributionOptionsWebGlContextOpts';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsUnityOptionsDistributionOptions
 */
export interface FxIdDomainSettingsUnityOptionsDistributionOptions {
    /**
     * 
     * @type {FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOption}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    ForceCompressionOption: FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOption;
    /**
     * Файл с лоадером (WebGL_Build.loader.js)
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    LoaderFileName: string;
    /**
     * Data file (WebGL_Build.data) без расширения архива
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    DataFileName: string;
    /**
     * Code file (WebGL_Build.wasm) без расширения архива
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    CodeFileName: string;
    /**
     * Data file используемый на мобилке (WebGL_Mobile.data) без расширения архива. Включается если задан и браузер поддерживает мобильные текстуры
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    MobileDataFileName?: string | null;
    /**
     * Framework file (WebGL_Build.framework.js) без расширения архива
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    FrameworkFileName: string;
    /**
     * Symbols file (WebGL_Build.symbols.json) без расширения архива
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    SymbolsFileName?: string | null;
    /**
     * Memory file
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    MemoryFileName?: string | null;
    /**
     * Streaming assets урл
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    StreamingAssetsUrl?: string | null;
    /**
     * Streaming assets урл до файлов сжатых для мобилки. Применяется если установлен MobileDataFileName
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    StreamingAssetsMobileUrl?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsUnityOptionsDistributionOptionsWebGlContextOpts}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    WebGlContextOpts: FxIdDomainSettingsUnityOptionsDistributionOptionsWebGlContextOpts;
    /**
     * The request pointer lock function lets you asynchronously ask for the pointer to be locked on the Unity Application's canvas.
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    RequestPointerLocking: boolean;
    /**
     * 
     * @type {FxIdDomainSettingsUnityOptionsDistributionOptionsComponent}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionOptions
     */
    Component?: FxIdDomainSettingsUnityOptionsDistributionOptionsComponent | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsUnityOptionsDistributionOptions interface.
 */
export function instanceOfFxIdDomainSettingsUnityOptionsDistributionOptions(value: object): value is FxIdDomainSettingsUnityOptionsDistributionOptions {
    if (!('ForceCompressionOption' in value) || value['ForceCompressionOption'] === undefined) return false;
    if (!('LoaderFileName' in value) || value['LoaderFileName'] === undefined) return false;
    if (!('DataFileName' in value) || value['DataFileName'] === undefined) return false;
    if (!('CodeFileName' in value) || value['CodeFileName'] === undefined) return false;
    if (!('FrameworkFileName' in value) || value['FrameworkFileName'] === undefined) return false;
    if (!('WebGlContextOpts' in value) || value['WebGlContextOpts'] === undefined) return false;
    if (!('RequestPointerLocking' in value) || value['RequestPointerLocking'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsUnityOptionsDistributionOptionsFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionOptions {
    return FxIdDomainSettingsUnityOptionsDistributionOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'ForceCompressionOption': FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionFromJSON(json['ForceCompressionOption']),
        'LoaderFileName': json['LoaderFileName'],
        'DataFileName': json['DataFileName'],
        'CodeFileName': json['CodeFileName'],
        'MobileDataFileName': json['MobileDataFileName'] == null ? undefined : json['MobileDataFileName'],
        'FrameworkFileName': json['FrameworkFileName'],
        'SymbolsFileName': json['SymbolsFileName'] == null ? undefined : json['SymbolsFileName'],
        'MemoryFileName': json['MemoryFileName'] == null ? undefined : json['MemoryFileName'],
        'StreamingAssetsUrl': json['StreamingAssetsUrl'] == null ? undefined : json['StreamingAssetsUrl'],
        'StreamingAssetsMobileUrl': json['StreamingAssetsMobileUrl'] == null ? undefined : json['StreamingAssetsMobileUrl'],
        'WebGlContextOpts': FxIdDomainSettingsUnityOptionsDistributionOptionsWebGlContextOptsFromJSON(json['WebGlContextOpts']),
        'RequestPointerLocking': json['RequestPointerLocking'],
        'Component': json['Component'] == null ? undefined : FxIdDomainSettingsUnityOptionsDistributionOptionsComponentFromJSON(json['Component']),
    };
}

export function FxIdDomainSettingsUnityOptionsDistributionOptionsToJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionOptions {
    return FxIdDomainSettingsUnityOptionsDistributionOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionOptionsToJSONTyped(value?: FxIdDomainSettingsUnityOptionsDistributionOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ForceCompressionOption': FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionToJSON(value['ForceCompressionOption']),
        'LoaderFileName': value['LoaderFileName'],
        'DataFileName': value['DataFileName'],
        'CodeFileName': value['CodeFileName'],
        'MobileDataFileName': value['MobileDataFileName'],
        'FrameworkFileName': value['FrameworkFileName'],
        'SymbolsFileName': value['SymbolsFileName'],
        'MemoryFileName': value['MemoryFileName'],
        'StreamingAssetsUrl': value['StreamingAssetsUrl'],
        'StreamingAssetsMobileUrl': value['StreamingAssetsMobileUrl'],
        'WebGlContextOpts': FxIdDomainSettingsUnityOptionsDistributionOptionsWebGlContextOptsToJSON(value['WebGlContextOpts']),
        'RequestPointerLocking': value['RequestPointerLocking'],
        'Component': FxIdDomainSettingsUnityOptionsDistributionOptionsComponentToJSON(value['Component']),
    };
}

