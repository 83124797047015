/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions } from './FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions';
import {
    instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions,
    FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions';

/**
 * @type FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimes
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimes = FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions;

export function FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimesFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimes {
    return FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimesFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimes {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions(json)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimesToJSON(json: any): any {
    return FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimesToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimesToJSONTyped(value?: FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimes | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions(value)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsToJSON(value as FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions);
    }

    return {};
}

