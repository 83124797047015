/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassniki } from './FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassniki';
import {
    instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassniki,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassnikiFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassnikiFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassnikiToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassniki';

/**
 * @type FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki = FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassniki;

export function FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki {
    return FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassniki(json)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassnikiFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiToJSON(json: any): any {
    return FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiToJSONTyped(value?: FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassniki(value)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassnikiToJSON(value as FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsOdnoklassniki);
    }

    return {};
}

