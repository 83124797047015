/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData
 */
export interface FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData
     */
    Token: string;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData(value: object): value is FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData {
    if (!('Token' in value) || value['Token'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData {
    if (json == null) {
        return json;
    }
    return {
        
        'Token': json['Token'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataToJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataToJSONTyped(value?: FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Token': value['Token'],
    };
}

