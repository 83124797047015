/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData } from './FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData';
import {
    FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataToJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData';
import type { FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData } from './FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData';
import {
    FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataToJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromGameResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateFromGameResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameResponse
     */
    IsConnected: boolean;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameResponse
     */
    ConnectedData?: FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameResponse
     */
    NotConnectedData?: FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromGameResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromGameResponse(value: object): value is FxIdWebFeaturesAuthAuthenticateFromGameResponse {
    if (!('IsConnected' in value) || value['IsConnected'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromGameResponse {
    return FxIdWebFeaturesAuthAuthenticateFromGameResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromGameResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'IsConnected': json['IsConnected'],
        'ConnectedData': json['ConnectedData'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSON(json['ConnectedData']),
        'NotConnectedData': json['NotConnectedData'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataFromJSON(json['NotConnectedData']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromGameResponse {
    return FxIdWebFeaturesAuthAuthenticateFromGameResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromGameResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'IsConnected': value['IsConnected'],
        'ConnectedData': FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataToJSON(value['ConnectedData']),
        'NotConnectedData': FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataToJSON(value['NotConnectedData']),
    };
}

