/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesProfileGetTwitchData } from './FxIdWebFeaturesProfileGetTwitchData';
import {
    instanceOfFxIdWebFeaturesProfileGetTwitchData,
    FxIdWebFeaturesProfileGetTwitchDataFromJSON,
    FxIdWebFeaturesProfileGetTwitchDataFromJSONTyped,
    FxIdWebFeaturesProfileGetTwitchDataToJSON,
} from './FxIdWebFeaturesProfileGetTwitchData';

/**
 * @type FxIdWebFeaturesProfileGetResponseTwitch
 * 
 * @export
 */
export type FxIdWebFeaturesProfileGetResponseTwitch = FxIdWebFeaturesProfileGetTwitchData;

export function FxIdWebFeaturesProfileGetResponseTwitchFromJSON(json: any): FxIdWebFeaturesProfileGetResponseTwitch {
    return FxIdWebFeaturesProfileGetResponseTwitchFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetResponseTwitchFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileGetResponseTwitch {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesProfileGetTwitchData(json)) {
        return FxIdWebFeaturesProfileGetTwitchDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesProfileGetResponseTwitchToJSON(json: any): any {
    return FxIdWebFeaturesProfileGetResponseTwitchToJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetResponseTwitchToJSONTyped(value?: FxIdWebFeaturesProfileGetResponseTwitch | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesProfileGetTwitchData(value)) {
        return FxIdWebFeaturesProfileGetTwitchDataToJSON(value as FxIdWebFeaturesProfileGetTwitchData);
    }

    return {};
}

