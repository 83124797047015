/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions
 */
export interface FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions
     */
    AppLinkUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions
     */
    AppId: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions(value: object): value is FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions {
    if (!('AppLinkUrl' in value) || value['AppLinkUrl'] === undefined) return false;
    if (!('AppId' in value) || value['AppId'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptionsFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'AppLinkUrl': json['AppLinkUrl'],
        'AppId': json['AppId'],
    };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptionsToJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptionsToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptionsToJSONTyped(value?: FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AppLinkUrl': value['AppLinkUrl'],
        'AppId': value['AppId'],
    };
}

