/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions';
import {
    instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptionsFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptionsFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptionsToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions';

/**
 * @type FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptions
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptions = FxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions;

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptionsFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptions {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptions {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions(json)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptionsToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptionsToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptionsToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions(value)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptionsToJSON(value as FxIdWebFeaturesAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions);
    }

    return {};
}

