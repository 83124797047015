/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptions } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptions';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptions';

/**
 * @type FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGames
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGames = FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptions;

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGames {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGames {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptions(json)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGames | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesLoginOptions);
    }

    return {};
}

