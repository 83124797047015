/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsPortalPublicWebConfigOptions } from './FxIdDomainSettingsPortalPublicWebConfigOptions';
import {
    FxIdDomainSettingsPortalPublicWebConfigOptionsFromJSON,
    FxIdDomainSettingsPortalPublicWebConfigOptionsFromJSONTyped,
    FxIdDomainSettingsPortalPublicWebConfigOptionsToJSON,
    FxIdDomainSettingsPortalPublicWebConfigOptionsToJSONTyped,
} from './FxIdDomainSettingsPortalPublicWebConfigOptions';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesGamesChooseGamePortalConfig
 */
export interface FxIdWebFeaturesGamesChooseGamePortalConfig {
    /**
     * 
     * @type {FxIdDomainSettingsPortalPublicWebConfigOptions}
     * @memberof FxIdWebFeaturesGamesChooseGamePortalConfig
     */
    PortalPublicWebConfig: FxIdDomainSettingsPortalPublicWebConfigOptions;
}

/**
 * Check if a given object implements the FxIdWebFeaturesGamesChooseGamePortalConfig interface.
 */
export function instanceOfFxIdWebFeaturesGamesChooseGamePortalConfig(value: object): value is FxIdWebFeaturesGamesChooseGamePortalConfig {
    if (!('PortalPublicWebConfig' in value) || value['PortalPublicWebConfig'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesGamesChooseGamePortalConfigFromJSON(json: any): FxIdWebFeaturesGamesChooseGamePortalConfig {
    return FxIdWebFeaturesGamesChooseGamePortalConfigFromJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesChooseGamePortalConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesGamesChooseGamePortalConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'PortalPublicWebConfig': FxIdDomainSettingsPortalPublicWebConfigOptionsFromJSON(json['PortalPublicWebConfig']),
    };
}

export function FxIdWebFeaturesGamesChooseGamePortalConfigToJSON(json: any): FxIdWebFeaturesGamesChooseGamePortalConfig {
    return FxIdWebFeaturesGamesChooseGamePortalConfigToJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesChooseGamePortalConfigToJSONTyped(value?: FxIdWebFeaturesGamesChooseGamePortalConfig | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'PortalPublicWebConfig': FxIdDomainSettingsPortalPublicWebConfigOptionsToJSON(value['PortalPublicWebConfig']),
    };
}

