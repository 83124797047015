/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesInfoNewsNewsL10nMessage } from './FxIdWebFeaturesInfoNewsNewsL10nMessage';
import {
    FxIdWebFeaturesInfoNewsNewsL10nMessageFromJSON,
    FxIdWebFeaturesInfoNewsNewsL10nMessageFromJSONTyped,
    FxIdWebFeaturesInfoNewsNewsL10nMessageToJSON,
    FxIdWebFeaturesInfoNewsNewsL10nMessageToJSONTyped,
} from './FxIdWebFeaturesInfoNewsNewsL10nMessage';
import type { FxIdDomainEntitiesNewsPriority } from './FxIdDomainEntitiesNewsPriority';
import {
    FxIdDomainEntitiesNewsPriorityFromJSON,
    FxIdDomainEntitiesNewsPriorityFromJSONTyped,
    FxIdDomainEntitiesNewsPriorityToJSON,
    FxIdDomainEntitiesNewsPriorityToJSONTyped,
} from './FxIdDomainEntitiesNewsPriority';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesInfoNewsNews
 */
export interface FxIdWebFeaturesInfoNewsNews {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    Id: string;
    /**
     * 
     * @type {Date}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    CreatedAt: Date;
    /**
     * 
     * @type {Array<FxIdWebFeaturesInfoNewsNewsL10nMessage>}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    Messages: Array<FxIdWebFeaturesInfoNewsNewsL10nMessage>;
    /**
     * 
     * @type {FxIdDomainEntitiesNewsPriority}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    Priority: FxIdDomainEntitiesNewsPriority;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    Link?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    IconUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    ImageUrl?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    Tags?: Array<string> | null;
}



/**
 * Check if a given object implements the FxIdWebFeaturesInfoNewsNews interface.
 */
export function instanceOfFxIdWebFeaturesInfoNewsNews(value: object): value is FxIdWebFeaturesInfoNewsNews {
    if (!('Id' in value) || value['Id'] === undefined) return false;
    if (!('CreatedAt' in value) || value['CreatedAt'] === undefined) return false;
    if (!('Messages' in value) || value['Messages'] === undefined) return false;
    if (!('Priority' in value) || value['Priority'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesInfoNewsNewsFromJSON(json: any): FxIdWebFeaturesInfoNewsNews {
    return FxIdWebFeaturesInfoNewsNewsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInfoNewsNewsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInfoNewsNews {
    if (json == null) {
        return json;
    }
    return {
        
        'Id': json['Id'],
        'CreatedAt': (new Date(json['CreatedAt'])),
        'Messages': ((json['Messages'] as Array<any>).map(FxIdWebFeaturesInfoNewsNewsL10nMessageFromJSON)),
        'Priority': FxIdDomainEntitiesNewsPriorityFromJSON(json['Priority']),
        'Link': json['Link'] == null ? undefined : json['Link'],
        'IconUrl': json['IconUrl'] == null ? undefined : json['IconUrl'],
        'ImageUrl': json['ImageUrl'] == null ? undefined : json['ImageUrl'],
        'Tags': json['Tags'] == null ? undefined : json['Tags'],
    };
}

export function FxIdWebFeaturesInfoNewsNewsToJSON(json: any): FxIdWebFeaturesInfoNewsNews {
    return FxIdWebFeaturesInfoNewsNewsToJSONTyped(json, false);
}

export function FxIdWebFeaturesInfoNewsNewsToJSONTyped(value?: FxIdWebFeaturesInfoNewsNews | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Id': value['Id'],
        'CreatedAt': ((value['CreatedAt']).toISOString()),
        'Messages': ((value['Messages'] as Array<any>).map(FxIdWebFeaturesInfoNewsNewsL10nMessageToJSON)),
        'Priority': FxIdDomainEntitiesNewsPriorityToJSON(value['Priority']),
        'Link': value['Link'],
        'IconUrl': value['IconUrl'],
        'ImageUrl': value['ImageUrl'],
        'Tags': value['Tags'],
    };
}

