/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError } from './FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError';
import {
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorFromJSON,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorFromJSONTyped,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorToJSON,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorToJSONTyped,
} from './FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError';
import type { FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseData } from './FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseData';
import {
    FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseDataFromJSON,
    FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseDataFromJSONTyped,
    FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseDataToJSON,
    FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseDataToJSONTyped,
} from './FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseData';

/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse
 */
export interface FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse
     */
    Success: boolean;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError}
     * @memberof FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse
     */
    Error?: FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseData}
     * @memberof FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse
     */
    Data?: FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseData | null;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse interface.
 */
export function instanceOfFxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse(value: object): value is FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse {
    if (!('Success' in value) || value['Success'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseFromJSON(json: any): FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse {
    return FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Success': json['Success'],
        'Error': json['Error'] == null ? undefined : FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorFromJSON(json['Error']),
        'Data': json['Data'] == null ? undefined : FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseDataFromJSON(json['Data']),
    };
}

export function FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseToJSON(json: any): FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse {
    return FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseToJSONTyped(value?: FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Success': value['Success'],
        'Error': FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorToJSON(value['Error']),
        'Data': FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseDataToJSON(value['Data']),
    };
}

