/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsFacebookPublicAuthOptions } from './FxIdDomainSettingsFacebookPublicAuthOptions';
import {
    instanceOfFxIdDomainSettingsFacebookPublicAuthOptions,
    FxIdDomainSettingsFacebookPublicAuthOptionsFromJSON,
    FxIdDomainSettingsFacebookPublicAuthOptionsFromJSONTyped,
    FxIdDomainSettingsFacebookPublicAuthOptionsToJSON,
} from './FxIdDomainSettingsFacebookPublicAuthOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook
 * Настройки для Facebook
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook = FxIdDomainSettingsFacebookPublicAuthOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsFacebookPublicAuthOptions(json)) {
        return FxIdDomainSettingsFacebookPublicAuthOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsFacebookPublicAuthOptions(value)) {
        return FxIdDomainSettingsFacebookPublicAuthOptionsToJSON(value as FxIdDomainSettingsFacebookPublicAuthOptions);
    }

    return {};
}

