/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainModelsDBDBInviteInviteStatusErrorCode } from './FxIdDomainModelsDBDBInviteInviteStatusErrorCode';
import {
    FxIdDomainModelsDBDBInviteInviteStatusErrorCodeFromJSON,
    FxIdDomainModelsDBDBInviteInviteStatusErrorCodeFromJSONTyped,
    FxIdDomainModelsDBDBInviteInviteStatusErrorCodeToJSON,
    FxIdDomainModelsDBDBInviteInviteStatusErrorCodeToJSONTyped,
} from './FxIdDomainModelsDBDBInviteInviteStatusErrorCode';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesInviteEmailInviteStatusResponse
 */
export interface FxIdWebFeaturesInviteEmailInviteStatusResponse {
    /**
     * 
     * @type {FxIdDomainModelsDBDBInviteInviteStatusErrorCode}
     * @memberof FxIdWebFeaturesInviteEmailInviteStatusResponse
     */
    StatusCode: FxIdDomainModelsDBDBInviteInviteStatusErrorCode;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailInviteStatusResponse
     */
    LoginToken?: string | null;
}



/**
 * Check if a given object implements the FxIdWebFeaturesInviteEmailInviteStatusResponse interface.
 */
export function instanceOfFxIdWebFeaturesInviteEmailInviteStatusResponse(value: object): value is FxIdWebFeaturesInviteEmailInviteStatusResponse {
    if (!('StatusCode' in value) || value['StatusCode'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesInviteEmailInviteStatusResponseFromJSON(json: any): FxIdWebFeaturesInviteEmailInviteStatusResponse {
    return FxIdWebFeaturesInviteEmailInviteStatusResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteEmailInviteStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInviteEmailInviteStatusResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'StatusCode': FxIdDomainModelsDBDBInviteInviteStatusErrorCodeFromJSON(json['StatusCode']),
        'LoginToken': json['LoginToken'] == null ? undefined : json['LoginToken'],
    };
}

export function FxIdWebFeaturesInviteEmailInviteStatusResponseToJSON(json: any): FxIdWebFeaturesInviteEmailInviteStatusResponse {
    return FxIdWebFeaturesInviteEmailInviteStatusResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteEmailInviteStatusResponseToJSONTyped(value?: FxIdWebFeaturesInviteEmailInviteStatusResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'StatusCode': FxIdDomainModelsDBDBInviteInviteStatusErrorCodeToJSON(value['StatusCode']),
        'LoginToken': value['LoginToken'],
    };
}

