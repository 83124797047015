/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform } from './FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform';
import {
    FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformFromJSON,
    FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformFromJSONTyped,
    FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformToJSON,
    FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformToJSONTyped,
} from './FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform';

/**
 * 
 * @export
 * @interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
 */
export interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease {
    /**
     * 
     * @type {Date}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    CreatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    UpdatedAt: Date;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    Platform: FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform;
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    BuildNumber: number;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    BuildVersion: string;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    BuildSemanticVersion: string;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    CommitHash: string;
}



/**
 * Check if a given object implements the FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease interface.
 */
export function instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease(value: object): value is FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease {
    if (!('CreatedAt' in value) || value['CreatedAt'] === undefined) return false;
    if (!('UpdatedAt' in value) || value['UpdatedAt'] === undefined) return false;
    if (!('Platform' in value) || value['Platform'] === undefined) return false;
    if (!('BuildNumber' in value) || value['BuildNumber'] === undefined) return false;
    if (!('BuildVersion' in value) || value['BuildVersion'] === undefined) return false;
    if (!('BuildSemanticVersion' in value) || value['BuildSemanticVersion'] === undefined) return false;
    if (!('CommitHash' in value) || value['CommitHash'] === undefined) return false;
    return true;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease {
    if (json == null) {
        return json;
    }
    return {
        
        'CreatedAt': (new Date(json['createdAt'])),
        'UpdatedAt': (new Date(json['updatedAt'])),
        'Platform': FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformFromJSON(json['platform']),
        'BuildNumber': json['buildNumber'],
        'BuildVersion': json['buildVersion'],
        'BuildSemanticVersion': json['buildSemanticVersion'],
        'CommitHash': json['commitHash'],
    };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseToJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseToJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseToJSONTyped(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'createdAt': ((value['CreatedAt']).toISOString()),
        'updatedAt': ((value['UpdatedAt']).toISOString()),
        'platform': FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformToJSON(value['Platform']),
        'buildNumber': value['BuildNumber'],
        'buildVersion': value['BuildVersion'],
        'buildSemanticVersion': value['BuildSemanticVersion'],
        'commitHash': value['CommitHash'],
    };
}

