/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption } from './FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption';
import {
    instanceOfFxIdDomainSettingsUnityOptionsDistributionForceCompressionOption,
    FxIdDomainSettingsUnityOptionsDistributionForceCompressionOptionFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionForceCompressionOptionFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionForceCompressionOptionToJSON,
} from './FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption';

/**
 * @type FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOption
 * Если включено то расширения для файлов будет устанавливаться в указанное. По умолчанию будет brotli
 * Unspecified
 * Disabled
 * Brotli
 * Gzip
 * @export
 */
export type FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOption = FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption;

export function FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOption {
    return FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOption {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsUnityOptionsDistributionForceCompressionOption(json)) {
        return FxIdDomainSettingsUnityOptionsDistributionForceCompressionOptionFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionToJSON(json: any): any {
    return FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionToJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOptionToJSONTyped(value?: FxIdDomainSettingsUnityOptionsDistributionOptionsForceCompressionOption | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsUnityOptionsDistributionForceCompressionOption(value)) {
        return FxIdDomainSettingsUnityOptionsDistributionForceCompressionOptionToJSON(value as FxIdDomainSettingsUnityOptionsDistributionForceCompressionOption);
    }

    return {};
}

