import {
	EFxIdSdkAdapterBuyProductResponseErrorCode,
	FxIdSdkAdapterSocialSettingDefault,
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest
} from "./FxIdSdkBaseAdapter";
import {
	FxIdApplicationStoreCreatePaymentHandlerEmbeddingType,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesPlayPublicDataBase,
	FxIdWebFeaturesStoreCreatePaymentRequest
} from "../Api/gen";
import i18next from "i18next";
import { ok, telegram, vk } from "vanilla-sharing";
import { appStore, sharingHandlersStore, userStore } from "../Stores";
import { getQueriedProfileData } from "../Api/Queries";
import OpenApiClient from "../Api/OpenApiClient";
import { deferred } from "./FxIdSdkUtils";

export class FxIdSdkAdapterForTelegram extends FxIdSdkBaseAdapter {
	private buyProductResolve?: (
		value: PromiseLike<IFxIdSdkAdapterBuyProductResponse> | IFxIdSdkAdapterBuyProductResponse
	) => void;
	private buyProductReject?: (
		value: PromiseLike<IFxIdSdkAdapterBuyProductResponse> | IFxIdSdkAdapterBuyProductResponse
	) => void;

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxIdWebFeaturesPlayPublicDataBase
	) {
		super(exportedSdk);
	}

	async GetInitData(): Promise<string> {
		return Promise.resolve(window.Telegram.WebApp.initData);
	}

	async Initialize(): Promise<void> {
		await new Promise<void>((resolve) => {
			const s = document.createElement("script");
			s.type = "text/javascript";
			s.src = "https://telegram.org/js/telegram-web-app.js";
			s.async = false;
			s.defer = false;
			s.addEventListener("load", () => {
				log.info("Telegram web api loaded");
				resolve();
			});
			const head = document.getElementsByTagName("head")[0];
			head.appendChild(s);

			log.info("Telegram web api added");
		});

		log.info(
			"Telegram adapter initialized. Platform: %o, Version: %o. Init data: %o",
			window.Telegram.WebApp.platform,
			window.Telegram.WebApp.version,
			window.Telegram.WebApp.initData
		);
	}

	GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		const tmUser = window.Telegram.WebApp.initDataUnsafe.user;

		if (tmUser == null) {
			throw new Error("Telegram user is missing!");
		}

		const socialInfo: IFxIdSdkAdapterInfoSocial = {
			social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Telegram,
			paymentsAvailable: true,
			userId: tmUser.id.toString(),
			capabilities: [],
			displayName: tmUser.username,
			firstName: tmUser.first_name,
			lastName: tmUser.last_name
		};

		if (tmUser.photo_url != null) {
			socialInfo.photo = {
				url: tmUser.photo_url
			};
		}
		return Promise.resolve(socialInfo);
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		const paymentRequest: FxIdWebFeaturesStoreCreatePaymentRequest = {
			Game: this.game,
			Sku: request.sku,
			WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Telegram,
			EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType.Embed,
			ProductDescriptionHint: request.productDescriptionHint,
			ProductNameHint: request.productNameHint
		};
		if (request.developerPayload != null) {
			paymentRequest.DeveloperPayload = {
				MerchantDeveloperPayload: request.developerPayload
			};
		}
		const createPaymentResult = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint(paymentRequest);

		log.info("Received result from server: %o", createPaymentResult);
		const tm = createPaymentResult.Telegram;
		if (tm == null) {
			throw new Error("Failed to create payment in telegram adapter - telegram data is empty");
		}

		log.info("Calling telegram api");

		const { promise, resolve, reject } = deferred<IFxIdSdkAdapterBuyProductResponse>();
		this.buyProductResolve = (data: any) => {
			resolve({
				...data,
				transactionId: createPaymentResult.TransactionId,
				stats: {
					currency: createPaymentResult.OrderProduct.Currency ?? "",
					currencyAmount: createPaymentResult.OrderProduct.Price ?? 0,
					usdAmount: createPaymentResult.OrderProduct.UsdPrice ?? 0
				}
			});
		};
		this.buyProductReject = reject;

		// Похоже в типах для либы не верная сигнатура - по доке в первом и единственном параметре придет статус, а тут урл и потом статус
		window.Telegram.WebApp.openInvoice(tm.InvoiceUrl, (s) => {
			log.info("Received open invoice result: %o", s);
			const status = s as "paid" | "cancelled" | "failed" | "pending";

			if (s === "paid" || s === "pending") {
				this.buyProductResolve?.call(this, { transactionId: createPaymentResult.TransactionId });
			} else {
				this.buyProductReject?.call(this, {
					error: s,
					errorCode:
						status === "cancelled"
							? EFxIdSdkAdapterBuyProductResponseErrorCode.UserCancelled
							: EFxIdSdkAdapterBuyProductResponseErrorCode.Unknown
				});
			}
		});

		return promise;
	}

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve(FxIdSdkAdapterSocialSettingDefault);
	}

	async RegisterShareHandlers(): Promise<void> {
		const { enableLinkCopy, enableShareOk, enableShareTelegram, enableShareVk } = sharingHandlersStore.getState();
		const { enableWebPlayLogin } = appStore.getState();
		const data = { url: window.location, title: i18next.t(`seo.${this.game}.title`) };
		enableShareVk(() => vk(data));
		enableShareOk(() => ok(data));
		enableShareTelegram(() => telegram(data));
		enableLinkCopy();
		const profileData = await getQueriedProfileData();

		const userDisplayName = profileData.Name ?? profileData.Email ?? `#${profileData.AccountId}`;

		userStore.getState().updateUserId(String(profileData.AccountId));
		userStore.getState().updateDisplayName(userDisplayName);
	}

	StoreCurrency(): Promise<string | undefined> {
		return Promise.resolve("XTR");
	}

	StatInitialize(request: IFxIdSdkAdapterStatInitializeRequest): Promise<void> {
		return Promise.resolve();
	}

	StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		return Promise.resolve();
	}
}
