/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsAdvertisementFbInstantRewardedOptions } from './FxIdDomainSettingsAdvertisementFbInstantRewardedOptions';
import {
    instanceOfFxIdDomainSettingsAdvertisementFbInstantRewardedOptions,
    FxIdDomainSettingsAdvertisementFbInstantRewardedOptionsFromJSON,
    FxIdDomainSettingsAdvertisementFbInstantRewardedOptionsFromJSONTyped,
    FxIdDomainSettingsAdvertisementFbInstantRewardedOptionsToJSON,
} from './FxIdDomainSettingsAdvertisementFbInstantRewardedOptions';

/**
 * @type FxIdDomainSettingsAdvertisementFbInstantOptionsRewarded
 * 
 * @export
 */
export type FxIdDomainSettingsAdvertisementFbInstantOptionsRewarded = FxIdDomainSettingsAdvertisementFbInstantRewardedOptions;

export function FxIdDomainSettingsAdvertisementFbInstantOptionsRewardedFromJSON(json: any): FxIdDomainSettingsAdvertisementFbInstantOptionsRewarded {
    return FxIdDomainSettingsAdvertisementFbInstantOptionsRewardedFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementFbInstantOptionsRewardedFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementFbInstantOptionsRewarded {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsAdvertisementFbInstantRewardedOptions(json)) {
        return FxIdDomainSettingsAdvertisementFbInstantRewardedOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsAdvertisementFbInstantOptionsRewardedToJSON(json: any): any {
    return FxIdDomainSettingsAdvertisementFbInstantOptionsRewardedToJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementFbInstantOptionsRewardedToJSONTyped(value?: FxIdDomainSettingsAdvertisementFbInstantOptionsRewarded | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsAdvertisementFbInstantRewardedOptions(value)) {
        return FxIdDomainSettingsAdvertisementFbInstantRewardedOptionsToJSON(value as FxIdDomainSettingsAdvertisementFbInstantRewardedOptions);
    }

    return {};
}

