/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest
 */
export interface FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest
     */
    GameSystemName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest
     */
    Game?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest
     */
    Token: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest
     */
    MyGamesToken: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest(value: object): value is FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest {
    if (!('Token' in value) || value['Token'] === undefined) return false;
    if (!('MyGamesToken' in value) || value['MyGamesToken'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequestFromJSON(json: any): FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest {
    return FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'GameSystemName': json['GameSystemName'] == null ? undefined : json['GameSystemName'],
        'Game': json['Game'] == null ? undefined : json['Game'],
        'Token': json['Token'],
        'MyGamesToken': json['MyGamesToken'],
    };
}

export function FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequestToJSON(json: any): FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest {
    return FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequestToJSONTyped(value?: FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameSystemName': value['GameSystemName'],
        'Game': value['Game'],
        'Token': value['Token'],
        'MyGamesToken': value['MyGamesToken'],
    };
}

