/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreference } from './FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreference';
import {
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceToJSON,
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceToJSONTyped,
} from './FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreference';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts
 */
export interface FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts {
    /**
     * If set to true, the context will have an alpha (transparency) channel.
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts
     */
    Alpha?: boolean | null;
    /**
     * If set to true, the context will attempt to perform antialiased rendering if possible.
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts
     */
    Antialias?: boolean | null;
    /**
     * If set to true, the context will have a 16 bit depth buffer. Defaults to true. Use gl.enable(DEPTH_TEST) to enable the depth test and gl.depthFunc(), gl.depthMask(), and gl.depthRange() to configure the depth test.
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts
     */
    Depth?: boolean | null;
    /**
     * If the value is true, context creation will fail if the implementation determines that the performance of the created WebGL context would be dramatically lower than that of a native application making equivalent OpenGL calls. This could happen for a number of reasons, including an implementation might switch to a software rasterizer if the user's GPU driver is known to be unstable. And an implementation might require reading back the framebuffer from GPU memory to system memory before compositing it with the rest of the page, significantly reducing performance.
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts
     */
    FailIfMajorPerformanceCaveat?: boolean | null;
    /**
     * 
     * @type {FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreference}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts
     */
    PowerPreference: FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreference;
    /**
     * If set to true, the color channels in the framebuffer will be stored premultipled by the alpha channel to improve performance.
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts
     */
    PremultipliedAlpha?: boolean | null;
    /**
     * If set to false, the buffer will be cleared after rendering. If you wish to use canvas.toDataURL(), you will either need to draw to the canvas immediately before calling toDataURL(), or set preserveDrawingBuffer to true to keep the buffer available after the browser has displayed the buffer (at the cost of increased memory use).
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts
     */
    PreserveDrawingBuffer?: boolean | null;
    /**
     * Stenciling enables and disables drawing on a per-pixel basis. It is typically used in multipass rendering to achieve special effects.
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts
     */
    Stencil?: boolean | null;
    /**
     * If set to true, the context will have an 8 bit stencil buffer. Defaults to false. Use gl.enable(STENCIL_TEST) to enable depth test and gl.stencilFunc(), gl.stencilFuncSeparate(), gl.stencilMask(), gl.stencilMaskSeparate(), gl.stencilOp(), and gl.stencilOpSeparate() to configure the stencil test.
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts
     */
    Desynchronized?: boolean | null;
    /**
     * xrCompatible is a boolean that indicates whether the context is compatible.
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts
     */
    XrCompatible?: boolean | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts interface.
 */
export function instanceOfFxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts(value: object): value is FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts {
    if (!('PowerPreference' in value) || value['PowerPreference'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts {
    return FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts {
    if (json == null) {
        return json;
    }
    return {
        
        'Alpha': json['Alpha'] == null ? undefined : json['Alpha'],
        'Antialias': json['Antialias'] == null ? undefined : json['Antialias'],
        'Depth': json['Depth'] == null ? undefined : json['Depth'],
        'FailIfMajorPerformanceCaveat': json['FailIfMajorPerformanceCaveat'] == null ? undefined : json['FailIfMajorPerformanceCaveat'],
        'PowerPreference': FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceFromJSON(json['PowerPreference']),
        'PremultipliedAlpha': json['PremultipliedAlpha'] == null ? undefined : json['PremultipliedAlpha'],
        'PreserveDrawingBuffer': json['PreserveDrawingBuffer'] == null ? undefined : json['PreserveDrawingBuffer'],
        'Stencil': json['Stencil'] == null ? undefined : json['Stencil'],
        'Desynchronized': json['Desynchronized'] == null ? undefined : json['Desynchronized'],
        'XrCompatible': json['XrCompatible'] == null ? undefined : json['XrCompatible'],
    };
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsToJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts {
    return FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsToJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsToJSONTyped(value?: FxIdDomainSettingsUnityOptionsDistributionWebGlContextOpts | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Alpha': value['Alpha'],
        'Antialias': value['Antialias'],
        'Depth': value['Depth'],
        'FailIfMajorPerformanceCaveat': value['FailIfMajorPerformanceCaveat'],
        'PowerPreference': FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceToJSON(value['PowerPreference']),
        'PremultipliedAlpha': value['PremultipliedAlpha'],
        'PreserveDrawingBuffer': value['PreserveDrawingBuffer'],
        'Stencil': value['Stencil'],
        'Desynchronized': value['Desynchronized'],
        'XrCompatible': value['XrCompatible'],
    };
}

