/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType } from './FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType';
import {
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSONTyped,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSONTyped,
} from './FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesProfileGetResponseSocialIdentifier
 */
export interface FxIdWebFeaturesProfileGetResponseSocialIdentifier {
    /**
     * 
     * @type {FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType}
     * @memberof FxIdWebFeaturesProfileGetResponseSocialIdentifier
     */
    Type: FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponseSocialIdentifier
     */
    Id: string;
}



/**
 * Check if a given object implements the FxIdWebFeaturesProfileGetResponseSocialIdentifier interface.
 */
export function instanceOfFxIdWebFeaturesProfileGetResponseSocialIdentifier(value: object): value is FxIdWebFeaturesProfileGetResponseSocialIdentifier {
    if (!('Type' in value) || value['Type'] === undefined) return false;
    if (!('Id' in value) || value['Id'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesProfileGetResponseSocialIdentifierFromJSON(json: any): FxIdWebFeaturesProfileGetResponseSocialIdentifier {
    return FxIdWebFeaturesProfileGetResponseSocialIdentifierFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetResponseSocialIdentifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileGetResponseSocialIdentifier {
    if (json == null) {
        return json;
    }
    return {
        
        'Type': FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON(json['Type']),
        'Id': json['Id'],
    };
}

export function FxIdWebFeaturesProfileGetResponseSocialIdentifierToJSON(json: any): FxIdWebFeaturesProfileGetResponseSocialIdentifier {
    return FxIdWebFeaturesProfileGetResponseSocialIdentifierToJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetResponseSocialIdentifierToJSONTyped(value?: FxIdWebFeaturesProfileGetResponseSocialIdentifier | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Type': FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON(value['Type']),
        'Id': value['Id'],
    };
}

