/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsUnityOptionsDistribution } from './FxIdDomainSettingsUnityOptionsDistribution';
import {
    FxIdDomainSettingsUnityOptionsDistributionFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionToJSON,
    FxIdDomainSettingsUnityOptionsDistributionToJSONTyped,
} from './FxIdDomainSettingsUnityOptionsDistribution';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsUnityOptions
 */
export interface FxIdDomainSettingsUnityOptions {
    /**
     * 
     * @type {FxIdDomainSettingsUnityOptionsDistribution}
     * @memberof FxIdDomainSettingsUnityOptions
     */
    Distribution: FxIdDomainSettingsUnityOptionsDistribution;
}

/**
 * Check if a given object implements the FxIdDomainSettingsUnityOptions interface.
 */
export function instanceOfFxIdDomainSettingsUnityOptions(value: object): value is FxIdDomainSettingsUnityOptions {
    if (!('Distribution' in value) || value['Distribution'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsUnityOptionsFromJSON(json: any): FxIdDomainSettingsUnityOptions {
    return FxIdDomainSettingsUnityOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'Distribution': FxIdDomainSettingsUnityOptionsDistributionFromJSON(json['Distribution']),
    };
}

export function FxIdDomainSettingsUnityOptionsToJSON(json: any): FxIdDomainSettingsUnityOptions {
    return FxIdDomainSettingsUnityOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsToJSONTyped(value?: FxIdDomainSettingsUnityOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Distribution': FxIdDomainSettingsUnityOptionsDistributionToJSON(value['Distribution']),
    };
}

