/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens } from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens';
import {
    instanceOfFxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokensFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokensFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokensToJSON,
} from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens';

/**
 * @type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens = FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens;

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens(json)) {
        return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokensFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens(value)) {
        return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokensToJSON(value as FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens);
    }

    return {};
}

