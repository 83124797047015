/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesProfileGetResponseConnectedGame } from './FxIdWebFeaturesProfileGetResponseConnectedGame';
import {
    FxIdWebFeaturesProfileGetResponseConnectedGameFromJSON,
    FxIdWebFeaturesProfileGetResponseConnectedGameFromJSONTyped,
    FxIdWebFeaturesProfileGetResponseConnectedGameToJSON,
    FxIdWebFeaturesProfileGetResponseConnectedGameToJSONTyped,
} from './FxIdWebFeaturesProfileGetResponseConnectedGame';
import type { FxIdWebFeaturesProfileGetResponseFeature } from './FxIdWebFeaturesProfileGetResponseFeature';
import {
    FxIdWebFeaturesProfileGetResponseFeatureFromJSON,
    FxIdWebFeaturesProfileGetResponseFeatureFromJSONTyped,
    FxIdWebFeaturesProfileGetResponseFeatureToJSON,
    FxIdWebFeaturesProfileGetResponseFeatureToJSONTyped,
} from './FxIdWebFeaturesProfileGetResponseFeature';
import type { FxIdWebFeaturesProfileGetResponseTwitch } from './FxIdWebFeaturesProfileGetResponseTwitch';
import {
    FxIdWebFeaturesProfileGetResponseTwitchFromJSON,
    FxIdWebFeaturesProfileGetResponseTwitchFromJSONTyped,
    FxIdWebFeaturesProfileGetResponseTwitchToJSON,
    FxIdWebFeaturesProfileGetResponseTwitchToJSONTyped,
} from './FxIdWebFeaturesProfileGetResponseTwitch';
import type { FxIdWebFeaturesProfileGetResponseSocialIdentifier } from './FxIdWebFeaturesProfileGetResponseSocialIdentifier';
import {
    FxIdWebFeaturesProfileGetResponseSocialIdentifierFromJSON,
    FxIdWebFeaturesProfileGetResponseSocialIdentifierFromJSONTyped,
    FxIdWebFeaturesProfileGetResponseSocialIdentifierToJSON,
    FxIdWebFeaturesProfileGetResponseSocialIdentifierToJSONTyped,
} from './FxIdWebFeaturesProfileGetResponseSocialIdentifier';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesProfileGetResponse
 */
export interface FxIdWebFeaturesProfileGetResponse {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    AccountId: number;
    /**
     * 
     * @type {Array<FxIdWebFeaturesProfileGetResponseConnectedGame>}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    ConnectedGames: Array<FxIdWebFeaturesProfileGetResponseConnectedGame>;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    MarketingEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    Email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    EmailValidated: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    Locale: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    GeoCountry: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    Name?: string | null;
    /**
     * 
     * @type {Array<FxIdWebFeaturesProfileGetResponseFeature>}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    Features: Array<FxIdWebFeaturesProfileGetResponseFeature>;
    /**
     * 
     * @type {Array<FxIdWebFeaturesProfileGetResponseSocialIdentifier>}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    SocialIdentifiers: Array<FxIdWebFeaturesProfileGetResponseSocialIdentifier>;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    HasEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    Guest: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    HasCompletedOrder: boolean;
    /**
     * 
     * @type {FxIdWebFeaturesProfileGetResponseTwitch}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    Twitch?: FxIdWebFeaturesProfileGetResponseTwitch | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesProfileGetResponse interface.
 */
export function instanceOfFxIdWebFeaturesProfileGetResponse(value: object): value is FxIdWebFeaturesProfileGetResponse {
    if (!('AccountId' in value) || value['AccountId'] === undefined) return false;
    if (!('ConnectedGames' in value) || value['ConnectedGames'] === undefined) return false;
    if (!('MarketingEnabled' in value) || value['MarketingEnabled'] === undefined) return false;
    if (!('EmailValidated' in value) || value['EmailValidated'] === undefined) return false;
    if (!('Locale' in value) || value['Locale'] === undefined) return false;
    if (!('GeoCountry' in value) || value['GeoCountry'] === undefined) return false;
    if (!('Features' in value) || value['Features'] === undefined) return false;
    if (!('SocialIdentifiers' in value) || value['SocialIdentifiers'] === undefined) return false;
    if (!('HasEmail' in value) || value['HasEmail'] === undefined) return false;
    if (!('Guest' in value) || value['Guest'] === undefined) return false;
    if (!('HasCompletedOrder' in value) || value['HasCompletedOrder'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesProfileGetResponseFromJSON(json: any): FxIdWebFeaturesProfileGetResponse {
    return FxIdWebFeaturesProfileGetResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileGetResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'AccountId': json['AccountId'],
        'ConnectedGames': ((json['ConnectedGames'] as Array<any>).map(FxIdWebFeaturesProfileGetResponseConnectedGameFromJSON)),
        'MarketingEnabled': json['MarketingEnabled'],
        'Email': json['Email'] == null ? undefined : json['Email'],
        'EmailValidated': json['EmailValidated'],
        'Locale': json['Locale'],
        'GeoCountry': json['GeoCountry'],
        'Name': json['Name'] == null ? undefined : json['Name'],
        'Features': ((json['Features'] as Array<any>).map(FxIdWebFeaturesProfileGetResponseFeatureFromJSON)),
        'SocialIdentifiers': ((json['SocialIdentifiers'] as Array<any>).map(FxIdWebFeaturesProfileGetResponseSocialIdentifierFromJSON)),
        'HasEmail': json['HasEmail'],
        'Guest': json['Guest'],
        'HasCompletedOrder': json['HasCompletedOrder'],
        'Twitch': json['Twitch'] == null ? undefined : FxIdWebFeaturesProfileGetResponseTwitchFromJSON(json['Twitch']),
    };
}

export function FxIdWebFeaturesProfileGetResponseToJSON(json: any): FxIdWebFeaturesProfileGetResponse {
    return FxIdWebFeaturesProfileGetResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetResponseToJSONTyped(value?: FxIdWebFeaturesProfileGetResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AccountId': value['AccountId'],
        'ConnectedGames': ((value['ConnectedGames'] as Array<any>).map(FxIdWebFeaturesProfileGetResponseConnectedGameToJSON)),
        'MarketingEnabled': value['MarketingEnabled'],
        'Email': value['Email'],
        'EmailValidated': value['EmailValidated'],
        'Locale': value['Locale'],
        'GeoCountry': value['GeoCountry'],
        'Name': value['Name'],
        'Features': ((value['Features'] as Array<any>).map(FxIdWebFeaturesProfileGetResponseFeatureToJSON)),
        'SocialIdentifiers': ((value['SocialIdentifiers'] as Array<any>).map(FxIdWebFeaturesProfileGetResponseSocialIdentifierToJSON)),
        'HasEmail': value['HasEmail'],
        'Guest': value['Guest'],
        'HasCompletedOrder': value['HasCompletedOrder'],
        'Twitch': FxIdWebFeaturesProfileGetResponseTwitchToJSON(value['Twitch']),
    };
}

