/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsUnityOptionsDistributionComponentOptions } from './FxIdDomainSettingsUnityOptionsDistributionComponentOptions';
import {
    instanceOfFxIdDomainSettingsUnityOptionsDistributionComponentOptions,
    FxIdDomainSettingsUnityOptionsDistributionComponentOptionsFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionComponentOptionsFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionComponentOptionsToJSON,
} from './FxIdDomainSettingsUnityOptionsDistributionComponentOptions';

/**
 * @type FxIdDomainSettingsUnityOptionsDistributionOptionsComponent
 * Настрйоки компонента с юнити канвасом
 * @export
 */
export type FxIdDomainSettingsUnityOptionsDistributionOptionsComponent = FxIdDomainSettingsUnityOptionsDistributionComponentOptions;

export function FxIdDomainSettingsUnityOptionsDistributionOptionsComponentFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionOptionsComponent {
    return FxIdDomainSettingsUnityOptionsDistributionOptionsComponentFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionOptionsComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionOptionsComponent {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsUnityOptionsDistributionComponentOptions(json)) {
        return FxIdDomainSettingsUnityOptionsDistributionComponentOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsUnityOptionsDistributionOptionsComponentToJSON(json: any): any {
    return FxIdDomainSettingsUnityOptionsDistributionOptionsComponentToJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionOptionsComponentToJSONTyped(value?: FxIdDomainSettingsUnityOptionsDistributionOptionsComponent | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsUnityOptionsDistributionComponentOptions(value)) {
        return FxIdDomainSettingsUnityOptionsDistributionComponentOptionsToJSON(value as FxIdDomainSettingsUnityOptionsDistributionComponentOptions);
    }

    return {};
}

