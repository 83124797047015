/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings';
import {
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings(json)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings);
    }

    return {};
}

