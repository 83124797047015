/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsUrlOpenMethod } from './FxIdDomainSettingsUrlOpenMethod';
import {
    instanceOfFxIdDomainSettingsUrlOpenMethod,
    FxIdDomainSettingsUrlOpenMethodFromJSON,
    FxIdDomainSettingsUrlOpenMethodFromJSONTyped,
    FxIdDomainSettingsUrlOpenMethodToJSON,
} from './FxIdDomainSettingsUrlOpenMethod';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethod
 * способ показа документа TOS
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethod = FxIdDomainSettingsUrlOpenMethod;

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethod {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethod {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsUrlOpenMethod(json)) {
        return FxIdDomainSettingsUrlOpenMethodFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethod | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsUrlOpenMethod(value)) {
        return FxIdDomainSettingsUrlOpenMethodToJSON(value as FxIdDomainSettingsUrlOpenMethod);
    }

    return {};
}

