/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse
     */
    Token: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromFirebaseResponse(value: object): value is FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse {
    if (!('Token' in value) || value['Token'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromFirebaseResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse {
    return FxIdWebFeaturesAuthAuthenticateFromFirebaseResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromFirebaseResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Token': json['Token'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromFirebaseResponseToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse {
    return FxIdWebFeaturesAuthAuthenticateFromFirebaseResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromFirebaseResponseToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromFirebaseResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Token': value['Token'],
    };
}

