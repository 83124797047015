/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData } from './FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData';
import {
    FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataToJSON,
    FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest
     */
    Game?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest
     */
    VkSessionUser: FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest(value: object): value is FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest {
    if (!('VkSessionUser' in value) || value['VkSessionUser'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest {
    return FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'Game': json['Game'] == null ? undefined : json['Game'],
        'VkSessionUser': FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSON(json['VkSessionUser']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest {
    return FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Game': value['Game'],
        'VkSessionUser': FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataToJSON(value['VkSessionUser']),
    };
}

