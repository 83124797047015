/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactToJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactToJSONTyped,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseToJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseToJSONTyped,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationToJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationToJSONTyped,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration';

/**
 * 
 * @export
 * @interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult
 */
export interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult {
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult
     */
    LauncherConfiguration: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult
     */
    Release: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease;
    /**
     * 
     * @type {Array<FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact>}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult
     */
    Artifacts: Array<FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact>;
}

/**
 * Check if a given object implements the FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult interface.
 */
export function instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult(value: object): value is FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult {
    if (!('LauncherConfiguration' in value) || value['LauncherConfiguration'] === undefined) return false;
    if (!('Release' in value) || value['Release'] === undefined) return false;
    if (!('Artifacts' in value) || value['Artifacts'] === undefined) return false;
    return true;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult {
    if (json == null) {
        return json;
    }
    return {
        
        'LauncherConfiguration': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationFromJSON(json['launcherConfiguration']),
        'Release': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSON(json['release']),
        'Artifacts': ((json['artifacts'] as Array<any>).map(FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSON)),
    };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultToJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultToJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultToJSONTyped(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'launcherConfiguration': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationToJSON(value['LauncherConfiguration']),
        'release': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseToJSON(value['Release']),
        'artifacts': ((value['Artifacts'] as Array<any>).map(FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactToJSON)),
    };
}

