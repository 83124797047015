/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions
     */
    Image: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions
     */
    Type: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions {
    if (!('Image' in value) || value['Image'] === undefined) return false;
    if (!('Type' in value) || value['Type'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'Image': json['Image'],
        'Type': json['Type'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Image': value['Image'],
        'Type': value['Type'],
    };
}

