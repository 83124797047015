/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions';

/**
 * @type FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLogin
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLogin = FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions;

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLogin {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLogin {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions(json)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLogin | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions);
    }

    return {};
}

