/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsFacebookPublicAuthOptions } from './FxIdDomainSettingsFacebookPublicAuthOptions';
import {
    instanceOfFxIdDomainSettingsFacebookPublicAuthOptions,
    FxIdDomainSettingsFacebookPublicAuthOptionsFromJSON,
    FxIdDomainSettingsFacebookPublicAuthOptionsFromJSONTyped,
    FxIdDomainSettingsFacebookPublicAuthOptionsToJSON,
} from './FxIdDomainSettingsFacebookPublicAuthOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGames
 * Настройки для Facebook instant games
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGames = FxIdDomainSettingsFacebookPublicAuthOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGames {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGames {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsFacebookPublicAuthOptions(json)) {
        return FxIdDomainSettingsFacebookPublicAuthOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGames | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsFacebookPublicAuthOptions(value)) {
        return FxIdDomainSettingsFacebookPublicAuthOptionsToJSON(value as FxIdDomainSettingsFacebookPublicAuthOptions);
    }

    return {};
}

