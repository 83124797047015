/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGames } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGames';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGames';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig {
    /**
     * Доступные провайдеры для аутентификации на веб клиенте
     * @type {Array<string>}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    AllowedAuthProviders: Array<string>;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    Google?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    Facebook?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGames}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    FacebookInstantGames?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGames | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    MyGames?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    Twitch?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig {
    if (!('AllowedAuthProviders' in value) || value['AllowedAuthProviders'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'AllowedAuthProviders': json['AllowedAuthProviders'],
        'Google': json['Google'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleFromJSON(json['Google']),
        'Facebook': json['Facebook'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSON(json['Facebook']),
        'FacebookInstantGames': json['FacebookInstantGames'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesFromJSON(json['FacebookInstantGames']),
        'MyGames': json['MyGames'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSON(json['MyGames']),
        'Twitch': json['Twitch'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSON(json['Twitch']),
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AllowedAuthProviders': value['AllowedAuthProviders'],
        'Google': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleToJSON(value['Google']),
        'Facebook': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSON(value['Facebook']),
        'FacebookInstantGames': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookInstantGamesToJSON(value['FacebookInstantGames']),
        'MyGames': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesToJSON(value['MyGames']),
        'Twitch': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchToJSON(value['Twitch']),
    };
}

