/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData';
import {
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantDataFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantDataFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantDataToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData(json)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaToJSON(json: any): any {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantDataToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaMerchantData);
    }

    return {};
}

