/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig {
    /**
     * Url to download the latest version of the launcher
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig
     */
    DownloadUrl: string;
    /**
     * С этой версии хорошо бы обновиться, но в целом еще можно пользоваться
     *             
     * @type {number}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig
     */
    SoftUpdateVersion?: number | null;
    /**
     * С этой версии нужно обновиться в любом случае
     * @type {number}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig
     */
    HardUpdateVersion?: number | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig {
    if (!('DownloadUrl' in value) || value['DownloadUrl'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'DownloadUrl': json['DownloadUrl'],
        'SoftUpdateVersion': json['SoftUpdateVersion'] == null ? undefined : json['SoftUpdateVersion'],
        'HardUpdateVersion': json['HardUpdateVersion'] == null ? undefined : json['HardUpdateVersion'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'DownloadUrl': value['DownloadUrl'],
        'SoftUpdateVersion': value['SoftUpdateVersion'],
        'HardUpdateVersion': value['HardUpdateVersion'],
    };
}

