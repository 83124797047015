/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData
 */
export interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData
     */
    MyGamesId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData
     */
    AccessToken: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData interface.
 */
export function instanceOfFxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData(value: object): value is FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData {
    if (!('MyGamesId' in value) || value['MyGamesId'] === undefined) return false;
    if (!('AccessToken' in value) || value['AccessToken'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataFromJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData {
    if (json == null) {
        return json;
    }
    return {
        
        'MyGamesId': json['MyGamesId'],
        'AccessToken': json['AccessToken'],
    };
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataToJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataToJSONTyped(value?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'MyGamesId': value['MyGamesId'],
        'AccessToken': value['AccessToken'],
    };
}

