/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData
 */
export interface FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData
     */
    AccessToken: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData
     */
    RefreshToken: string;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData interface.
 */
export function instanceOfFxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData(value: object): value is FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData {
    if (!('AccessToken' in value) || value['AccessToken'] === undefined) return false;
    if (!('RefreshToken' in value) || value['RefreshToken'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataFromJSON(json: any): FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData {
    return FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData {
    if (json == null) {
        return json;
    }
    return {
        
        'AccessToken': json['AccessToken'],
        'RefreshToken': json['RefreshToken'],
    };
}

export function FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataToJSON(json: any): FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData {
    return FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataToJSONTyped(value?: FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AccessToken': value['AccessToken'],
        'RefreshToken': value['RefreshToken'],
    };
}

