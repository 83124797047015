/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions';
import {
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions;

export function FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay {
    return FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions(json)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayToJSON(json: any): any {
    return FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayToJSONTyped(value?: FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions);
    }

    return {};
}

