/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsDimensions } from './FxIdDomainSettingsDimensions';
import {
    instanceOfFxIdDomainSettingsDimensions,
    FxIdDomainSettingsDimensionsFromJSON,
    FxIdDomainSettingsDimensionsFromJSONTyped,
    FxIdDomainSettingsDimensionsToJSON,
} from './FxIdDomainSettingsDimensions';

/**
 * @type FxIdDomainSettingsPresentationOptionsMinSize
 * Минимальный размер
 * @export
 */
export type FxIdDomainSettingsPresentationOptionsMinSize = FxIdDomainSettingsDimensions;

export function FxIdDomainSettingsPresentationOptionsMinSizeFromJSON(json: any): FxIdDomainSettingsPresentationOptionsMinSize {
    return FxIdDomainSettingsPresentationOptionsMinSizeFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPresentationOptionsMinSizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPresentationOptionsMinSize {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsDimensions(json)) {
        return FxIdDomainSettingsDimensionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPresentationOptionsMinSizeToJSON(json: any): any {
    return FxIdDomainSettingsPresentationOptionsMinSizeToJSONTyped(json, false);
}

export function FxIdDomainSettingsPresentationOptionsMinSizeToJSONTyped(value?: FxIdDomainSettingsPresentationOptionsMinSize | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsDimensions(value)) {
        return FxIdDomainSettingsDimensionsToJSON(value as FxIdDomainSettingsDimensions);
    }

    return {};
}

