/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions } from './FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions';
import {
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions,
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides
 * Настройки доступности игры
 * По-умолчанию считаем, что игра доступна везде, если не указано иначе
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides = FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions(json)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions);
    }

    return {};
}

