/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
 */
export interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
     */
    AppId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
     */
    AuthKey: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
     */
    GameSid: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
     */
    Guest: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
     */
    PlayerId: string;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions(value: object): value is FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions {
    if (!('AppId' in value) || value['AppId'] === undefined) return false;
    if (!('AuthKey' in value) || value['AuthKey'] === undefined) return false;
    if (!('GameSid' in value) || value['GameSid'] === undefined) return false;
    if (!('Guest' in value) || value['Guest'] === undefined) return false;
    if (!('PlayerId' in value) || value['PlayerId'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'AppId': json['AppId'],
        'AuthKey': json['AuthKey'],
        'GameSid': json['GameSid'],
        'Guest': json['Guest'],
        'PlayerId': json['PlayerId'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsToJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsToJSONTyped(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AppId': value['AppId'],
        'AuthKey': value['AuthKey'],
        'GameSid': value['GameSid'],
        'Guest': value['Guest'],
        'PlayerId': value['PlayerId'],
    };
}

