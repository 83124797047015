import { Button, Stack, Text, TextInput } from "@mantine/core";
import { ContextModalProps, openContextModal } from "@mantine/modals";
import { exitFullscreen } from "@app/Utils/Utils";
import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// OS detection function
const isMacOS = () => {
	return navigator.userAgent.toLowerCase().includes("mac");
};

export const TextCopyModal = ({ context, id, innerProps }: ContextModalProps<{ text: string }>) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [clipboardAvailable, setClipboardAvailable] = useState<boolean>(true);
	const copyKey = isMacOS() ? "⌘+C" : "Ctrl+C";
	const { t } = useTranslation();

	// useEffect(() => {
	// 	const checkClipboardAccess = async () => {
	// 		try {
	// 			const permissionStatus = await navigator.permissions.query({
	// 				name: "clipboard-write" as PermissionName
	// 			});
	// 			setClipboardAvailable(permissionStatus.state === "granted" || permissionStatus.state === "prompt");
	// 		} catch {
	// 			setClipboardAvailable(false);
	// 		}
	// 	};
	//
	// 	void checkClipboardAccess();
	// }, []);

	// const handleCopy = async () => {
	// 	try {
	// 		await navigator.clipboard.writeText(innerProps.text);
	// 		context.closeModal(id);
	// 	} catch (error) {
	// 		log.warn("Failed to copy text:", error);
	// 		inputRef.current?.select();
	// 	}
	// };

	const handleTextClick = () => {
		inputRef.current?.select();
	};

	return (
		<Stack spacing="md">
			<Text>{t("textcopymodal.title")}</Text>
			<TextInput
				ref={inputRef}
				value={innerProps.text}
				readOnly
				onClick={handleTextClick}
				styles={(theme) => ({
					input: {
						cursor: "pointer",
						backgroundColor: theme.colors.gray[0],
						border: `1px solid ${theme.colors.gray[3]}`,
						padding: "12px",
						fontSize: theme.fontSizes.sm,
						minHeight: "80px",
						whiteSpace: "pre-wrap",
						"&:focus": {
							borderColor: theme.colors.blue[5]
						}
					}
				})}
			/>
			<Text size="xs" color="orange" align="center">
				<>{t("textcopymodal.copytext", { copyKey })}</>
			</Text>
			{/*{clipboardAvailable && (*/}
			{/*	<Button onClick={handleCopy} fullWidth>*/}
			{/*		Copy to Clipboard*/}
			{/*	</Button>*/}
			{/*)}*/}
		</Stack>
	);
};

export const openTextCopyModal = (params: { text: string } & { onClose?: () => void }) => {
	const { onClose, ...innerProps } = params;

	void exitFullscreen();

	openContextModal({
		modal: "textCopyModal",
		innerProps: { text: params.text },
		withCloseButton: true,
		size: "lg"
		// styles: {
		// 	body: {
		// 		padding: "20px"
		// 	}
		// }
	});
};
