/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions';
import {
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncher
 * Опции для ланчера
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncher = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncher {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncher {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions(json)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncher | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions);
    }

    return {};
}

