/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference {
    Low = 0,
    Medium = 1,
    High = 2,
    NUMBER_unknown_default_open_api = 11184809
}


export function instanceOfFxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference(value: any): boolean {
    for (const key in FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference) {
        if (Object.prototype.hasOwnProperty.call(FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference, key)) {
            if (FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference[key as keyof typeof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference {
    return FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference {
    return json as FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference;
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceToJSON(value?: FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference | null): any {
    return value as any;
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceToJSONTyped(value: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference {
    return value as FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference;
}

