/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayListResponseBuild
 */
export interface FxIdWebFeaturesPlayListResponseBuild {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    Id: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    GameId: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    BuildType: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    Sha: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    Description: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    ChangeSet: string;
    /**
     * 
     * @type {Date}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    CreatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    BuildNumber: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    Metadata: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    InstallUrl: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayListResponseBuild interface.
 */
export function instanceOfFxIdWebFeaturesPlayListResponseBuild(value: object): value is FxIdWebFeaturesPlayListResponseBuild {
    if (!('Id' in value) || value['Id'] === undefined) return false;
    if (!('GameId' in value) || value['GameId'] === undefined) return false;
    if (!('BuildType' in value) || value['BuildType'] === undefined) return false;
    if (!('Sha' in value) || value['Sha'] === undefined) return false;
    if (!('Description' in value) || value['Description'] === undefined) return false;
    if (!('ChangeSet' in value) || value['ChangeSet'] === undefined) return false;
    if (!('CreatedAt' in value) || value['CreatedAt'] === undefined) return false;
    if (!('BuildNumber' in value) || value['BuildNumber'] === undefined) return false;
    if (!('Metadata' in value) || value['Metadata'] === undefined) return false;
    if (!('InstallUrl' in value) || value['InstallUrl'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesPlayListResponseBuildFromJSON(json: any): FxIdWebFeaturesPlayListResponseBuild {
    return FxIdWebFeaturesPlayListResponseBuildFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayListResponseBuildFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayListResponseBuild {
    if (json == null) {
        return json;
    }
    return {
        
        'Id': json['Id'],
        'GameId': json['GameId'],
        'BuildType': json['BuildType'],
        'Sha': json['Sha'],
        'Description': json['Description'],
        'ChangeSet': json['ChangeSet'],
        'CreatedAt': (new Date(json['CreatedAt'])),
        'BuildNumber': json['BuildNumber'],
        'Metadata': json['Metadata'],
        'InstallUrl': json['InstallUrl'],
    };
}

export function FxIdWebFeaturesPlayListResponseBuildToJSON(json: any): FxIdWebFeaturesPlayListResponseBuild {
    return FxIdWebFeaturesPlayListResponseBuildToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayListResponseBuildToJSONTyped(value?: FxIdWebFeaturesPlayListResponseBuild | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Id': value['Id'],
        'GameId': value['GameId'],
        'BuildType': value['BuildType'],
        'Sha': value['Sha'],
        'Description': value['Description'],
        'ChangeSet': value['ChangeSet'],
        'CreatedAt': ((value['CreatedAt']).toISOString()),
        'BuildNumber': value['BuildNumber'],
        'Metadata': value['Metadata'],
        'InstallUrl': value['InstallUrl'],
    };
}

