/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions
     */
    Uid: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions
     */
    IpAddress: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions
     */
    Currency: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions(value: object): value is FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions {
    if (!('Uid' in value) || value['Uid'] === undefined) return false;
    if (!('IpAddress' in value) || value['IpAddress'] === undefined) return false;
    if (!('Currency' in value) || value['Currency'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'Uid': json['Uid'],
        'IpAddress': json['IpAddress'],
        'Currency': json['Currency'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Uid': value['Uid'],
        'IpAddress': value['IpAddress'],
        'Currency': value['Currency'],
    };
}

