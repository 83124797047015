/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClass } from './FxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClass';
import {
    instanceOfFxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClass,
    FxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClassFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClassFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClassToJSON,
} from './FxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClass';

/**
 * @type FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData = FxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClass;

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData {
    return FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClass(json)) {
        return FxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClassFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClass(value)) {
        return FxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClassToJSON(value as FxIdWebFeaturesAuthAuthenticateFromGameConnectedDataClass);
    }

    return {};
}

