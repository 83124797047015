/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantData
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantData
     */
    ExtraAttributes: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantData interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantData(value: object): value is FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantData {
    if (!('ExtraAttributes' in value) || value['ExtraAttributes'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantDataFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantData {
    if (json == null) {
        return json;
    }
    return {
        
        'ExtraAttributes': json['ExtraAttributes'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantDataToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantDataToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantDataToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiMerchantData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ExtraAttributes': value['ExtraAttributes'],
    };
}

