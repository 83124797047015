/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLogos
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLogos {
    /**
     * Логотип компании
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLogos
     */
    CompanyLogo: string;
    /**
     * Брендовый логотип сайта
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLogos
     */
    BrandLogo: string;
    /**
     * Логотип для веб-плеера
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLogos
     */
    WebPlayerLogo: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLogos interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLogos(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLogos {
    if (!('CompanyLogo' in value) || value['CompanyLogo'] === undefined) return false;
    if (!('BrandLogo' in value) || value['BrandLogo'] === undefined) return false;
    if (!('WebPlayerLogo' in value) || value['WebPlayerLogo'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLogosFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLogos {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLogosFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLogosFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLogos {
    if (json == null) {
        return json;
    }
    return {
        
        'CompanyLogo': json['CompanyLogo'],
        'BrandLogo': json['BrandLogo'],
        'WebPlayerLogo': json['WebPlayerLogo'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLogosToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLogos {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLogosToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLogosToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLogos | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'CompanyLogo': value['CompanyLogo'],
        'BrandLogo': value['BrandLogo'],
        'WebPlayerLogo': value['WebPlayerLogo'],
    };
}

