/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SystemNetMailMailAddress } from './SystemNetMailMailAddress';
import {
    instanceOfSystemNetMailMailAddress,
    SystemNetMailMailAddressFromJSON,
    SystemNetMailMailAddressFromJSONTyped,
    SystemNetMailMailAddressToJSON,
} from './SystemNetMailMailAddress';

/**
 * @type FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddress
 * 
 * @export
 */
export type FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddress = SystemNetMailMailAddress;

export function FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddressFromJSON(json: any): FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddress {
    return FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddressFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddress {
    if (json == null) {
        return json;
    }
    if (instanceOfSystemNetMailMailAddress(json)) {
        return SystemNetMailMailAddressFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddressToJSON(json: any): any {
    return FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddressToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddressToJSONTyped(value?: FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddress | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfSystemNetMailMailAddress(value)) {
        return SystemNetMailMailAddressToJSON(value as SystemNetMailMailAddress);
    }

    return {};
}

