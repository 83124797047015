/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainStoreEnumsSupportedWebPublishingPlatform } from './FxIdDomainStoreEnumsSupportedWebPublishingPlatform';
import {
    instanceOfFxIdDomainStoreEnumsSupportedWebPublishingPlatform,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSONTyped,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON,
} from './FxIdDomainStoreEnumsSupportedWebPublishingPlatform';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatform
 * TODO: Отказаться от этого параметра, и брать текущую платформу из токена - мы же знаем где пользователь авторизовался
 * Тут я на радостях подумал убрать установку "платформы с которой мы платим" в CreatePayment но вспомнил - у нас же кука по домену переносится. Нам надо сначала это починить и потом убирать этот хинт
 * Иначе ты зашел на вк ком, мы знаем что ты зашел оттуда.
 * Пошел на портал
 * Хуяк - тебе платжка вкком - в токене то стоит что ты авторизовался оттуда
 * Так же надо подумать что мы будем делать если захотим авторизовывать пользователей через FxId как Oauth провайдера
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatform = FxIdDomainStoreEnumsSupportedWebPublishingPlatform;

export function FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatform {
    return FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatform {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainStoreEnumsSupportedWebPublishingPlatform(json)) {
        return FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformToJSON(json: any): any {
    return FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatformToJSONTyped(value?: FxIdWebFeaturesStoreCreatePaymentRequestWebPublishingPlatform | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainStoreEnumsSupportedWebPublishingPlatform(value)) {
        return FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON(value as FxIdDomainStoreEnumsSupportedWebPublishingPlatform);
    }

    return {};
}

