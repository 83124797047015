/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData {
    /**
     * 
     * @type {number}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData
     */
    SecuredOrderId: number;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData(value: object): value is FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData {
    if (!('SecuredOrderId' in value) || value['SecuredOrderId'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData {
    if (json == null) {
        return json;
    }
    return {
        
        'SecuredOrderId': json['SecuredOrderId'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'SecuredOrderId': value['SecuredOrderId'],
    };
}

