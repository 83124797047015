/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions';

/**
 * @type FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLogin
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLogin = FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions;

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLogin {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLogin {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions(json)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLogin | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions);
    }

    return {};
}

