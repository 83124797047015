/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsUnityOptionsDistributionOptions } from './FxIdDomainSettingsUnityOptionsDistributionOptions';
import {
    instanceOfFxIdDomainSettingsUnityOptionsDistributionOptions,
    FxIdDomainSettingsUnityOptionsDistributionOptionsFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionOptionsFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionOptionsToJSON,
} from './FxIdDomainSettingsUnityOptionsDistributionOptions';

/**
 * @type FxIdDomainSettingsUnityOptionsDistribution
 * Данные для встраивания игры
 * @export
 */
export type FxIdDomainSettingsUnityOptionsDistribution = FxIdDomainSettingsUnityOptionsDistributionOptions;

export function FxIdDomainSettingsUnityOptionsDistributionFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistribution {
    return FxIdDomainSettingsUnityOptionsDistributionFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistribution {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsUnityOptionsDistributionOptions(json)) {
        return FxIdDomainSettingsUnityOptionsDistributionOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsUnityOptionsDistributionToJSON(json: any): any {
    return FxIdDomainSettingsUnityOptionsDistributionToJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionToJSONTyped(value?: FxIdDomainSettingsUnityOptionsDistribution | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsUnityOptionsDistributionOptions(value)) {
        return FxIdDomainSettingsUnityOptionsDistributionOptionsToJSON(value as FxIdDomainSettingsUnityOptionsDistributionOptions);
    }

    return {};
}

