/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData } from './FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData';
import {
    FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataFromJSON,
    FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataFromJSONTyped,
    FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataToJSON,
    FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataToJSONTyped,
} from './FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromTokenResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateFromTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromTokenResponse
     */
    Token: string;
    /**
     * 
     * @type {FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromTokenResponse
     */
    TempTokenData: FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromTokenResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromTokenResponse(value: object): value is FxIdWebFeaturesAuthAuthenticateFromTokenResponse {
    if (!('Token' in value) || value['Token'] === undefined) return false;
    if (!('TempTokenData' in value) || value['TempTokenData'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromTokenResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromTokenResponse {
    return FxIdWebFeaturesAuthAuthenticateFromTokenResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromTokenResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Token': json['Token'],
        'TempTokenData': FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataFromJSON(json['TempTokenData']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromTokenResponseToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromTokenResponse {
    return FxIdWebFeaturesAuthAuthenticateFromTokenResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromTokenResponseToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromTokenResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Token': value['Token'],
        'TempTokenData': FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataToJSON(value['TempTokenData']),
    };
}

