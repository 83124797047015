/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType } from './FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType';
import {
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSONTyped,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSONTyped,
} from './FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromSocialResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateFromSocialResponse {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialResponse
     */
    Token: string;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialResponse
     */
    AccountId: number;
    /**
     * 
     * @type {FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialResponse
     */
    AuthenticatedSocialIdentifierType: FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialResponse
     */
    AuthenticatedSocialIdentifier: string;
}



/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromSocialResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromSocialResponse(value: object): value is FxIdWebFeaturesAuthAuthenticateFromSocialResponse {
    if (!('Token' in value) || value['Token'] === undefined) return false;
    if (!('AccountId' in value) || value['AccountId'] === undefined) return false;
    if (!('AuthenticatedSocialIdentifierType' in value) || value['AuthenticatedSocialIdentifierType'] === undefined) return false;
    if (!('AuthenticatedSocialIdentifier' in value) || value['AuthenticatedSocialIdentifier'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialResponse {
    return FxIdWebFeaturesAuthAuthenticateFromSocialResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromSocialResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Token': json['Token'],
        'AccountId': json['AccountId'],
        'AuthenticatedSocialIdentifierType': FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON(json['AuthenticatedSocialIdentifierType']),
        'AuthenticatedSocialIdentifier': json['AuthenticatedSocialIdentifier'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialResponseToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialResponse {
    return FxIdWebFeaturesAuthAuthenticateFromSocialResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialResponseToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromSocialResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Token': value['Token'],
        'AccountId': value['AccountId'],
        'AuthenticatedSocialIdentifierType': FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON(value['AuthenticatedSocialIdentifierType']),
        'AuthenticatedSocialIdentifier': value['AuthenticatedSocialIdentifier'],
    };
}

