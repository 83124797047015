/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData
     */
    PaymentIntentClientSecret: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData
     */
    PublishableKey: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData
     */
    SessionUrl: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData(value: object): value is FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData {
    if (!('PaymentIntentClientSecret' in value) || value['PaymentIntentClientSecret'] === undefined) return false;
    if (!('PublishableKey' in value) || value['PublishableKey'] === undefined) return false;
    if (!('SessionUrl' in value) || value['SessionUrl'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantDataFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData {
    if (json == null) {
        return json;
    }
    return {
        
        'PaymentIntentClientSecret': json['PaymentIntentClientSecret'],
        'PublishableKey': json['PublishableKey'],
        'SessionUrl': json['SessionUrl'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantDataToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantDataToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantDataToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeMerchantData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'PaymentIntentClientSecret': value['PaymentIntentClientSecret'],
        'PublishableKey': value['PublishableKey'],
        'SessionUrl': value['SessionUrl'],
    };
}

