/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions } from './FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions';
import {
    instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions,
    FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptionsFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptionsToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions';

/**
 * @type FxIdWebFeaturesPlayPlatformDataOptionsWindows
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPlatformDataOptionsWindows = FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions;

export function FxIdWebFeaturesPlayPlatformDataOptionsWindowsFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsWindows {
    return FxIdWebFeaturesPlayPlatformDataOptionsWindowsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWindowsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsWindows {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions(json)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWindowsToJSON(json: any): any {
    return FxIdWebFeaturesPlayPlatformDataOptionsWindowsToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWindowsToJSONTyped(value?: FxIdWebFeaturesPlayPlatformDataOptionsWindows | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions(value)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptionsToJSON(value as FxIdWebFeaturesPlayPlatformDataOptionsWindowsOptions);
    }

    return {};
}

