/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements
     */
    OperatingSystem: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements
     */
    Processor: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements
     */
    Ram: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements
     */
    VideoCard: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements {
    if (!('OperatingSystem' in value) || value['OperatingSystem'] === undefined) return false;
    if (!('Processor' in value) || value['Processor'] === undefined) return false;
    if (!('Ram' in value) || value['Ram'] === undefined) return false;
    if (!('VideoCard' in value) || value['VideoCard'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements {
    if (json == null) {
        return json;
    }
    return {
        
        'OperatingSystem': json['OperatingSystem'],
        'Processor': json['Processor'],
        'Ram': json['Ram'],
        'VideoCard': json['VideoCard'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'OperatingSystem': value['OperatingSystem'],
        'Processor': value['Processor'],
        'Ram': value['Ram'],
        'VideoCard': value['VideoCard'],
    };
}

