/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsDiagType } from './FxIdDomainSettingsPublicWebClientConfigOptionsDiagType';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsDiagTypeFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsDiagTypeFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsDiagTypeToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsDiagTypeToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsDiagType';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem {
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsDiagType}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem
     */
    Type: FxIdDomainSettingsPublicWebClientConfigOptionsDiagType;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem
     */
    ServerType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem
     */
    ServerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem
     */
    Port?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem
     */
    Count?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem
     */
    MaxTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem
     */
    ResultFile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem
     */
    SourceName?: string | null;
}



/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsDiagItem(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem {
    if (!('Type' in value) || value['Type'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem {
    return FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem {
    if (json == null) {
        return json;
    }
    return {
        
        'Type': FxIdDomainSettingsPublicWebClientConfigOptionsDiagTypeFromJSON(json['Type']),
        'ServerType': json['ServerType'] == null ? undefined : json['ServerType'],
        'ServerName': json['ServerName'] == null ? undefined : json['ServerName'],
        'Port': json['Port'] == null ? undefined : json['Port'],
        'Count': json['Count'] == null ? undefined : json['Count'],
        'MaxTime': json['MaxTime'] == null ? undefined : json['MaxTime'],
        'ResultFile': json['ResultFile'] == null ? undefined : json['ResultFile'],
        'SourceName': json['SourceName'] == null ? undefined : json['SourceName'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem {
    return FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Type': FxIdDomainSettingsPublicWebClientConfigOptionsDiagTypeToJSON(value['Type']),
        'ServerType': value['ServerType'],
        'ServerName': value['ServerName'],
        'Port': value['Port'],
        'Count': value['Count'],
        'MaxTime': value['MaxTime'],
        'ResultFile': value['ResultFile'],
        'SourceName': value['SourceName'],
    };
}

