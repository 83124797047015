/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreStoreAvailabilityResponse
 */
export interface FxIdWebFeaturesStoreStoreAvailabilityResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesStoreStoreAvailabilityResponse
     */
    Available: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesStoreStoreAvailabilityResponse
     */
    Games: Array<string>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreStoreAvailabilityResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreStoreAvailabilityResponse(value: object): value is FxIdWebFeaturesStoreStoreAvailabilityResponse {
    if (!('Available' in value) || value['Available'] === undefined) return false;
    if (!('Games' in value) || value['Games'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreStoreAvailabilityResponseFromJSON(json: any): FxIdWebFeaturesStoreStoreAvailabilityResponse {
    return FxIdWebFeaturesStoreStoreAvailabilityResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreStoreAvailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreStoreAvailabilityResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Available': json['Available'],
        'Games': json['Games'],
    };
}

export function FxIdWebFeaturesStoreStoreAvailabilityResponseToJSON(json: any): FxIdWebFeaturesStoreStoreAvailabilityResponse {
    return FxIdWebFeaturesStoreStoreAvailabilityResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreStoreAvailabilityResponseToJSONTyped(value?: FxIdWebFeaturesStoreStoreAvailabilityResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Available': value['Available'],
        'Games': value['Games'],
    };
}

