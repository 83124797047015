/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData
     */
    InvoiceUrl: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData(value: object): value is FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData {
    if (!('InvoiceUrl' in value) || value['InvoiceUrl'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData {
    if (json == null) {
        return json;
    }
    return {
        
        'InvoiceUrl': json['InvoiceUrl'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'InvoiceUrl': value['InvoiceUrl'],
    };
}

