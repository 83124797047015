/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions {
    /**
     * 
     * @type {number}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions
     */
    AppId: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions
     */
    AuthKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions
     */
    Debug: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions
     */
    GameSid: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions
     */
    PlayerId: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions
     */
    Guest: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions
     */
    Invite: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions(value: object): value is FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions {
    if (!('AppId' in value) || value['AppId'] === undefined) return false;
    if (!('AuthKey' in value) || value['AuthKey'] === undefined) return false;
    if (!('Debug' in value) || value['Debug'] === undefined) return false;
    if (!('GameSid' in value) || value['GameSid'] === undefined) return false;
    if (!('PlayerId' in value) || value['PlayerId'] === undefined) return false;
    if (!('Guest' in value) || value['Guest'] === undefined) return false;
    if (!('Invite' in value) || value['Invite'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'AppId': json['app_id'],
        'AuthKey': json['auth_key'],
        'Debug': json['debug'],
        'GameSid': json['game_sid'],
        'PlayerId': json['player_id'],
        'Guest': json['guest'],
        'Invite': json['invite'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'app_id': value['AppId'],
        'auth_key': value['AuthKey'],
        'debug': value['Debug'],
        'game_sid': value['GameSid'],
        'player_id': value['PlayerId'],
        'guest': value['Guest'],
        'invite': value['Invite'],
    };
}

