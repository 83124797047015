/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesPlayPublicDataBase } from './FxIdWebFeaturesPlayPublicDataBase';
import {
    FxIdWebFeaturesPlayPublicDataBaseFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseToJSON,
    FxIdWebFeaturesPlayPublicDataBaseToJSONTyped,
} from './FxIdWebFeaturesPlayPublicDataBase';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesGamesListWithConfigsResponse
 */
export interface FxIdWebFeaturesGamesListWithConfigsResponse {
    /**
     * 
     * @type {Array<FxIdWebFeaturesPlayPublicDataBase>}
     * @memberof FxIdWebFeaturesGamesListWithConfigsResponse
     */
    AvailableGames: Array<FxIdWebFeaturesPlayPublicDataBase>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesGamesListWithConfigsResponse interface.
 */
export function instanceOfFxIdWebFeaturesGamesListWithConfigsResponse(value: object): value is FxIdWebFeaturesGamesListWithConfigsResponse {
    if (!('AvailableGames' in value) || value['AvailableGames'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesGamesListWithConfigsResponseFromJSON(json: any): FxIdWebFeaturesGamesListWithConfigsResponse {
    return FxIdWebFeaturesGamesListWithConfigsResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesListWithConfigsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesGamesListWithConfigsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'AvailableGames': ((json['AvailableGames'] as Array<any>).map(FxIdWebFeaturesPlayPublicDataBaseFromJSON)),
    };
}

export function FxIdWebFeaturesGamesListWithConfigsResponseToJSON(json: any): FxIdWebFeaturesGamesListWithConfigsResponse {
    return FxIdWebFeaturesGamesListWithConfigsResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesListWithConfigsResponseToJSONTyped(value?: FxIdWebFeaturesGamesListWithConfigsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AvailableGames': ((value['AvailableGames'] as Array<any>).map(FxIdWebFeaturesPlayPublicDataBaseToJSON)),
    };
}

