/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions
 */
export interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions
     */
    SignedRequest: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions
     */
    Locale?: string | null;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions(value: object): value is FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions {
    if (!('SignedRequest' in value) || value['SignedRequest'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'SignedRequest': json['SignedRequest'],
        'Locale': json['Locale'] == null ? undefined : json['Locale'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsToJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsToJSONTyped(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'SignedRequest': value['SignedRequest'],
        'Locale': value['Locale'],
    };
}

