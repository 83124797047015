/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsFxEventsClientPublicConfigConfigSection
 */
export interface FxIdDomainSettingsFxEventsClientPublicConfigConfigSection {
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdDomainSettingsFxEventsClientPublicConfigConfigSection
     */
    SendTo: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof FxIdDomainSettingsFxEventsClientPublicConfigConfigSection
     */
    Mapper: any | null;
    /**
     * 
     * @type {any}
     * @memberof FxIdDomainSettingsFxEventsClientPublicConfigConfigSection
     */
    Matcher: any | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsFxEventsClientPublicConfigConfigSection interface.
 */
export function instanceOfFxIdDomainSettingsFxEventsClientPublicConfigConfigSection(value: object): value is FxIdDomainSettingsFxEventsClientPublicConfigConfigSection {
    if (!('SendTo' in value) || value['SendTo'] === undefined) return false;
    if (!('Mapper' in value) || value['Mapper'] === undefined) return false;
    if (!('Matcher' in value) || value['Matcher'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSON(json: any): FxIdDomainSettingsFxEventsClientPublicConfigConfigSection {
    return FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSONTyped(json, false);
}

export function FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsFxEventsClientPublicConfigConfigSection {
    if (json == null) {
        return json;
    }
    return {
        
        'SendTo': json['SendTo'],
        'Mapper': json['Mapper'],
        'Matcher': json['Matcher'],
    };
}

export function FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionToJSON(json: any): FxIdDomainSettingsFxEventsClientPublicConfigConfigSection {
    return FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionToJSONTyped(json, false);
}

export function FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionToJSONTyped(value?: FxIdDomainSettingsFxEventsClientPublicConfigConfigSection | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'SendTo': value['SendTo'],
        'Mapper': value['Mapper'],
        'Matcher': value['Matcher'],
    };
}

