/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainSettingsUrlOpenMethod {
    Unspecified = 0,
    Modal = 1,
    NewPage = 2,
    NUMBER_unknown_default_open_api = 11184809
}


export function instanceOfFxIdDomainSettingsUrlOpenMethod(value: any): boolean {
    for (const key in FxIdDomainSettingsUrlOpenMethod) {
        if (Object.prototype.hasOwnProperty.call(FxIdDomainSettingsUrlOpenMethod, key)) {
            if (FxIdDomainSettingsUrlOpenMethod[key as keyof typeof FxIdDomainSettingsUrlOpenMethod] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FxIdDomainSettingsUrlOpenMethodFromJSON(json: any): FxIdDomainSettingsUrlOpenMethod {
    return FxIdDomainSettingsUrlOpenMethodFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUrlOpenMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUrlOpenMethod {
    return json as FxIdDomainSettingsUrlOpenMethod;
}

export function FxIdDomainSettingsUrlOpenMethodToJSON(value?: FxIdDomainSettingsUrlOpenMethod | null): any {
    return value as any;
}

export function FxIdDomainSettingsUrlOpenMethodToJSONTyped(value: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUrlOpenMethod {
    return value as FxIdDomainSettingsUrlOpenMethod;
}

