/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreYandexGamesPurchaseRequest
 */
export interface FxIdWebFeaturesStoreYandexGamesPurchaseRequest {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesStoreYandexGamesPurchaseRequest
     */
    YandexGetPurchasesRequest: boolean;
    /**
     * https://yandex.ru/dev/games/doc/dg/sdk/sdk-purchases.html#sdk-purchases__signature
     * @type {string}
     * @memberof FxIdWebFeaturesStoreYandexGamesPurchaseRequest
     */
    YandexSignedData: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreYandexGamesPurchaseRequest interface.
 */
export function instanceOfFxIdWebFeaturesStoreYandexGamesPurchaseRequest(value: object): value is FxIdWebFeaturesStoreYandexGamesPurchaseRequest {
    if (!('YandexGetPurchasesRequest' in value) || value['YandexGetPurchasesRequest'] === undefined) return false;
    if (!('YandexSignedData' in value) || value['YandexSignedData'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreYandexGamesPurchaseRequestFromJSON(json: any): FxIdWebFeaturesStoreYandexGamesPurchaseRequest {
    return FxIdWebFeaturesStoreYandexGamesPurchaseRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreYandexGamesPurchaseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreYandexGamesPurchaseRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'YandexGetPurchasesRequest': json['YandexGetPurchasesRequest'],
        'YandexSignedData': json['YandexSignedData'],
    };
}

export function FxIdWebFeaturesStoreYandexGamesPurchaseRequestToJSON(json: any): FxIdWebFeaturesStoreYandexGamesPurchaseRequest {
    return FxIdWebFeaturesStoreYandexGamesPurchaseRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreYandexGamesPurchaseRequestToJSONTyped(value?: FxIdWebFeaturesStoreYandexGamesPurchaseRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'YandexGetPurchasesRequest': value['YandexGetPurchasesRequest'],
        'YandexSignedData': value['YandexSignedData'],
    };
}

