/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken } from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken';
import {
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenToJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType } from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeToJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeToJSONTyped,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType';
import type { FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLogin } from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLogin';
import {
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLogin';
import type { FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLogin } from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLogin';
import {
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLogin';
import type { FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLogin } from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLogin';
import {
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLogin';
import type { FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFacebookLogin } from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFacebookLogin';
import {
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFacebookLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFacebookLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFacebookLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFacebookLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFacebookLogin';
import type { FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLogin } from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLogin';
import {
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLogin';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest {
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    AuthenticationType: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    Locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    SessionId?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    SteamLogin?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    SteamOpenIdLogin?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    EgsLogin?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    GoogleLogin?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFacebookLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    FacebookLogin?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFacebookLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest
     */
    AuthenticateWithExternalIdGameAccessToken?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessToken | null;
}



/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest(value: object): value is FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest {
    if (!('AuthenticationType' in value) || value['AuthenticationType'] === undefined) return false;
    if (!('Game' in value) || value['Game'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'AuthenticationType': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeFromJSON(json['AuthenticationType']),
        'Game': json['Game'],
        'Locale': json['Locale'] == null ? undefined : json['Locale'],
        'SessionId': json['SessionId'] == null ? undefined : json['SessionId'],
        'SteamLogin': json['SteamLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginFromJSON(json['SteamLogin']),
        'SteamOpenIdLogin': json['SteamOpenIdLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginFromJSON(json['SteamOpenIdLogin']),
        'EgsLogin': json['EgsLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginFromJSON(json['EgsLogin']),
        'GoogleLogin': json['GoogleLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginFromJSON(json['GoogleLogin']),
        'FacebookLogin': json['FacebookLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFacebookLoginFromJSON(json['FacebookLogin']),
        'AuthenticateWithExternalIdGameAccessToken': json['AuthenticateWithExternalIdGameAccessToken'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenFromJSON(json['AuthenticateWithExternalIdGameAccessToken']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestToJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AuthenticationType': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeToJSON(value['AuthenticationType']),
        'Game': value['Game'],
        'Locale': value['Locale'],
        'SessionId': value['SessionId'],
        'SteamLogin': FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamLoginToJSON(value['SteamLogin']),
        'SteamOpenIdLogin': FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestSteamOpenIdLoginToJSON(value['SteamOpenIdLogin']),
        'EgsLogin': FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestEgsLoginToJSON(value['EgsLogin']),
        'GoogleLogin': FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginToJSON(value['GoogleLogin']),
        'FacebookLogin': FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestFacebookLoginToJSON(value['FacebookLogin']),
        'AuthenticateWithExternalIdGameAccessToken': FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestAuthenticateWithExternalIdGameAccessTokenToJSON(value['AuthenticateWithExternalIdGameAccessToken']),
    };
}

