/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError } from './FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError';
import {
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorFromJSON,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorFromJSONTyped,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorToJSON,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorToJSONTyped,
} from './FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse
 */
export interface FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse {
    /**
     * 
     * @type {FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError}
     * @memberof FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse
     */
    Error?: FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse
     */
    Success: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse
     */
    RedirectUri?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse(value: object): value is FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse {
    if (!('Success' in value) || value['Success'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseFromJSON(json: any): FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse {
    return FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Error': json['Error'] == null ? undefined : FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorFromJSON(json['Error']),
        'Success': json['Success'],
        'RedirectUri': json['RedirectUri'] == null ? undefined : json['RedirectUri'],
    };
}

export function FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseToJSON(json: any): FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse {
    return FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseToJSONTyped(value?: FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Error': FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorToJSON(value['Error']),
        'Success': value['Success'],
        'RedirectUri': value['RedirectUri'],
    };
}

