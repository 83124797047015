/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdApplicationHandlersAuthAuthenticateWithExternalIdState {
    Unspecified = 0,
    FailedNoCredentials = 1,
    FailedWrongEmailForExternalId = 2,
    FailedNeedEmailCode = 3,
    FailedWrongCode = 4,
    FailedUnknownError = 5,
    FailedValidationError = 6,
    FailedInviteError = 7,
    FailedExternalIdWithoutEmail = 9,
    FailedAccountWithoutEmail = 10,
    FailedTooMuchRequestsForInvite = 11,
    FailedEmailTaken = 12,
    Success = 99,
    NUMBER_unknown_default_open_api = 11184809
}


export function instanceOfFxIdApplicationHandlersAuthAuthenticateWithExternalIdState(value: any): boolean {
    for (const key in FxIdApplicationHandlersAuthAuthenticateWithExternalIdState) {
        if (Object.prototype.hasOwnProperty.call(FxIdApplicationHandlersAuthAuthenticateWithExternalIdState, key)) {
            if (FxIdApplicationHandlersAuthAuthenticateWithExternalIdState[key as keyof typeof FxIdApplicationHandlersAuthAuthenticateWithExternalIdState] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateWithExternalIdState {
    return FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateWithExternalIdState {
    return json as FxIdApplicationHandlersAuthAuthenticateWithExternalIdState;
}

export function FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateToJSON(value?: FxIdApplicationHandlersAuthAuthenticateWithExternalIdState | null): any {
    return value as any;
}

export function FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateToJSONTyped(value: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateWithExternalIdState {
    return value as FxIdApplicationHandlersAuthAuthenticateWithExternalIdState;
}

