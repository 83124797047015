/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris
 */
export interface FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris
     */
    RedirectOnSuccess: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris
     */
    RedirectOnFailure: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris interface.
 */
export function instanceOfFxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris(value: object): value is FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris {
    if (!('RedirectOnSuccess' in value) || value['RedirectOnSuccess'] === undefined) return false;
    if (!('RedirectOnFailure' in value) || value['RedirectOnFailure'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUrisFromJSON(json: any): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris {
    return FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUrisFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUrisFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris {
    if (json == null) {
        return json;
    }
    return {
        
        'RedirectOnSuccess': json['RedirectOnSuccess'],
        'RedirectOnFailure': json['RedirectOnFailure'],
    };
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUrisToJSON(json: any): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris {
    return FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUrisToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUrisToJSONTyped(value?: FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'RedirectOnSuccess': value['RedirectOnSuccess'],
        'RedirectOnFailure': value['RedirectOnFailure'],
    };
}

