/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData } from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData';
import {
    instanceOfFxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataFromJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataFromJSONTyped,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataToJSON,
} from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData';

/**
 * @type FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseData
 * 
 * @export
 */
export type FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseData = FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData;

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseDataFromJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseData {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseData {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData(json)) {
        return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseDataToJSON(json: any): any {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseDataToJSONTyped(value?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthResponseData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData(value)) {
        return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataToJSON(value as FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData);
    }

    return {};
}

