/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions
 */
export interface FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions
     */
    ApplicationScopedId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions
     */
    Signature: string;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions(value: object): value is FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions {
    if (!('ApplicationScopedId' in value) || value['ApplicationScopedId'] === undefined) return false;
    if (!('Signature' in value) || value['Signature'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'ApplicationScopedId': json['ApplicationScopedId'],
        'Signature': json['Signature'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsToJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsToJSONTyped(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ApplicationScopedId': value['ApplicationScopedId'],
        'Signature': value['Signature'],
    };
}

