/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions
     */
    WebFlow: boolean;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions(value: object): value is FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions {
    if (!('WebFlow' in value) || value['WebFlow'] === undefined) return false;
    return true;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'WebFlow': json['WebFlow'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsToJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'WebFlow': value['WebFlow'],
    };
}

