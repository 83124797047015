/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements';
import {
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements(json)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements);
    }

    return {};
}

