/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthCreateTempTokenRequest
 */
export interface FxIdWebFeaturesAuthCreateTempTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthCreateTempTokenRequest
     */
    RedirectUri: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthCreateTempTokenRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthCreateTempTokenRequest(value: object): value is FxIdWebFeaturesAuthCreateTempTokenRequest {
    if (!('RedirectUri' in value) || value['RedirectUri'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthCreateTempTokenRequestFromJSON(json: any): FxIdWebFeaturesAuthCreateTempTokenRequest {
    return FxIdWebFeaturesAuthCreateTempTokenRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthCreateTempTokenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthCreateTempTokenRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'RedirectUri': json['RedirectUri'],
    };
}

export function FxIdWebFeaturesAuthCreateTempTokenRequestToJSON(json: any): FxIdWebFeaturesAuthCreateTempTokenRequest {
    return FxIdWebFeaturesAuthCreateTempTokenRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthCreateTempTokenRequestToJSONTyped(value?: FxIdWebFeaturesAuthCreateTempTokenRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'RedirectUri': value['RedirectUri'],
    };
}

