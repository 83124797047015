/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsTwitchAuthOptions
 */
export interface FxIdDomainSettingsTwitchAuthOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsTwitchAuthOptions
     */
    ClientId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsTwitchAuthOptions
     */
    RedirectUri: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsTwitchAuthOptions interface.
 */
export function instanceOfFxIdDomainSettingsTwitchAuthOptions(value: object): value is FxIdDomainSettingsTwitchAuthOptions {
    if (!('ClientId' in value) || value['ClientId'] === undefined) return false;
    if (!('RedirectUri' in value) || value['RedirectUri'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsTwitchAuthOptionsFromJSON(json: any): FxIdDomainSettingsTwitchAuthOptions {
    return FxIdDomainSettingsTwitchAuthOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsTwitchAuthOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsTwitchAuthOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'ClientId': json['ClientId'],
        'RedirectUri': json['RedirectUri'],
    };
}

export function FxIdDomainSettingsTwitchAuthOptionsToJSON(json: any): FxIdDomainSettingsTwitchAuthOptions {
    return FxIdDomainSettingsTwitchAuthOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsTwitchAuthOptionsToJSONTyped(value?: FxIdDomainSettingsTwitchAuthOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ClientId': value['ClientId'],
        'RedirectUri': value['RedirectUri'],
    };
}

