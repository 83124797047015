/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownload } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownload';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadToJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadToJSONTyped,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownload';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSONTyped,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent';

/**
 * 
 * @export
 * @interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
 */
export interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration {
    /**
     * 
     * @type {Date}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    CreatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    UpdatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    StartMenuName: string;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    LaunchFile: string;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    InstallerFile: string;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    LaunchParams: string;
    /**
     * 
     * @type {any}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    ConfigurationBucket: any | null;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    Torrent: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownload}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    HttpDownload: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownload;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    ManifestUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    ManifestSha256: string;
}

/**
 * Check if a given object implements the FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration interface.
 */
export function instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration(value: object): value is FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration {
    if (!('CreatedAt' in value) || value['CreatedAt'] === undefined) return false;
    if (!('UpdatedAt' in value) || value['UpdatedAt'] === undefined) return false;
    if (!('Name' in value) || value['Name'] === undefined) return false;
    if (!('StartMenuName' in value) || value['StartMenuName'] === undefined) return false;
    if (!('LaunchFile' in value) || value['LaunchFile'] === undefined) return false;
    if (!('InstallerFile' in value) || value['InstallerFile'] === undefined) return false;
    if (!('LaunchParams' in value) || value['LaunchParams'] === undefined) return false;
    if (!('ConfigurationBucket' in value) || value['ConfigurationBucket'] === undefined) return false;
    if (!('Torrent' in value) || value['Torrent'] === undefined) return false;
    if (!('HttpDownload' in value) || value['HttpDownload'] === undefined) return false;
    if (!('ManifestUrl' in value) || value['ManifestUrl'] === undefined) return false;
    if (!('ManifestSha256' in value) || value['ManifestSha256'] === undefined) return false;
    return true;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration {
    if (json == null) {
        return json;
    }
    return {
        
        'CreatedAt': (new Date(json['createdAt'])),
        'UpdatedAt': (new Date(json['updatedAt'])),
        'Name': json['name'],
        'StartMenuName': json['startMenuName'],
        'LaunchFile': json['launchFile'],
        'InstallerFile': json['installerFile'],
        'LaunchParams': json['launchParams'],
        'ConfigurationBucket': json['configurationBucket'],
        'Torrent': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSON(json['torrent']),
        'HttpDownload': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadFromJSON(json['httpDownload']),
        'ManifestUrl': json['manifestUrl'],
        'ManifestSha256': json['manifestSha256'],
    };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationToJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationToJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationToJSONTyped(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'createdAt': ((value['CreatedAt']).toISOString()),
        'updatedAt': ((value['UpdatedAt']).toISOString()),
        'name': value['Name'],
        'startMenuName': value['StartMenuName'],
        'launchFile': value['LaunchFile'],
        'installerFile': value['InstallerFile'],
        'launchParams': value['LaunchParams'],
        'configurationBucket': value['ConfigurationBucket'],
        'torrent': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSON(value['Torrent']),
        'httpDownload': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultHttpDownloadToJSON(value['HttpDownload']),
        'manifestUrl': value['ManifestUrl'],
        'manifestSha256': value['ManifestSha256'],
    };
}

