/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdWebFeaturesProfileGetResponseFeature {
    None = 'None',
    ListGames = 'ListGames',
    UnknownDefaultOpenApi = '11184809'
}


export function instanceOfFxIdWebFeaturesProfileGetResponseFeature(value: any): boolean {
    for (const key in FxIdWebFeaturesProfileGetResponseFeature) {
        if (Object.prototype.hasOwnProperty.call(FxIdWebFeaturesProfileGetResponseFeature, key)) {
            if (FxIdWebFeaturesProfileGetResponseFeature[key as keyof typeof FxIdWebFeaturesProfileGetResponseFeature] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FxIdWebFeaturesProfileGetResponseFeatureFromJSON(json: any): FxIdWebFeaturesProfileGetResponseFeature {
    return FxIdWebFeaturesProfileGetResponseFeatureFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetResponseFeatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileGetResponseFeature {
    return json as FxIdWebFeaturesProfileGetResponseFeature;
}

export function FxIdWebFeaturesProfileGetResponseFeatureToJSON(value?: FxIdWebFeaturesProfileGetResponseFeature | null): any {
    return value as any;
}

export function FxIdWebFeaturesProfileGetResponseFeatureToJSONTyped(value: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileGetResponseFeature {
    return value as FxIdWebFeaturesProfileGetResponseFeature;
}

