/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClass } from './FxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClass';
import {
    instanceOfFxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClass,
    FxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClassFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClassFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClassToJSON,
} from './FxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClass';

/**
 * @type FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData = FxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClass;

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData {
    return FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClass(json)) {
        return FxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClassFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClass(value)) {
        return FxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClassToJSON(value as FxIdWebFeaturesAuthAuthenticateFromGameNotConnectedDataClass);
    }

    return {};
}

