/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuth } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuth';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuthFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuthFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuthToJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuthToJSONTyped,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuth';

/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptions
 */
export interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptions
     */
    QueryString: string;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuth}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptions
     */
    UserAuth?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuth | null;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptions interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptions(value: object): value is FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptions {
    if (!('QueryString' in value) || value['QueryString'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'QueryString': json['QueryString'],
        'UserAuth': json['UserAuth'] == null ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuthFromJSON(json['UserAuth']),
    };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsToJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsToJSONTyped(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'QueryString': value['QueryString'],
        'UserAuth': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuthToJSON(value['UserAuth']),
    };
}

