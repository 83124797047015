/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
 */
export interface FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
     */
    FirstName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
     */
    LastName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
     */
    NickName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
     */
    PhotoUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
     */
    Email?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData(value: object): value is FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData {
    if (!('FirstName' in value) || value['FirstName'] === undefined) return false;
    if (!('LastName' in value) || value['LastName'] === undefined) return false;
    if (!('NickName' in value) || value['NickName'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData {
    return FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData {
    if (json == null) {
        return json;
    }
    return {
        
        'FirstName': json['FirstName'],
        'LastName': json['LastName'],
        'NickName': json['NickName'],
        'PhotoUri': json['PhotoUri'] == null ? undefined : json['PhotoUri'],
        'Email': json['Email'] == null ? undefined : json['Email'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData {
    return FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'FirstName': value['FirstName'],
        'LastName': value['LastName'],
        'NickName': value['NickName'],
        'PhotoUri': value['PhotoUri'],
        'Email': value['Email'],
    };
}

