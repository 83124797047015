/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesPlayGameIframeGameConfigOptions } from './FxIdWebFeaturesPlayGameIframeGameConfigOptions';
import {
    instanceOfFxIdWebFeaturesPlayGameIframeGameConfigOptions,
    FxIdWebFeaturesPlayGameIframeGameConfigOptionsFromJSON,
    FxIdWebFeaturesPlayGameIframeGameConfigOptionsFromJSONTyped,
    FxIdWebFeaturesPlayGameIframeGameConfigOptionsToJSON,
} from './FxIdWebFeaturesPlayGameIframeGameConfigOptions';

/**
 * @type FxIdWebFeaturesPlayGameResponseIframeGame
 * 
 * @export
 */
export type FxIdWebFeaturesPlayGameResponseIframeGame = FxIdWebFeaturesPlayGameIframeGameConfigOptions;

export function FxIdWebFeaturesPlayGameResponseIframeGameFromJSON(json: any): FxIdWebFeaturesPlayGameResponseIframeGame {
    return FxIdWebFeaturesPlayGameResponseIframeGameFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseIframeGameFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayGameResponseIframeGame {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesPlayGameIframeGameConfigOptions(json)) {
        return FxIdWebFeaturesPlayGameIframeGameConfigOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesPlayGameResponseIframeGameToJSON(json: any): any {
    return FxIdWebFeaturesPlayGameResponseIframeGameToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseIframeGameToJSONTyped(value?: FxIdWebFeaturesPlayGameResponseIframeGame | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesPlayGameIframeGameConfigOptions(value)) {
        return FxIdWebFeaturesPlayGameIframeGameConfigOptionsToJSON(value as FxIdWebFeaturesPlayGameIframeGameConfigOptions);
    }

    return {};
}

