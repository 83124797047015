/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference } from './FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference';
import {
    instanceOfFxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference,
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceToJSON,
} from './FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference';

/**
 * @type FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreference
 * Provides a hint to the user agent indicating what configuration of GPU is suitable for this WebGL context. This may influence which GPU is used in a system with multiple GPUs. For example, a dual-GPU system might have one GPU that consumes less power at the expense of rendering performance. Note that this property is only a hint and a WebGL implementation may choose to ignore it. WebGL implementations use context lost and restored events to regulate power and memory consumption, regardless of the value of this attribute.
 * The value expected to be 0 for default, 1 for low-power or 2 for high-performance. See the following enum:
 * 0: Default
 * 1: Low Power
 * 2: High Performance
 * @export
 */
export type FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreference = FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference;

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreference {
    return FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreference {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference(json)) {
        return FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceToJSON(json: any): any {
    return FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceToJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreferenceToJSONTyped(value?: FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptsPowerPreference | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference(value)) {
        return FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceToJSON(value as FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference);
    }

    return {};
}

