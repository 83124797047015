/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenance } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenance';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenanceFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenanceFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenanceToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenanceToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenance';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions {
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenance}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Maintenance?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenance | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    MinimalRequirements?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    RecommendedRequirements?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Oauth?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    BugReport?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Update?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate | null;
    /**
     * 
     * @type {Array<FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer>}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Servers?: Array<FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer> | null;
    /**
     * Включение дополнительных интрефейсов для разработчиков
     * @type {boolean}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    DevelopmentMode?: boolean | null;
    /**
     * Диплинка для запуска лончера
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    UrlScheme?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    LaunchParams?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Links?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Socials?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions {
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'Maintenance': json['Maintenance'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenanceFromJSON(json['Maintenance']),
        'MinimalRequirements': json['MinimalRequirements'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSON(json['MinimalRequirements']),
        'RecommendedRequirements': json['RecommendedRequirements'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSON(json['RecommendedRequirements']),
        'Oauth': json['Oauth'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSON(json['Oauth']),
        'BugReport': json['BugReport'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSON(json['BugReport']),
        'Update': json['Update'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateFromJSON(json['Update']),
        'Servers': json['Servers'] == null ? undefined : ((json['Servers'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerFromJSON)),
        'DevelopmentMode': json['DevelopmentMode'] == null ? undefined : json['DevelopmentMode'],
        'UrlScheme': json['UrlScheme'] == null ? undefined : json['UrlScheme'],
        'LaunchParams': json['LaunchParams'] == null ? undefined : json['LaunchParams'],
        'Links': json['Links'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksFromJSON(json['Links']),
        'Socials': json['Socials'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsFromJSON(json['Socials']),
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Maintenance': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenanceToJSON(value['Maintenance']),
        'MinimalRequirements': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsToJSON(value['MinimalRequirements']),
        'RecommendedRequirements': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsToJSON(value['RecommendedRequirements']),
        'Oauth': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthToJSON(value['Oauth']),
        'BugReport': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportToJSON(value['BugReport']),
        'Update': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateToJSON(value['Update']),
        'Servers': value['Servers'] == null ? undefined : ((value['Servers'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerToJSON)),
        'DevelopmentMode': value['DevelopmentMode'],
        'UrlScheme': value['UrlScheme'],
        'LaunchParams': value['LaunchParams'],
        'Links': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksToJSON(value['Links']),
        'Socials': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsToJSON(value['Socials']),
    };
}

