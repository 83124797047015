/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions {
    /**
     * Перечень окружений в которых доступна игра
     * @type {Array<string>}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions
     */
    Envs?: Array<string> | null;
    /**
     * Доступна ли игра в браузере
     * @type {boolean}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions
     */
    Web?: boolean | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions {
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'Envs': json['Envs'] == null ? undefined : json['Envs'],
        'Web': json['Web'] == null ? undefined : json['Web'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Envs': value['Envs'],
        'Web': value['Web'],
    };
}

