/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions';
import {
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions;

export function FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames {
    return FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions(json)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesToJSON(json: any): any {
    return FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesToJSONTyped(value?: FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions);
    }

    return {};
}

