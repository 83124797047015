/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesInviteUpdateConnectGameInviteRequest
 */
export interface FxIdWebFeaturesInviteUpdateConnectGameInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteUpdateConnectGameInviteRequest
     */
    Token: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteUpdateConnectGameInviteRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteUpdateConnectGameInviteRequest
     */
    EmailInviteToken: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesInviteUpdateConnectGameInviteRequest
     */
    MarketingEnabled: boolean;
}

/**
 * Check if a given object implements the FxIdWebFeaturesInviteUpdateConnectGameInviteRequest interface.
 */
export function instanceOfFxIdWebFeaturesInviteUpdateConnectGameInviteRequest(value: object): value is FxIdWebFeaturesInviteUpdateConnectGameInviteRequest {
    if (!('Token' in value) || value['Token'] === undefined) return false;
    if (!('Game' in value) || value['Game'] === undefined) return false;
    if (!('EmailInviteToken' in value) || value['EmailInviteToken'] === undefined) return false;
    if (!('MarketingEnabled' in value) || value['MarketingEnabled'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesInviteUpdateConnectGameInviteRequestFromJSON(json: any): FxIdWebFeaturesInviteUpdateConnectGameInviteRequest {
    return FxIdWebFeaturesInviteUpdateConnectGameInviteRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteUpdateConnectGameInviteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInviteUpdateConnectGameInviteRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'Token': json['Token'],
        'Game': json['Game'],
        'EmailInviteToken': json['EmailInviteToken'],
        'MarketingEnabled': json['MarketingEnabled'],
    };
}

export function FxIdWebFeaturesInviteUpdateConnectGameInviteRequestToJSON(json: any): FxIdWebFeaturesInviteUpdateConnectGameInviteRequest {
    return FxIdWebFeaturesInviteUpdateConnectGameInviteRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteUpdateConnectGameInviteRequestToJSONTyped(value?: FxIdWebFeaturesInviteUpdateConnectGameInviteRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Token': value['Token'],
        'Game': value['Game'],
        'EmailInviteToken': value['EmailInviteToken'],
        'MarketingEnabled': value['MarketingEnabled'],
    };
}

