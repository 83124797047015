/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsUnityOptionsDistributionComponentOptions
 */
export interface FxIdDomainSettingsUnityOptionsDistributionComponentOptions {
    /**
     * Если установлен то devicePixelRatio будет переопредлен в это значение
     * @type {number}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionComponentOptions
     */
    DevicePixelRatio?: number | null;
    /**
     * Если установлен то devicePixelRatio будет автоматически устанавливаться в текущее значение в браузере.
     * рекомендуется поставить в true. Не работает если включен DevicePixelRatio
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionComponentOptions
     */
    AutomaticDevicePixelRatio?: boolean | null;
    /**
     * obsolete
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionComponentOptions
     */
    MatchWebGLToCanvasSize: boolean;
}

/**
 * Check if a given object implements the FxIdDomainSettingsUnityOptionsDistributionComponentOptions interface.
 */
export function instanceOfFxIdDomainSettingsUnityOptionsDistributionComponentOptions(value: object): value is FxIdDomainSettingsUnityOptionsDistributionComponentOptions {
    if (!('MatchWebGLToCanvasSize' in value) || value['MatchWebGLToCanvasSize'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsUnityOptionsDistributionComponentOptionsFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionComponentOptions {
    return FxIdDomainSettingsUnityOptionsDistributionComponentOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionComponentOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionComponentOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'DevicePixelRatio': json['DevicePixelRatio'] == null ? undefined : json['DevicePixelRatio'],
        'AutomaticDevicePixelRatio': json['AutomaticDevicePixelRatio'] == null ? undefined : json['AutomaticDevicePixelRatio'],
        'MatchWebGLToCanvasSize': json['MatchWebGLToCanvasSize'],
    };
}

export function FxIdDomainSettingsUnityOptionsDistributionComponentOptionsToJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionComponentOptions {
    return FxIdDomainSettingsUnityOptionsDistributionComponentOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionComponentOptionsToJSONTyped(value?: FxIdDomainSettingsUnityOptionsDistributionComponentOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'DevicePixelRatio': value['DevicePixelRatio'],
        'AutomaticDevicePixelRatio': value['AutomaticDevicePixelRatio'],
        'MatchWebGLToCanvasSize': value['MatchWebGLToCanvasSize'],
    };
}

