/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo
 */
export interface FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo
     */
    Description: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo
     */
    Price: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo interface.
 */
export function instanceOfFxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo(value: object): value is FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo {
    if (!('Name' in value) || value['Name'] === undefined) return false;
    if (!('Description' in value) || value['Description'] === undefined) return false;
    if (!('Price' in value) || value['Price'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfoFromJSON(json: any): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo {
    return FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfoFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'Name': json['Name'],
        'Description': json['Description'],
        'Price': json['Price'],
    };
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfoToJSON(json: any): FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo {
    return FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfoToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfoToJSONTyped(value?: FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Name': value['Name'],
        'Description': value['Description'],
        'Price': value['Price'],
    };
}

