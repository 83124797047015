/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayGameUnityGameConfigOptions
 */
export interface FxIdWebFeaturesPlayGameUnityGameConfigOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameUnityGameConfigOptions
     */
    BaseUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameUnityGameConfigOptions
     */
    BaseBuildUrl: string;
    /**
     * 
     * @type {any}
     * @memberof FxIdWebFeaturesPlayGameUnityGameConfigOptions
     */
    UnityBuildJson?: any | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayGameUnityGameConfigOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayGameUnityGameConfigOptions(value: object): value is FxIdWebFeaturesPlayGameUnityGameConfigOptions {
    if (!('BaseUrl' in value) || value['BaseUrl'] === undefined) return false;
    if (!('BaseBuildUrl' in value) || value['BaseBuildUrl'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesPlayGameUnityGameConfigOptionsFromJSON(json: any): FxIdWebFeaturesPlayGameUnityGameConfigOptions {
    return FxIdWebFeaturesPlayGameUnityGameConfigOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameUnityGameConfigOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayGameUnityGameConfigOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'BaseUrl': json['BaseUrl'],
        'BaseBuildUrl': json['BaseBuildUrl'],
        'UnityBuildJson': json['UnityBuildJson'] == null ? undefined : json['UnityBuildJson'],
    };
}

export function FxIdWebFeaturesPlayGameUnityGameConfigOptionsToJSON(json: any): FxIdWebFeaturesPlayGameUnityGameConfigOptions {
    return FxIdWebFeaturesPlayGameUnityGameConfigOptionsToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameUnityGameConfigOptionsToJSONTyped(value?: FxIdWebFeaturesPlayGameUnityGameConfigOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'BaseUrl': value['BaseUrl'],
        'BaseBuildUrl': value['BaseBuildUrl'],
        'UnityBuildJson': value['UnityBuildJson'],
    };
}

