/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPresentationOptions } from './FxIdDomainSettingsPresentationOptions';
import {
    instanceOfFxIdDomainSettingsPresentationOptions,
    FxIdDomainSettingsPresentationOptionsFromJSON,
    FxIdDomainSettingsPresentationOptionsFromJSONTyped,
    FxIdDomainSettingsPresentationOptionsToJSON,
} from './FxIdDomainSettingsPresentationOptions';

/**
 * @type FxIdWebFeaturesPlayPublicDataBasePresentation
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPublicDataBasePresentation = FxIdDomainSettingsPresentationOptions;

export function FxIdWebFeaturesPlayPublicDataBasePresentationFromJSON(json: any): FxIdWebFeaturesPlayPublicDataBasePresentation {
    return FxIdWebFeaturesPlayPublicDataBasePresentationFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBasePresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPublicDataBasePresentation {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPresentationOptions(json)) {
        return FxIdDomainSettingsPresentationOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesPlayPublicDataBasePresentationToJSON(json: any): any {
    return FxIdWebFeaturesPlayPublicDataBasePresentationToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBasePresentationToJSONTyped(value?: FxIdWebFeaturesPlayPublicDataBasePresentation | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPresentationOptions(value)) {
        return FxIdDomainSettingsPresentationOptionsToJSON(value as FxIdDomainSettingsPresentationOptions);
    }

    return {};
}

