/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest
 */
export interface FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest
     */
    OriginUrl: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest interface.
 */
export function instanceOfFxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest(value: object): value is FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest {
    if (!('OriginUrl' in value) || value['OriginUrl'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestFromJSON(json: any): FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest {
    return FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'OriginUrl': json['OriginUrl'],
    };
}

export function FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestToJSON(json: any): FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest {
    return FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestToJSONTyped(value?: FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'OriginUrl': value['OriginUrl'],
    };
}

