/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions';

/**
 * @type FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLogin
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLogin = FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions;

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLogin {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLogin {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions(json)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLogin | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions);
    }

    return {};
}

