/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayGameResponseLaunchDataOptions
 */
export interface FxIdWebFeaturesPlayGameResponseLaunchDataOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameResponseLaunchDataOptions
     */
    AppendToQuery?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayGameResponseLaunchDataOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayGameResponseLaunchDataOptions(value: object): value is FxIdWebFeaturesPlayGameResponseLaunchDataOptions {
    return true;
}

export function FxIdWebFeaturesPlayGameResponseLaunchDataOptionsFromJSON(json: any): FxIdWebFeaturesPlayGameResponseLaunchDataOptions {
    return FxIdWebFeaturesPlayGameResponseLaunchDataOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseLaunchDataOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayGameResponseLaunchDataOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'AppendToQuery': json['AppendToQuery'] == null ? undefined : json['AppendToQuery'],
    };
}

export function FxIdWebFeaturesPlayGameResponseLaunchDataOptionsToJSON(json: any): FxIdWebFeaturesPlayGameResponseLaunchDataOptions {
    return FxIdWebFeaturesPlayGameResponseLaunchDataOptionsToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseLaunchDataOptionsToJSONTyped(value?: FxIdWebFeaturesPlayGameResponseLaunchDataOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AppendToQuery': value['AppendToQuery'],
    };
}

