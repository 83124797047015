/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest
     */
    GameSystemName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest
     */
    Token: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest
     */
    MarketingEnabled?: boolean | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromFirebaseRequest(value: object): value is FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest {
    if (!('Token' in value) || value['Token'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest {
    return FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'GameSystemName': json['GameSystemName'] == null ? undefined : json['GameSystemName'],
        'Token': json['Token'],
        'MarketingEnabled': json['MarketingEnabled'] == null ? undefined : json['MarketingEnabled'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest {
    return FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameSystemName': value['GameSystemName'],
        'Token': value['Token'],
        'MarketingEnabled': value['MarketingEnabled'],
    };
}

