/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptions } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptions';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptions';

/**
 * @type FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLogin
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLogin = FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptions;

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLogin {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLogin {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptions(json)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLogin | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginOptions);
    }

    return {};
}

