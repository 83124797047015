/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsFacebookPublicAuthOptions
 */
export interface FxIdDomainSettingsFacebookPublicAuthOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsFacebookPublicAuthOptions
     */
    AppId: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsFacebookPublicAuthOptions interface.
 */
export function instanceOfFxIdDomainSettingsFacebookPublicAuthOptions(value: object): value is FxIdDomainSettingsFacebookPublicAuthOptions {
    if (!('AppId' in value) || value['AppId'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsFacebookPublicAuthOptionsFromJSON(json: any): FxIdDomainSettingsFacebookPublicAuthOptions {
    return FxIdDomainSettingsFacebookPublicAuthOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsFacebookPublicAuthOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsFacebookPublicAuthOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'AppId': json['AppId'],
    };
}

export function FxIdDomainSettingsFacebookPublicAuthOptionsToJSON(json: any): FxIdDomainSettingsFacebookPublicAuthOptions {
    return FxIdDomainSettingsFacebookPublicAuthOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsFacebookPublicAuthOptionsToJSONTyped(value?: FxIdDomainSettingsFacebookPublicAuthOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AppId': value['AppId'],
    };
}

