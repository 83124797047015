/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData } from './FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData';
import {
    instanceOfFxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataFromJSON,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataFromJSONTyped,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataToJSON,
} from './FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData';

/**
 * @type FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseData
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseData = FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData;

export function FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseDataFromJSON(json: any): FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseData {
    return FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseDataFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseData {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData(json)) {
        return FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseDataToJSON(json: any): any {
    return FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseDataToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseDataToJSONTyped(value?: FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData(value)) {
        return FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataToJSON(value as FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData);
    }

    return {};
}

