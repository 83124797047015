/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsAdvertisementFbInstantOptions } from './FxIdDomainSettingsAdvertisementFbInstantOptions';
import {
    instanceOfFxIdDomainSettingsAdvertisementFbInstantOptions,
    FxIdDomainSettingsAdvertisementFbInstantOptionsFromJSON,
    FxIdDomainSettingsAdvertisementFbInstantOptionsFromJSONTyped,
    FxIdDomainSettingsAdvertisementFbInstantOptionsToJSON,
} from './FxIdDomainSettingsAdvertisementFbInstantOptions';

/**
 * @type FxIdDomainSettingsAdvertisementOptionsFacebookInstantGames
 * 
 * @export
 */
export type FxIdDomainSettingsAdvertisementOptionsFacebookInstantGames = FxIdDomainSettingsAdvertisementFbInstantOptions;

export function FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesFromJSON(json: any): FxIdDomainSettingsAdvertisementOptionsFacebookInstantGames {
    return FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementOptionsFacebookInstantGames {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsAdvertisementFbInstantOptions(json)) {
        return FxIdDomainSettingsAdvertisementFbInstantOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesToJSON(json: any): any {
    return FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesToJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementOptionsFacebookInstantGamesToJSONTyped(value?: FxIdDomainSettingsAdvertisementOptionsFacebookInstantGames | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsAdvertisementFbInstantOptions(value)) {
        return FxIdDomainSettingsAdvertisementFbInstantOptionsToJSON(value as FxIdDomainSettingsAdvertisementFbInstantOptions);
    }

    return {};
}

