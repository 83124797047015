/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsAdvertisementFbInstantInterstitialOptions } from './FxIdDomainSettingsAdvertisementFbInstantInterstitialOptions';
import {
    instanceOfFxIdDomainSettingsAdvertisementFbInstantInterstitialOptions,
    FxIdDomainSettingsAdvertisementFbInstantInterstitialOptionsFromJSON,
    FxIdDomainSettingsAdvertisementFbInstantInterstitialOptionsFromJSONTyped,
    FxIdDomainSettingsAdvertisementFbInstantInterstitialOptionsToJSON,
} from './FxIdDomainSettingsAdvertisementFbInstantInterstitialOptions';

/**
 * @type FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial
 * 
 * @export
 */
export type FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial = FxIdDomainSettingsAdvertisementFbInstantInterstitialOptions;

export function FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialFromJSON(json: any): FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial {
    return FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsAdvertisementFbInstantInterstitialOptions(json)) {
        return FxIdDomainSettingsAdvertisementFbInstantInterstitialOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialToJSON(json: any): any {
    return FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialToJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialToJSONTyped(value?: FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsAdvertisementFbInstantInterstitialOptions(value)) {
        return FxIdDomainSettingsAdvertisementFbInstantInterstitialOptionsToJSON(value as FxIdDomainSettingsAdvertisementFbInstantInterstitialOptions);
    }

    return {};
}

