/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData';
import {
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData(json)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQToJSON(json: any): any {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQToJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQToJSONTyped(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData);
    }

    return {};
}

