/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainEntitiesAwGameDataViewModel } from './FxIdDomainEntitiesAwGameDataViewModel';
import {
    instanceOfFxIdDomainEntitiesAwGameDataViewModel,
    FxIdDomainEntitiesAwGameDataViewModelFromJSON,
    FxIdDomainEntitiesAwGameDataViewModelFromJSONTyped,
    FxIdDomainEntitiesAwGameDataViewModelToJSON,
} from './FxIdDomainEntitiesAwGameDataViewModel';

/**
 * @type FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData
 * 
 * @export
 */
export type FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData = FxIdDomainEntitiesAwGameDataViewModel;

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataFromJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainEntitiesAwGameDataViewModel(json)) {
        return FxIdDomainEntitiesAwGameDataViewModelFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataToJSON(json: any): any {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataToJSONTyped(value?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainEntitiesAwGameDataViewModel(value)) {
        return FxIdDomainEntitiesAwGameDataViewModelToJSON(value as FxIdDomainEntitiesAwGameDataViewModel);
    }

    return {};
}

