/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesStoreStoreDataResponseStoreMeta } from './FxIdWebFeaturesStoreStoreDataResponseStoreMeta';
import {
    FxIdWebFeaturesStoreStoreDataResponseStoreMetaFromJSON,
    FxIdWebFeaturesStoreStoreDataResponseStoreMetaFromJSONTyped,
    FxIdWebFeaturesStoreStoreDataResponseStoreMetaToJSON,
    FxIdWebFeaturesStoreStoreDataResponseStoreMetaToJSONTyped,
} from './FxIdWebFeaturesStoreStoreDataResponseStoreMeta';
import type { FxIdWebFeaturesStoreStoreDataResponseProductWithPrice } from './FxIdWebFeaturesStoreStoreDataResponseProductWithPrice';
import {
    FxIdWebFeaturesStoreStoreDataResponseProductWithPriceFromJSON,
    FxIdWebFeaturesStoreStoreDataResponseProductWithPriceFromJSONTyped,
    FxIdWebFeaturesStoreStoreDataResponseProductWithPriceToJSON,
    FxIdWebFeaturesStoreStoreDataResponseProductWithPriceToJSONTyped,
} from './FxIdWebFeaturesStoreStoreDataResponseProductWithPrice';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreStoreDataResponse
 */
export interface FxIdWebFeaturesStoreStoreDataResponse {
    /**
     * 
     * @type {Array<FxIdWebFeaturesStoreStoreDataResponseProductWithPrice>}
     * @memberof FxIdWebFeaturesStoreStoreDataResponse
     */
    Products: Array<FxIdWebFeaturesStoreStoreDataResponseProductWithPrice>;
    /**
     * 
     * @type {FxIdWebFeaturesStoreStoreDataResponseStoreMeta}
     * @memberof FxIdWebFeaturesStoreStoreDataResponse
     */
    Meta: FxIdWebFeaturesStoreStoreDataResponseStoreMeta;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreStoreDataResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreStoreDataResponse(value: object): value is FxIdWebFeaturesStoreStoreDataResponse {
    if (!('Products' in value) || value['Products'] === undefined) return false;
    if (!('Meta' in value) || value['Meta'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesStoreStoreDataResponseFromJSON(json: any): FxIdWebFeaturesStoreStoreDataResponse {
    return FxIdWebFeaturesStoreStoreDataResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreStoreDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreStoreDataResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Products': ((json['Products'] as Array<any>).map(FxIdWebFeaturesStoreStoreDataResponseProductWithPriceFromJSON)),
        'Meta': FxIdWebFeaturesStoreStoreDataResponseStoreMetaFromJSON(json['Meta']),
    };
}

export function FxIdWebFeaturesStoreStoreDataResponseToJSON(json: any): FxIdWebFeaturesStoreStoreDataResponse {
    return FxIdWebFeaturesStoreStoreDataResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreStoreDataResponseToJSONTyped(value?: FxIdWebFeaturesStoreStoreDataResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Products': ((value['Products'] as Array<any>).map(FxIdWebFeaturesStoreStoreDataResponseProductWithPriceToJSON)),
        'Meta': FxIdWebFeaturesStoreStoreDataResponseStoreMetaToJSON(value['Meta']),
    };
}

