/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultToJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultToJSONTyped,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse
 */
export interface FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse {
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult}
     * @memberof FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse
     */
    Manifest: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse
     */
    AvailableBranches: Array<string>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse interface.
 */
export function instanceOfFxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse(value: object): value is FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse {
    if (!('Manifest' in value) || value['Manifest'] === undefined) return false;
    if (!('AvailableBranches' in value) || value['AvailableBranches'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseFromJSON(json: any): FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse {
    return FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Manifest': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSON(json['Manifest']),
        'AvailableBranches': json['AvailableBranches'],
    };
}

export function FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseToJSON(json: any): FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse {
    return FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseToJSONTyped(value?: FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Manifest': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultToJSON(value['Manifest']),
        'AvailableBranches': value['AvailableBranches'],
    };
}

