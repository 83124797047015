/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptions } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptions';
import {
    instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptions,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptionsFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptionsFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptionsToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptions';

/**
 * @type FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptions
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptions = FxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptions;

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptions {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptions {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptions(json)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptionsToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestAdditionalOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptions(value)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptionsToJSON(value as FxIdWebFeaturesAuthAuthenticateWithExternalIdAdditionalOptions);
    }

    return {};
}

