/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainEntitiesOrderDeveloperPayload
 */
export interface FxIdDomainEntitiesOrderDeveloperPayload {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainEntitiesOrderDeveloperPayload
     */
    MerchantDeveloperPayload?: string | null;
}

/**
 * Check if a given object implements the FxIdDomainEntitiesOrderDeveloperPayload interface.
 */
export function instanceOfFxIdDomainEntitiesOrderDeveloperPayload(value: object): value is FxIdDomainEntitiesOrderDeveloperPayload {
    return true;
}

export function FxIdDomainEntitiesOrderDeveloperPayloadFromJSON(json: any): FxIdDomainEntitiesOrderDeveloperPayload {
    return FxIdDomainEntitiesOrderDeveloperPayloadFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesOrderDeveloperPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesOrderDeveloperPayload {
    if (json == null) {
        return json;
    }
    return {
        
        'MerchantDeveloperPayload': json['merchant_developer_payload'] == null ? undefined : json['merchant_developer_payload'],
    };
}

export function FxIdDomainEntitiesOrderDeveloperPayloadToJSON(json: any): FxIdDomainEntitiesOrderDeveloperPayload {
    return FxIdDomainEntitiesOrderDeveloperPayloadToJSONTyped(json, false);
}

export function FxIdDomainEntitiesOrderDeveloperPayloadToJSONTyped(value?: FxIdDomainEntitiesOrderDeveloperPayload | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'merchant_developer_payload': value['MerchantDeveloperPayload'],
    };
}

