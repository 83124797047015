/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData } from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData';
import {
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData';

/**
 * @type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLogin
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLogin = FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData;

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLogin {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLogin {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData(json)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLoginToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdAuthenticationRequestGoogleLogin | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthDataToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdServicesProvidersGoogleGoogleAuthData);
    }

    return {};
}

