/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer {
    /**
     * ссылука на сервер для игры
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer
     */
    Url: string;
    /**
     * ссылка на хелс чек
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer
     */
    HealthCheck: string;
    /**
     * название сервера для лончера
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer
     */
    Name: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer {
    if (!('Url' in value) || value['Url'] === undefined) return false;
    if (!('HealthCheck' in value) || value['HealthCheck'] === undefined) return false;
    if (!('Name' in value) || value['Name'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer {
    if (json == null) {
        return json;
    }
    return {
        
        'Url': json['Url'],
        'HealthCheck': json['HealthCheck'],
        'Name': json['Name'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Url': value['Url'],
        'HealthCheck': value['HealthCheck'],
        'Name': value['Name'],
    };
}

