/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesGamesChooseGamePortalConfig } from './FxIdWebFeaturesGamesChooseGamePortalConfig';
import {
    FxIdWebFeaturesGamesChooseGamePortalConfigFromJSON,
    FxIdWebFeaturesGamesChooseGamePortalConfigFromJSONTyped,
    FxIdWebFeaturesGamesChooseGamePortalConfigToJSON,
    FxIdWebFeaturesGamesChooseGamePortalConfigToJSONTyped,
} from './FxIdWebFeaturesGamesChooseGamePortalConfig';
import type { FxIdWebFeaturesGamesChooseGameResponsePublicGameData } from './FxIdWebFeaturesGamesChooseGameResponsePublicGameData';
import {
    FxIdWebFeaturesGamesChooseGameResponsePublicGameDataFromJSON,
    FxIdWebFeaturesGamesChooseGameResponsePublicGameDataFromJSONTyped,
    FxIdWebFeaturesGamesChooseGameResponsePublicGameDataToJSON,
    FxIdWebFeaturesGamesChooseGameResponsePublicGameDataToJSONTyped,
} from './FxIdWebFeaturesGamesChooseGameResponsePublicGameData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesGamesChooseGameResponse
 */
export interface FxIdWebFeaturesGamesChooseGameResponse {
    /**
     * 
     * @type {FxIdWebFeaturesGamesChooseGamePortalConfig}
     * @memberof FxIdWebFeaturesGamesChooseGameResponse
     */
    PortalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig;
    /**
     * 
     * @type {FxIdWebFeaturesGamesChooseGameResponsePublicGameData}
     * @memberof FxIdWebFeaturesGamesChooseGameResponse
     */
    PublicGameData?: FxIdWebFeaturesGamesChooseGameResponsePublicGameData | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesGamesChooseGameResponse interface.
 */
export function instanceOfFxIdWebFeaturesGamesChooseGameResponse(value: object): value is FxIdWebFeaturesGamesChooseGameResponse {
    if (!('PortalConfig' in value) || value['PortalConfig'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesGamesChooseGameResponseFromJSON(json: any): FxIdWebFeaturesGamesChooseGameResponse {
    return FxIdWebFeaturesGamesChooseGameResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesChooseGameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesGamesChooseGameResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'PortalConfig': FxIdWebFeaturesGamesChooseGamePortalConfigFromJSON(json['PortalConfig']),
        'PublicGameData': json['PublicGameData'] == null ? undefined : FxIdWebFeaturesGamesChooseGameResponsePublicGameDataFromJSON(json['PublicGameData']),
    };
}

export function FxIdWebFeaturesGamesChooseGameResponseToJSON(json: any): FxIdWebFeaturesGamesChooseGameResponse {
    return FxIdWebFeaturesGamesChooseGameResponseToJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesChooseGameResponseToJSONTyped(value?: FxIdWebFeaturesGamesChooseGameResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'PortalConfig': FxIdWebFeaturesGamesChooseGamePortalConfigToJSON(value['PortalConfig']),
        'PublicGameData': FxIdWebFeaturesGamesChooseGameResponsePublicGameDataToJSON(value['PublicGameData']),
    };
}

