/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions
 */
export interface FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions
     */
    AllowedTransferFromMyGames: boolean;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions(value: object): value is FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions {
    if (!('AllowedTransferFromMyGames' in value) || value['AllowedTransferFromMyGames'] === undefined) return false;
    return true;
}

export function FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptionsFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions {
    return FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'AllowedTransferFromMyGames': json['AllowedTransferFromMyGames'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptionsToJSON(json: any): FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions {
    return FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptionsToJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptionsToJSONTyped(value?: FxIdApplicationHandlersAuthAuthenticateWithExternalIdAuthenticateWithExternalIdResultTransferOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AllowedTransferFromMyGames': value['AllowedTransferFromMyGames'],
    };
}

