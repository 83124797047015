/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig';
import {
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfigFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfigFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfigToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig(json)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfigFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthToJSON(json: any): any {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfigToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig);
    }

    return {};
}

