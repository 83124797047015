/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemNetMailMailAddress
 */
export interface SystemNetMailMailAddress {
    /**
     * 
     * @type {string}
     * @memberof SystemNetMailMailAddress
     */
    DisplayName: string;
    /**
     * 
     * @type {string}
     * @memberof SystemNetMailMailAddress
     */
    User: string;
    /**
     * 
     * @type {string}
     * @memberof SystemNetMailMailAddress
     */
    Host: string;
    /**
     * 
     * @type {string}
     * @memberof SystemNetMailMailAddress
     */
    Address: string;
}

/**
 * Check if a given object implements the SystemNetMailMailAddress interface.
 */
export function instanceOfSystemNetMailMailAddress(value: object): value is SystemNetMailMailAddress {
    if (!('DisplayName' in value) || value['DisplayName'] === undefined) return false;
    if (!('User' in value) || value['User'] === undefined) return false;
    if (!('Host' in value) || value['Host'] === undefined) return false;
    if (!('Address' in value) || value['Address'] === undefined) return false;
    return true;
}

export function SystemNetMailMailAddressFromJSON(json: any): SystemNetMailMailAddress {
    return SystemNetMailMailAddressFromJSONTyped(json, false);
}

export function SystemNetMailMailAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemNetMailMailAddress {
    if (json == null) {
        return json;
    }
    return {
        
        'DisplayName': json['DisplayName'],
        'User': json['User'],
        'Host': json['Host'],
        'Address': json['Address'],
    };
}

export function SystemNetMailMailAddressToJSON(json: any): SystemNetMailMailAddress {
    return SystemNetMailMailAddressToJSONTyped(json, false);
}

export function SystemNetMailMailAddressToJSONTyped(value?: SystemNetMailMailAddress | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'DisplayName': value['DisplayName'],
        'User': value['User'],
        'Host': value['Host'],
        'Address': value['Address'],
    };
}

