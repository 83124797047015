/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethod } from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethod';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethod';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigPpUrlOpenMethod } from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigPpUrlOpenMethod';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigPpUrlOpenMethodFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigPpUrlOpenMethodFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigPpUrlOpenMethodToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigPpUrlOpenMethodToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigPpUrlOpenMethod';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue } from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue';

/**
 * Настройки для адаптеров (класс)
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig {
    /**
     * Название адаптера
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    IframeType: string;
    /**
     * путь к документации TOS
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    ExternalTosUrl?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethod}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    TosUrlOpenMethod: FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethod;
    /**
     * путь к документации PP
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    ExternalPpUrl?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigPpUrlOpenMethod}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    PpUrlOpenMethod: FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigPpUrlOpenMethod;
    /**
     * путь к документации контактов
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    ContactsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    HeaderLogo?: string | null;
    /**
     * контакты
     * @type {{ [key: string]: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue; }}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    LegalContacts?: { [key: string]: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue; } | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig {
    if (!('IframeType' in value) || value['IframeType'] === undefined) return false;
    if (!('TosUrlOpenMethod' in value) || value['TosUrlOpenMethod'] === undefined) return false;
    if (!('PpUrlOpenMethod' in value) || value['PpUrlOpenMethod'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'IframeType': json['IframeType'],
        'ExternalTosUrl': json['ExternalTosUrl'] == null ? undefined : json['ExternalTosUrl'],
        'TosUrlOpenMethod': FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodFromJSON(json['TosUrlOpenMethod']),
        'ExternalPpUrl': json['ExternalPpUrl'] == null ? undefined : json['ExternalPpUrl'],
        'PpUrlOpenMethod': FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigPpUrlOpenMethodFromJSON(json['PpUrlOpenMethod']),
        'ContactsUrl': json['ContactsUrl'] == null ? undefined : json['ContactsUrl'],
        'HeaderLogo': json['HeaderLogo'] == null ? undefined : json['HeaderLogo'],
        'LegalContacts': json['LegalContacts'] == null ? undefined : (mapValues(json['LegalContacts'], FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSON)),
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'IframeType': value['IframeType'],
        'ExternalTosUrl': value['ExternalTosUrl'],
        'TosUrlOpenMethod': FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigTosUrlOpenMethodToJSON(value['TosUrlOpenMethod']),
        'ExternalPpUrl': value['ExternalPpUrl'],
        'PpUrlOpenMethod': FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigPpUrlOpenMethodToJSON(value['PpUrlOpenMethod']),
        'ContactsUrl': value['ContactsUrl'],
        'HeaderLogo': value['HeaderLogo'],
        'LegalContacts': value['LegalContacts'] == null ? undefined : (mapValues(value['LegalContacts'], FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueToJSON)),
    };
}

