/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions
 */
export interface FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions
     */
    PackageName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions
     */
    AndroidClass?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions
     */
    AppLinkUrl: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions(value: object): value is FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions {
    if (!('PackageName' in value) || value['PackageName'] === undefined) return false;
    if (!('AppLinkUrl' in value) || value['AppLinkUrl'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptionsFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'PackageName': json['PackageName'],
        'AndroidClass': json['AndroidClass'] == null ? undefined : json['AndroidClass'],
        'AppLinkUrl': json['AppLinkUrl'],
    };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptionsToJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptionsToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptionsToJSONTyped(value?: FxIdWebFeaturesPlayPlatformDataOptionsAndroidOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'PackageName': value['PackageName'],
        'AndroidClass': value['AndroidClass'],
        'AppLinkUrl': value['AppLinkUrl'],
    };
}

