/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesPlayGameResponseLaunchDataOptions } from './FxIdWebFeaturesPlayGameResponseLaunchDataOptions';
import {
    instanceOfFxIdWebFeaturesPlayGameResponseLaunchDataOptions,
    FxIdWebFeaturesPlayGameResponseLaunchDataOptionsFromJSON,
    FxIdWebFeaturesPlayGameResponseLaunchDataOptionsFromJSONTyped,
    FxIdWebFeaturesPlayGameResponseLaunchDataOptionsToJSON,
} from './FxIdWebFeaturesPlayGameResponseLaunchDataOptions';

/**
 * @type FxIdWebFeaturesPlayGameResponseLaunchData
 * 
 * @export
 */
export type FxIdWebFeaturesPlayGameResponseLaunchData = FxIdWebFeaturesPlayGameResponseLaunchDataOptions;

export function FxIdWebFeaturesPlayGameResponseLaunchDataFromJSON(json: any): FxIdWebFeaturesPlayGameResponseLaunchData {
    return FxIdWebFeaturesPlayGameResponseLaunchDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseLaunchDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayGameResponseLaunchData {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesPlayGameResponseLaunchDataOptions(json)) {
        return FxIdWebFeaturesPlayGameResponseLaunchDataOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesPlayGameResponseLaunchDataToJSON(json: any): any {
    return FxIdWebFeaturesPlayGameResponseLaunchDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseLaunchDataToJSONTyped(value?: FxIdWebFeaturesPlayGameResponseLaunchData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesPlayGameResponseLaunchDataOptions(value)) {
        return FxIdWebFeaturesPlayGameResponseLaunchDataOptionsToJSON(value as FxIdWebFeaturesPlayGameResponseLaunchDataOptions);
    }

    return {};
}

