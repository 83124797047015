/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayFxLauncherOptions
 */
export interface FxIdWebFeaturesPlayFxLauncherOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayFxLauncherOptions
     */
    AdditionalLauncherArguments?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesPlayFxLauncherOptions
     */
    GameSearchPaths: Array<string>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayFxLauncherOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayFxLauncherOptions(value: object): value is FxIdWebFeaturesPlayFxLauncherOptions {
    if (!('GameSearchPaths' in value) || value['GameSearchPaths'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesPlayFxLauncherOptionsFromJSON(json: any): FxIdWebFeaturesPlayFxLauncherOptions {
    return FxIdWebFeaturesPlayFxLauncherOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayFxLauncherOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayFxLauncherOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'AdditionalLauncherArguments': json['AdditionalLauncherArguments'] == null ? undefined : json['AdditionalLauncherArguments'],
        'GameSearchPaths': json['GameSearchPaths'],
    };
}

export function FxIdWebFeaturesPlayFxLauncherOptionsToJSON(json: any): FxIdWebFeaturesPlayFxLauncherOptions {
    return FxIdWebFeaturesPlayFxLauncherOptionsToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayFxLauncherOptionsToJSONTyped(value?: FxIdWebFeaturesPlayFxLauncherOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'AdditionalLauncherArguments': value['AdditionalLauncherArguments'],
        'GameSearchPaths': value['GameSearchPaths'],
    };
}

