/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesProfileGetTwitchGameData } from './FxIdWebFeaturesProfileGetTwitchGameData';
import {
    FxIdWebFeaturesProfileGetTwitchGameDataFromJSON,
    FxIdWebFeaturesProfileGetTwitchGameDataFromJSONTyped,
    FxIdWebFeaturesProfileGetTwitchGameDataToJSON,
    FxIdWebFeaturesProfileGetTwitchGameDataToJSONTyped,
} from './FxIdWebFeaturesProfileGetTwitchGameData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesProfileGetTwitchData
 */
export interface FxIdWebFeaturesProfileGetTwitchData {
    /**
     * 
     * @type {{ [key: string]: FxIdWebFeaturesProfileGetTwitchGameData; }}
     * @memberof FxIdWebFeaturesProfileGetTwitchData
     */
    TwitchGameData?: { [key: string]: FxIdWebFeaturesProfileGetTwitchGameData; } | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesProfileGetTwitchData interface.
 */
export function instanceOfFxIdWebFeaturesProfileGetTwitchData(value: object): value is FxIdWebFeaturesProfileGetTwitchData {
    return true;
}

export function FxIdWebFeaturesProfileGetTwitchDataFromJSON(json: any): FxIdWebFeaturesProfileGetTwitchData {
    return FxIdWebFeaturesProfileGetTwitchDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetTwitchDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileGetTwitchData {
    if (json == null) {
        return json;
    }
    return {
        
        'TwitchGameData': json['TwitchGameData'] == null ? undefined : (mapValues(json['TwitchGameData'], FxIdWebFeaturesProfileGetTwitchGameDataFromJSON)),
    };
}

export function FxIdWebFeaturesProfileGetTwitchDataToJSON(json: any): FxIdWebFeaturesProfileGetTwitchData {
    return FxIdWebFeaturesProfileGetTwitchDataToJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetTwitchDataToJSONTyped(value?: FxIdWebFeaturesProfileGetTwitchData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'TwitchGameData': value['TwitchGameData'] == null ? undefined : (mapValues(value['TwitchGameData'], FxIdWebFeaturesProfileGetTwitchGameDataToJSON)),
    };
}

