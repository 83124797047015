/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesInviteEmailConnectGameRequest
 */
export interface FxIdWebFeaturesInviteEmailConnectGameRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailConnectGameRequest
     */
    Type: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailConnectGameRequest
     */
    Token: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailConnectGameRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailConnectGameRequest
     */
    Locale?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesInviteEmailConnectGameRequest interface.
 */
export function instanceOfFxIdWebFeaturesInviteEmailConnectGameRequest(value: object): value is FxIdWebFeaturesInviteEmailConnectGameRequest {
    if (!('Type' in value) || value['Type'] === undefined) return false;
    if (!('Token' in value) || value['Token'] === undefined) return false;
    if (!('Game' in value) || value['Game'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesInviteEmailConnectGameRequestFromJSON(json: any): FxIdWebFeaturesInviteEmailConnectGameRequest {
    return FxIdWebFeaturesInviteEmailConnectGameRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteEmailConnectGameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInviteEmailConnectGameRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'Type': json['Type'],
        'Token': json['Token'],
        'Game': json['Game'],
        'Locale': json['Locale'] == null ? undefined : json['Locale'],
    };
}

export function FxIdWebFeaturesInviteEmailConnectGameRequestToJSON(json: any): FxIdWebFeaturesInviteEmailConnectGameRequest {
    return FxIdWebFeaturesInviteEmailConnectGameRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteEmailConnectGameRequestToJSONTyped(value?: FxIdWebFeaturesInviteEmailConnectGameRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Type': value['Type'],
        'Token': value['Token'],
        'Game': value['Game'],
        'Locale': value['Locale'],
    };
}

