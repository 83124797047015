/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsFxEventsClientPublicConfigConfigSection } from './FxIdDomainSettingsFxEventsClientPublicConfigConfigSection';
import {
    FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSON,
    FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSONTyped,
    FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionToJSON,
    FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionToJSONTyped,
} from './FxIdDomainSettingsFxEventsClientPublicConfigConfigSection';

/**
 * Настройки для FxEvents - смотри README
 * @export
 * @interface FxIdDomainSettingsFxEventsClientPublicConfig
 */
export interface FxIdDomainSettingsFxEventsClientPublicConfig {
    /**
     * Описание смотри в README
     * @type {{ [key: string]: FxIdDomainSettingsFxEventsClientPublicConfigConfigSection; }}
     * @memberof FxIdDomainSettingsFxEventsClientPublicConfig
     */
    Sections: { [key: string]: FxIdDomainSettingsFxEventsClientPublicConfigConfigSection; };
}

/**
 * Check if a given object implements the FxIdDomainSettingsFxEventsClientPublicConfig interface.
 */
export function instanceOfFxIdDomainSettingsFxEventsClientPublicConfig(value: object): value is FxIdDomainSettingsFxEventsClientPublicConfig {
    if (!('Sections' in value) || value['Sections'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsFxEventsClientPublicConfigFromJSON(json: any): FxIdDomainSettingsFxEventsClientPublicConfig {
    return FxIdDomainSettingsFxEventsClientPublicConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsFxEventsClientPublicConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsFxEventsClientPublicConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'Sections': (mapValues(json['Sections'], FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSON)),
    };
}

export function FxIdDomainSettingsFxEventsClientPublicConfigToJSON(json: any): FxIdDomainSettingsFxEventsClientPublicConfig {
    return FxIdDomainSettingsFxEventsClientPublicConfigToJSONTyped(json, false);
}

export function FxIdDomainSettingsFxEventsClientPublicConfigToJSONTyped(value?: FxIdDomainSettingsFxEventsClientPublicConfig | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Sections': (mapValues(value['Sections'], FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionToJSON)),
    };
}

