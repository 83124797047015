/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions {
    /**
     * An encrypted string used when calling certain APIs that don't need to
     * access private user data
     * (example value: `AIzaSyDOCAbC123dEf456GhI789jKl012-MnO`).
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    ApiKey?: string | null;
    /**
     * Auth domain for the project ID.
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    AuthDomain?: string | null;
    /**
     * Default Realtime Database URL.
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    DatabaseUrl?: string | null;
    /**
     * The unique identifier for the project across all of Firebase and
     * Google Cloud.
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    ProjectId?: string | null;
    /**
     * The default Cloud Storage bucket name.
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    StorageBucket?: string | null;
    /**
     * Unique numerical value used to identify each sender that can send
     * Firebase Cloud Messaging messages to client apps.
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    MessagingSenderId?: string | null;
    /**
     * Unique identifier for the app.
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    AppId?: string | null;
    /**
     * An ID automatically created when you enable Analytics in your
     * Firebase project and register a web app. In versions 7.20.0
     * and higher, this parameter is optional.
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    MeasurementId?: string | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions {
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'ApiKey': json['ApiKey'] == null ? undefined : json['ApiKey'],
        'AuthDomain': json['AuthDomain'] == null ? undefined : json['AuthDomain'],
        'DatabaseUrl': json['DatabaseUrl'] == null ? undefined : json['DatabaseUrl'],
        'ProjectId': json['ProjectId'] == null ? undefined : json['ProjectId'],
        'StorageBucket': json['StorageBucket'] == null ? undefined : json['StorageBucket'],
        'MessagingSenderId': json['MessagingSenderId'] == null ? undefined : json['MessagingSenderId'],
        'AppId': json['AppId'] == null ? undefined : json['AppId'],
        'MeasurementId': json['MeasurementId'] == null ? undefined : json['MeasurementId'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ApiKey': value['ApiKey'],
        'AuthDomain': value['AuthDomain'],
        'DatabaseUrl': value['DatabaseUrl'],
        'ProjectId': value['ProjectId'],
        'StorageBucket': value['StorageBucket'],
        'MessagingSenderId': value['MessagingSenderId'],
        'AppId': value['AppId'],
        'MeasurementId': value['MeasurementId'],
    };
}

