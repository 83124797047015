/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGames } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGames';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGames';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLogin } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLogin';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLogin';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLogin } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLogin';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLogin';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQ } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQ';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQ';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontakteComLogin } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontakteComLogin';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontakteComLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontakteComLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontakteComLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontakteComLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontakteComLogin';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLogin } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLogin';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLogin';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestKongregate } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestKongregate';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestKongregateFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestKongregateFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestKongregateToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestKongregateToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestKongregate';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLogin } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLogin';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLogin';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestAbsoluteGamesLogin } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestAbsoluteGamesLogin';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestAbsoluteGamesLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestAbsoluteGamesLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestAbsoluteGamesLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestAbsoluteGamesLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestAbsoluteGamesLogin';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGames } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGames';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGames';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestFxIdLogin } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestFxIdLogin';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFxIdLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFxIdLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFxIdLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestFxIdLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestFxIdLogin';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestTelegram } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestTelegram';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestTelegramFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestTelegramFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestTelegramToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestTelegramToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestTelegram';
import type { FxIdWebFeaturesAuthAuthenticateFromSocialRequestSteamLogin } from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestSteamLogin';
import {
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestSteamLoginFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestSteamLoginFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestSteamLoginToJSON,
    FxIdWebFeaturesAuthAuthenticateFromSocialRequestSteamLoginToJSONTyped,
} from './FxIdWebFeaturesAuthAuthenticateFromSocialRequestSteamLogin';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromSocialRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateFromSocialRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    Social: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    Locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    SessionId?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestSteamLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    SteamLogin?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestSteamLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    FacebookGamesLogin?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    VkontaktePlayLogin?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontakteComLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    VkontakteComLogin?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontakteComLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    OdnoklassnikiLogin?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    YandexGamesLogin?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestAbsoluteGamesLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    AbsoluteGamesLogin?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestAbsoluteGamesLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestFxIdLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    FxIdLogin?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestFxIdLogin | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGames}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    CrazyGames?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGames | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQ}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    WizQ?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQ | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestTelegram}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    Telegram?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestTelegram | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGames}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    FacebookInstantGames?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGames | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromSocialRequestKongregate}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    Kongregate?: FxIdWebFeaturesAuthAuthenticateFromSocialRequestKongregate | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromSocialRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromSocialRequest(value: object): value is FxIdWebFeaturesAuthAuthenticateFromSocialRequest {
    if (!('Social' in value) || value['Social'] === undefined) return false;
    if (!('Game' in value) || value['Game'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialRequest {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromSocialRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'Social': json['Social'],
        'Game': json['Game'],
        'Locale': json['Locale'] == null ? undefined : json['Locale'],
        'SessionId': json['SessionId'] == null ? undefined : json['SessionId'],
        'SteamLogin': json['SteamLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestSteamLoginFromJSON(json['SteamLogin']),
        'FacebookGamesLogin': json['FacebookGamesLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginFromJSON(json['FacebookGamesLogin']),
        'VkontaktePlayLogin': json['VkontaktePlayLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginFromJSON(json['VkontaktePlayLogin']),
        'VkontakteComLogin': json['VkontakteComLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontakteComLoginFromJSON(json['VkontakteComLogin']),
        'OdnoklassnikiLogin': json['OdnoklassnikiLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginFromJSON(json['OdnoklassnikiLogin']),
        'YandexGamesLogin': json['YandexGamesLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginFromJSON(json['YandexGamesLogin']),
        'AbsoluteGamesLogin': json['AbsoluteGamesLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestAbsoluteGamesLoginFromJSON(json['AbsoluteGamesLogin']),
        'FxIdLogin': json['FxIdLogin'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestFxIdLoginFromJSON(json['FxIdLogin']),
        'CrazyGames': json['CrazyGames'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesFromJSON(json['CrazyGames']),
        'WizQ': json['WizQ'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQFromJSON(json['WizQ']),
        'Telegram': json['Telegram'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestTelegramFromJSON(json['Telegram']),
        'FacebookInstantGames': json['FacebookInstantGames'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesFromJSON(json['FacebookInstantGames']),
        'Kongregate': json['Kongregate'] == null ? undefined : FxIdWebFeaturesAuthAuthenticateFromSocialRequestKongregateFromJSON(json['Kongregate']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestToJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialRequest {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateFromSocialRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Social': value['Social'],
        'Game': value['Game'],
        'Locale': value['Locale'],
        'SessionId': value['SessionId'],
        'SteamLogin': FxIdWebFeaturesAuthAuthenticateFromSocialRequestSteamLoginToJSON(value['SteamLogin']),
        'FacebookGamesLogin': FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookGamesLoginToJSON(value['FacebookGamesLogin']),
        'VkontaktePlayLogin': FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontaktePlayLoginToJSON(value['VkontaktePlayLogin']),
        'VkontakteComLogin': FxIdWebFeaturesAuthAuthenticateFromSocialRequestVkontakteComLoginToJSON(value['VkontakteComLogin']),
        'OdnoklassnikiLogin': FxIdWebFeaturesAuthAuthenticateFromSocialRequestOdnoklassnikiLoginToJSON(value['OdnoklassnikiLogin']),
        'YandexGamesLogin': FxIdWebFeaturesAuthAuthenticateFromSocialRequestYandexGamesLoginToJSON(value['YandexGamesLogin']),
        'AbsoluteGamesLogin': FxIdWebFeaturesAuthAuthenticateFromSocialRequestAbsoluteGamesLoginToJSON(value['AbsoluteGamesLogin']),
        'FxIdLogin': FxIdWebFeaturesAuthAuthenticateFromSocialRequestFxIdLoginToJSON(value['FxIdLogin']),
        'CrazyGames': FxIdWebFeaturesAuthAuthenticateFromSocialRequestCrazyGamesToJSON(value['CrazyGames']),
        'WizQ': FxIdWebFeaturesAuthAuthenticateFromSocialRequestWizQToJSON(value['WizQ']),
        'Telegram': FxIdWebFeaturesAuthAuthenticateFromSocialRequestTelegramToJSON(value['Telegram']),
        'FacebookInstantGames': FxIdWebFeaturesAuthAuthenticateFromSocialRequestFacebookInstantGamesToJSON(value['FacebookInstantGames']),
        'Kongregate': FxIdWebFeaturesAuthAuthenticateFromSocialRequestKongregateToJSON(value['Kongregate']),
    };
}

