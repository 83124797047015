/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPublicDataBaseStoreOptions
 */
export interface FxIdWebFeaturesPlayPublicDataBaseStoreOptions {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesPlayPublicDataBaseStoreOptions
     */
    XsollaEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesPlayPublicDataBaseStoreOptions
     */
    YookassaEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesPlayPublicDataBaseStoreOptions
     */
    StripeEnabled: boolean;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPublicDataBaseStoreOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayPublicDataBaseStoreOptions(value: object): value is FxIdWebFeaturesPlayPublicDataBaseStoreOptions {
    if (!('XsollaEnabled' in value) || value['XsollaEnabled'] === undefined) return false;
    if (!('YookassaEnabled' in value) || value['YookassaEnabled'] === undefined) return false;
    if (!('StripeEnabled' in value) || value['StripeEnabled'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSON(json: any): FxIdWebFeaturesPlayPublicDataBaseStoreOptions {
    return FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPublicDataBaseStoreOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'XsollaEnabled': json['XsollaEnabled'],
        'YookassaEnabled': json['YookassaEnabled'],
        'StripeEnabled': json['StripeEnabled'],
    };
}

export function FxIdWebFeaturesPlayPublicDataBaseStoreOptionsToJSON(json: any): FxIdWebFeaturesPlayPublicDataBaseStoreOptions {
    return FxIdWebFeaturesPlayPublicDataBaseStoreOptionsToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseStoreOptionsToJSONTyped(value?: FxIdWebFeaturesPlayPublicDataBaseStoreOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'XsollaEnabled': value['XsollaEnabled'],
        'YookassaEnabled': value['YookassaEnabled'],
        'StripeEnabled': value['StripeEnabled'],
    };
}

