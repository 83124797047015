/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType {
    Unspecified = 'Unspecified',
    DirectLoad = 'DirectLoad',
    QbsPatch = 'QbsPatch',
    UnknownDefaultOpenApi = '11184809'
}


export function instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType(value: any): boolean {
    for (const key in FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType) {
        if (Object.prototype.hasOwnProperty.call(FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType, key)) {
            if (FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType[key as keyof typeof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType {
    return json as FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeToJSON(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType | null): any {
    return value as any;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType {
    return value as FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType;
}

