/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmail } from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmail';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmailFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmailFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmailToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmailToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmail';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact
     */
    LegalName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact
     */
    OgrnNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact
     */
    InnNumber?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmail}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact
     */
    Email?: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmail | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLegalContact(value: object): value is FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact {
    if (!('LegalName' in value) || value['LegalName'] === undefined) return false;
    return true;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact {
    if (json == null) {
        return json;
    }
    return {
        
        'LegalName': json['LegalName'],
        'OgrnNumber': json['OgrnNumber'] == null ? undefined : json['OgrnNumber'],
        'InnNumber': json['InnNumber'] == null ? undefined : json['InnNumber'],
        'Email': json['Email'] == null ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmailFromJSON(json['Email']),
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactToJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactToJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactToJSONTyped(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'LegalName': value['LegalName'],
        'OgrnNumber': value['OgrnNumber'],
        'InnNumber': value['InnNumber'],
        'Email': FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmailToJSON(value['Email']),
    };
}

