/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens';
import {
    instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdTokens,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens';

/**
 * @type FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens = FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens;

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdTokens(json)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensToJSON(json: any): any {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensToJSONTyped(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdTokens(value)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensToJSON(value as FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens);
    }

    return {};
}

