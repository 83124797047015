/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest
 */
export interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest
     */
    GameSystemName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest
     */
    Game?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest
     */
    Url: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest(value: object): value is FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest {
    if (!('Url' in value) || value['Url'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestFromJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'GameSystemName': json['GameSystemName'] == null ? undefined : json['GameSystemName'],
        'Game': json['Game'] == null ? undefined : json['Game'],
        'Url': json['Url'],
    };
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestToJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestToJSONTyped(value?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameSystemName': value['GameSystemName'],
        'Game': value['Game'],
        'Url': value['Url'],
    };
}

