/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxIdWebFeaturesInviteEmailConnectGameRequest,
  FxIdWebFeaturesInviteEmailConnectGameResponse,
  FxIdWebFeaturesInviteEmailInviteRequest,
  FxIdWebFeaturesInviteEmailInviteResponse,
  FxIdWebFeaturesInviteEmailInviteStatusRequest,
  FxIdWebFeaturesInviteEmailInviteStatusResponse,
  FxIdWebFeaturesInviteUpdateConnectGameInviteRequest,
} from '../models/index';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxIdWebFeaturesInviteEmailConnectGameRequestFromJSON,
    FxIdWebFeaturesInviteEmailConnectGameRequestToJSON,
    FxIdWebFeaturesInviteEmailConnectGameResponseFromJSON,
    FxIdWebFeaturesInviteEmailConnectGameResponseToJSON,
    FxIdWebFeaturesInviteEmailInviteRequestFromJSON,
    FxIdWebFeaturesInviteEmailInviteRequestToJSON,
    FxIdWebFeaturesInviteEmailInviteResponseFromJSON,
    FxIdWebFeaturesInviteEmailInviteResponseToJSON,
    FxIdWebFeaturesInviteEmailInviteStatusRequestFromJSON,
    FxIdWebFeaturesInviteEmailInviteStatusRequestToJSON,
    FxIdWebFeaturesInviteEmailInviteStatusResponseFromJSON,
    FxIdWebFeaturesInviteEmailInviteStatusResponseToJSON,
    FxIdWebFeaturesInviteUpdateConnectGameInviteRequestFromJSON,
    FxIdWebFeaturesInviteUpdateConnectGameInviteRequestToJSON,
} from '../models/index';

export interface FxIdWebFeaturesInviteEmailConnectGameEndpointRequest {
    fxIdWebFeaturesInviteEmailConnectGameRequest: FxIdWebFeaturesInviteEmailConnectGameRequest;
}

export interface FxIdWebFeaturesInviteEmailInviteEndpointRequest {
    fxIdWebFeaturesInviteEmailInviteRequest: FxIdWebFeaturesInviteEmailInviteRequest;
}

export interface FxIdWebFeaturesInviteEmailInviteStatusEndpointRequest {
    fxIdWebFeaturesInviteEmailInviteStatusRequest: FxIdWebFeaturesInviteEmailInviteStatusRequest;
}

export interface FxIdWebFeaturesInviteUpdateConnectGameInviteEndpointRequest {
    fxIdWebFeaturesInviteUpdateConnectGameInviteRequest: FxIdWebFeaturesInviteUpdateConnectGameInviteRequest;
}

/**
 * 
 */
export class InviteApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesInviteEmailConnectGameEndpointRaw(requestParameters: FxIdWebFeaturesInviteEmailConnectGameEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesInviteEmailConnectGameResponse>> {
        if (requestParameters['fxIdWebFeaturesInviteEmailConnectGameRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesInviteEmailConnectGameRequest',
                'Required parameter "fxIdWebFeaturesInviteEmailConnectGameRequest" was null or undefined when calling fxIdWebFeaturesInviteEmailConnectGameEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/invite/connect_from_game`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesInviteEmailConnectGameRequestToJSON(requestParameters['fxIdWebFeaturesInviteEmailConnectGameRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesInviteEmailConnectGameResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesInviteEmailConnectGameEndpoint(fxIdWebFeaturesInviteEmailConnectGameRequest: FxIdWebFeaturesInviteEmailConnectGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesInviteEmailConnectGameResponse> {
        const response = await this.fxIdWebFeaturesInviteEmailConnectGameEndpointRaw({ fxIdWebFeaturesInviteEmailConnectGameRequest: fxIdWebFeaturesInviteEmailConnectGameRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesInviteEmailInviteEndpointRaw(requestParameters: FxIdWebFeaturesInviteEmailInviteEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesInviteEmailInviteResponse>> {
        if (requestParameters['fxIdWebFeaturesInviteEmailInviteRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesInviteEmailInviteRequest',
                'Required parameter "fxIdWebFeaturesInviteEmailInviteRequest" was null or undefined when calling fxIdWebFeaturesInviteEmailInviteEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/invite/email_invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesInviteEmailInviteRequestToJSON(requestParameters['fxIdWebFeaturesInviteEmailInviteRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesInviteEmailInviteResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesInviteEmailInviteEndpoint(fxIdWebFeaturesInviteEmailInviteRequest: FxIdWebFeaturesInviteEmailInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesInviteEmailInviteResponse> {
        const response = await this.fxIdWebFeaturesInviteEmailInviteEndpointRaw({ fxIdWebFeaturesInviteEmailInviteRequest: fxIdWebFeaturesInviteEmailInviteRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesInviteEmailInviteStatusEndpointRaw(requestParameters: FxIdWebFeaturesInviteEmailInviteStatusEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesInviteEmailInviteStatusResponse>> {
        if (requestParameters['fxIdWebFeaturesInviteEmailInviteStatusRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesInviteEmailInviteStatusRequest',
                'Required parameter "fxIdWebFeaturesInviteEmailInviteStatusRequest" was null or undefined when calling fxIdWebFeaturesInviteEmailInviteStatusEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/invite/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesInviteEmailInviteStatusRequestToJSON(requestParameters['fxIdWebFeaturesInviteEmailInviteStatusRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesInviteEmailInviteStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesInviteEmailInviteStatusEndpoint(fxIdWebFeaturesInviteEmailInviteStatusRequest: FxIdWebFeaturesInviteEmailInviteStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesInviteEmailInviteStatusResponse> {
        const response = await this.fxIdWebFeaturesInviteEmailInviteStatusEndpointRaw({ fxIdWebFeaturesInviteEmailInviteStatusRequest: fxIdWebFeaturesInviteEmailInviteStatusRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesInviteUpdateConnectGameInviteEndpointRaw(requestParameters: FxIdWebFeaturesInviteUpdateConnectGameInviteEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['fxIdWebFeaturesInviteUpdateConnectGameInviteRequest'] == null) {
            throw new runtime.RequiredError(
                'fxIdWebFeaturesInviteUpdateConnectGameInviteRequest',
                'Required parameter "fxIdWebFeaturesInviteUpdateConnectGameInviteRequest" was null or undefined when calling fxIdWebFeaturesInviteUpdateConnectGameInviteEndpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/invite/update_connect_game_invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesInviteUpdateConnectGameInviteRequestToJSON(requestParameters['fxIdWebFeaturesInviteUpdateConnectGameInviteRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fxIdWebFeaturesInviteUpdateConnectGameInviteEndpoint(fxIdWebFeaturesInviteUpdateConnectGameInviteRequest: FxIdWebFeaturesInviteUpdateConnectGameInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fxIdWebFeaturesInviteUpdateConnectGameInviteEndpointRaw({ fxIdWebFeaturesInviteUpdateConnectGameInviteRequest: fxIdWebFeaturesInviteUpdateConnectGameInviteRequest }, initOverrides);
        return await response.value();
    }

}
