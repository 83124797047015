/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesGamesChooseGameRequest
 */
export interface FxIdWebFeaturesGamesChooseGameRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesGamesChooseGameRequest
     */
    GameSystemName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesGamesChooseGameRequest
     */
    OriginUrl: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesGamesChooseGameRequest interface.
 */
export function instanceOfFxIdWebFeaturesGamesChooseGameRequest(value: object): value is FxIdWebFeaturesGamesChooseGameRequest {
    if (!('OriginUrl' in value) || value['OriginUrl'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesGamesChooseGameRequestFromJSON(json: any): FxIdWebFeaturesGamesChooseGameRequest {
    return FxIdWebFeaturesGamesChooseGameRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesChooseGameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesGamesChooseGameRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'GameSystemName': json['GameSystemName'] == null ? undefined : json['GameSystemName'],
        'OriginUrl': json['OriginUrl'],
    };
}

export function FxIdWebFeaturesGamesChooseGameRequestToJSON(json: any): FxIdWebFeaturesGamesChooseGameRequest {
    return FxIdWebFeaturesGamesChooseGameRequestToJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesChooseGameRequestToJSONTyped(value?: FxIdWebFeaturesGamesChooseGameRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'GameSystemName': value['GameSystemName'],
        'OriginUrl': value['OriginUrl'],
    };
}

